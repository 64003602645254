import React from "react";
import logo from "../../images/branding/footer-logo.png";
import {
  FaFacebookSquare,
  FaYoutube,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import {
  FooterContainer,
  LegalLinks,
  PipeSpan,
  SocialIconLink,
} from "./Footer.elements";

import { Container, Row, Col } from "react-bootstrap";
import ReactGA from "react-ga";

ReactGA.initialize("UA-203934706-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const Footer = (props) => {
  return (
    <FooterContainer>
      <Container fluid>
        <Row className="pt-2">
          <Col
            lg={3}
            className="d-flex flex-column flex-lg-row text-center text-lg-left">
            <a href="/" aria-label="Home" className="mb-3 mb-lg-0 pr-xl-3">
              <img
                src={logo}
                alt="BD advancing the world of health logo. Click to go to home page"
              />
            </a>
            <div>
              <SocialIconLink
                href="https://www.facebook.com/BectonDickinsonandCo?ref=bookmarks"
                target="_blank"
                aria-label="Facebook">
                <FaFacebookSquare />
              </SocialIconLink>
              <SocialIconLink
                href="https://twitter.com/BDandCo"
                target="_blank"
                aria-label="Twitter">
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.linkedin.com/company/bd1?trk=biz-companies-cym"
                target="_blank"
                aria-label="LinkedIn">
                <FaLinkedinIn />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.youtube.com/channel/UCPGmutY43EjP_3ijOugNGnA"
                target="_blank"
                aria-label="YouTube">
                <FaYoutube />
              </SocialIconLink>
            </div>
          </Col>
          <Col lg={6} className="my-3 my-lg-0">
            <p className="text-white text-center" style={{ fontSize: "10px" }}>
              © 2021 BD. &nbsp; All rights reserved. &nbsp;BD and the BD Logo
              are registered trademarks of Becton, Dickinson and Company. &nbsp;
              All other trademarks are the property of their respective owners.
            </p>
          </Col>
          <Col lg={3} className="mb-4 mb-lg-0">
            <p className="text-lg-right text-center small">
              <LegalLinks
                href="https://www.bd.com/en-us/privacy-statement"
                target="_blank"
                rel="noopener noreferrer">
                Privacy
              </LegalLinks>
              <PipeSpan>|</PipeSpan>
              <LegalLinks
                href="https://www.bd.com/en-us/terms-of-use"
                target="_blank"
                rel="noopener noreferrer">
                Terms of Use
              </LegalLinks>
              <PipeSpan>|</PipeSpan>
              <button
                id="ot-sdk-btn"
                className="ot-sdk-show-settings"
                data-uw-styling-context="true"
                data-uw-rm-kbnav="anohref"
                tabIndex="0">
                Cookies Settings
              </button>
            </p>
          </Col>
        </Row>
      </Container>
    </FooterContainer>
  );
};

export default React.forwardRef(Footer);
