import { useState, useEffect } from "react";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-203934706-1');
ReactGA.pageview(window.location.pathname + window.location.search);


function appendScript(baseUrl, setScriptLoaded) {
  if (window.MktoForms2) return setScriptLoaded(true);

  const script = document.createElement("script");
  script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
  script.onload = () => (window.MktoForms2 ? setScriptLoaded(true) : null);
  document.body.appendChild(script);
}

function useMarketo({ baseUrl, munchkinId, formId, onUserSubmit, linkedinID, ThankyouModalID }) {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (scriptLoaded) {
      window.MktoForms2.loadForm(baseUrl, munchkinId, formId, function(form) {

        form.onSuccess(function(values, followUpUrl) {

            if(onUserSubmit !== undefined) {
              // form.getFormElem().hide();
              onUserSubmit(linkedinID, ThankyouModalID)
            }
           
            // Return false to prevent the submission handler from taking the lead to the follow up url
            return false;
        });
    });
      return;
    }
    appendScript(baseUrl, setScriptLoaded);
  }, [scriptLoaded, baseUrl, munchkinId, formId]);
}

export default useMarketo;