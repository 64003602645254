import React from "react"

const LoadingSpinner = () => {
    return (
<div class="d-flex justify-content-center flex-column pt-5 text-center">
     <p className="h3 font-weight-bold" style={{color:'#02519d'}}>Loading...</p>
  <div class="spinner-border text-secondary mx-auto" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
    )
}

export default LoadingSpinner