import React from "react";
import ShowHideDescription from "./PresentationElements/ShowHideDescription"
import Speakers from "./PresentationElements/Speakers";
import PresentationTime from "./PresentationElements/PresentationTime";
import ButtonElement from "../../../components/UI/Button/ButtonElement";
import ReactHtmlParser from 'react-html-parser';
import ButtonLink from "../../../components/UI/Button/ButtonHref";
import './sessionPresentationCard.css'

const SessionPresentationCard = ({date, time, title, speakers, showHideDescription, presentationComingSoon, openModalHandler, sessionModalID, isOdd, location="", note="", registerLink=""}) => {
    return (
        <div className="row px-md-5 my-4">

            <PresentationTime date={date} time={time} location={location} isOdd={isOdd} />

            <div className={`py-4 ${date || time != "" ? "col-md-8" : "col-md-12"}`} style={ isOdd ? {backgroundColor: 'white'} : {}}>
                <h5 className="font-weight-semi-bold my-3 grey-title">
                    {ReactHtmlParser(title)}
                </h5>

                <Speakers speakers={speakers} />

                {showHideDescription &&
                <ShowHideDescription
                description={showHideDescription}
                />
                }
                {note &&
                <small className="mb-4 mt-2 font-italic d-block" style={{color:"#212529"}}>{note}</small>
                }

                {presentationComingSoon &&
                    <button type="button" className="btn font-weight-semi-bold my-3 rounded-0" style={{backgroundColor:'#d8d8d8', color:'black'}} disabled>Presentation coming soon</button>
                }

                {/* If presentation coming soon is false */}
                {!presentationComingSoon && 

                    <div className="mb-4 pt-3">
                 {/* If presentation coming soon is false because there's a modal ID */}
                    {sessionModalID &&
                        <ButtonElement onClick={() => openModalHandler(sessionModalID)} >
                            View presentation
                        </ButtonElement>
                    }
                    {/* If presentation coming soon is false because there's a registration link */}
                    {registerLink &&
                        <ButtonLink onClick={()=> window.open(registerLink, "_blank")} >
                            Learn More
                        </ButtonLink>
                    }
                    </div>
                }
      </div>
    </div>
    )
}

export default SessionPresentationCard