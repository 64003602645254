import { gsap } from "gsap"


export const bikeCircleAnimation = bikeCircle => {
    let tl = gsap.timeline()

    gsap.set(bikeCircle, {transformOrigin: 'center center', scale: 0, opacity: 0})

    tl
      .to(bikeCircle, {scale: 1, duration: .5, opacity: .55})
      .to(bikeCircle, { duration: .5, opacity: 0})
    
  }