import React from "react";
import VideoModal from "./VideoModal";

const VideoModals = ({ modalId, closeModal}) => {
    return (
        videos.map(video => {
            return (
                <VideoModal key={video.id} id={video.id} title={video.title} iframeSrc={video.iframeSrc} modalId={modalId} closeModal={closeModal}/>
            )
        })
        
    )
}

export default VideoModals

const videos = [{
    id:"supplyChallenges",
    title:"Effectively Managing Supply Challenges: Lessons Learned from the COVID-19 Pandemic",
    iframeSrc:"https://player.vimeo.com/video/579209863?autoplay=1&color=ED621D&byline=0&portrait=0",
},
{
    id:"covidPanel",
    title:"COVID-19 Panel Discussion: Actionable Insights from the C-Suite to the Front Lines",
    iframeSrc:"https://player.vimeo.com/video/579209819?autoplay=1&color=ED621D&byline=0&portrait=0"
},
{
    id:"maximizeMedication",
    title:"Maximizing Enterprise Medication Inventory Optimization Utilizing Analytics Software",
    iframeSrc:"https://player.vimeo.com/video/579210157?autoplay=1&color=ED621D&byline=0&portrait=0",
}, 
{
    id:"analytics",
    title:"Analytics: the key to high performance pharmacy programs",
    iframeSrc:"https://player.vimeo.com/video/642482529?h=b6badffe29&amp;badge=0&amp;autopause=1&amp;player_id=0&amp;app_id=58479",
},
{
    id:"medManagement",
    title:"Improving the medication management system with rule-based EMR initiatives",
    iframeSrc:"https://player.vimeo.com/video/642368131?h=8caee611b2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
},
{
    id:"perspectives",
    title:"Perspectives from the C-suite: unleashing the value of pharmacy within healthcare organizations",
    iframeSrc:"https://player.vimeo.com/video/642398492?h=3151048ffa&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
}
]