import React, {useEffect, useRef} from 'react'
import { gsap } from "gsap"
import './doctor.css'

const Doctor = () => {
    let top_scientistRef = null
    let top_beaker_outlineRef = null
    let top_pillRef = null
    let top_fingerRef = null
    let finger_circlesRef = null
    let top_monitor = null
    let top_hex = null
    let top_rx = null
    let top_molecule = null
    let top_red_pill = null
    let doctorTimeLine = useRef(gsap.timeline({ delay: .4, force3D: true}));

    useEffect(() => {


         gsap.set(finger_circlesRef.childNodes, {
           opacity: 0,
           scale: 0,
           transformOrigin: 'center center',
           force3D: true,
         });

         gsap.fromTo(top_beaker_outlineRef, {y: 5}, {y: 0, duration: 1.3, ease: "sine.inOut", repeat: -1, yoyo: true})
         gsap.to(finger_circlesRef.childNodes, { duration: 5, opacity: 1, scale: 1, ease: "elastic.out(1, 0.5)", delay: 1, stagger: -.5, repeat: -1, force3D: true})
         gsap.fromTo(top_fingerRef, {y: -5}, { duration: 1, y: 0, ease: "sine.inOut", repeat: -1, yoyo:true, force3D: true})
         


         doctorTimeLine.current
         .fromTo(top_scientistRef, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
         .fromTo(top_hex, {transformOrigin: 'center center', scale: 0, opacity: 0}, {scale: 1, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
         .fromTo(top_red_pill, {xPercent: 100, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
         .fromTo(top_molecule, {xPercent: 100, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
         .fromTo(top_monitor, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
         .fromTo(finger_circlesRef, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
         .fromTo(top_pillRef, {xPercent: -100, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
         .fromTo(top_beaker_outlineRef, {xPercent: -100, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
         .fromTo(top_rx, {yPercent: -100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
         .fromTo(top_fingerRef, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
         .fromTo(finger_circlesRef.childNodes, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)

        //  return () =>  {
        //     const killAnimation = doctorTimeLine.current
        //     doctorTimeLine.kill()
        //   }

      }, []);

      useEffect(() => {

        let tweenProperty = (element, prop, min, max, direction) => {
    
            let randomDur = gsap.utils.random(.5, 1, .05, true);
    
            gsap.to(element, {
                [prop]: gsap.utils.random(min, max),
                duration: randomDur(),
                ease: "Sine.easeInOut",
                onComplete: tweenProperty,
                onCompleteParams: [element, prop, min, max, direction * -1]
            });
        }
    
        gsap.utils.toArray('#top_hex').forEach(element => {
            tweenProperty(element, "x", 0, 10, 1)
            tweenProperty(element, "y", 10, 20, -1)
          });
          

      }, []);

    return(
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 776.47 441.61" className="enterprise-doctor">
            <defs/>
            <g id="top_molecule" data-name="top molecule" ref={(e) => top_molecule = e}>
                <path d="M751.18 156.55a12.87 12.87 0 01-1.37-4.59l-51.44 24.36-24-56.87a34.63 34.63 0 01-4.38 1.78l24.09 57.11-59.62 28.23q1.14 2.07 2.09 4.24l59.37-28.11 9.18 21.78a24.25 24.25 0 014.31-1.94l-9.22-21.86z" className="cls-1"/>
                <path d="M471.89 137.64l64.49-23A18 18 0 01542 97.51l-24.61-35.76A28.21 28.21 0 01492 63.14l-31.55 61.77a28.41 28.41 0 0111.44 12.73zM490.77 211.58l14.86-25.07-36.53-20.57a28.55 28.55 0 01-9.22 8.17l18.29 37.11a18 18 0 017.93-.85 17.63 17.63 0 014.67 1.21zM705.12 204.48l-9.18-21.78-59.37 28.11a52.56 52.56 0 014.2 16.31l52.31-2.89c0-.58 0-1.16.12-1.75a24 24 0 0111.92-18zM541.79 206.86l-32-18-14.91 25.04a18.15 18.15 0 017.17 13.32l33.54 1.12c.06-.86.14-1.73.24-2.6a52.68 52.68 0 015.96-18.88zM674.39 119.45l24 56.87L749.81 152a12.67 12.67 0 01.81-6.06c-20.38-12.38-44.35-26.87-62.69-37.84a35.88 35.88 0 01-13.54 11.35zM624.5 97.58l-52.33 12.06a17.82 17.82 0 01-.07 3.42 18.07 18.07 0 01-11 14.56l14.6 53a53 53 0 0133.65 2.94L641 118.31a36 36 0 01-16.5-20.73zM545.88 94.81A18.1 18.1 0 01571.21 105l52.23-12a35.67 35.67 0 01-.23-10 38.62 38.62 0 01.92-4.86L529.67 47.6a28.33 28.33 0 01-8.38 11.49zM542.3 124.67a18 18 0 01-4.37-5.61l-64.43 23a28 28 0 01-2 19.8l36.5 20.58zM670 121.23a35.72 35.72 0 01-24.76-.86l-31.63 65.3a52.88 52.88 0 0120.86 20.9l59.62-28.23zM751.18 156.55l-51 24.13 9.22 21.86a23.76 23.76 0 0118.46 1.34l26.18-43.61a12.77 12.77 0 01-2.86-3.72zM552 128.93a17.94 17.94 0 01-5.73-1.68l-34.1 57.51 32.06 18.05a53 53 0 0127-20.84l-14.62-53.1a18.12 18.12 0 01-4.61.06z" className="cls-2"/>
                <path d="M545.88 94.81l-24.59-35.72a28.84 28.84 0 01-3.9 2.66L542 97.51a18.33 18.33 0 013.88-2.7zM536.38 114.59l-64.49 23a27.68 27.68 0 011.61 4.44l64.43-23a17.67 17.67 0 01-1.55-4.44zM459.88 174.11a28.35 28.35 0 01-4.31 1.94l18.36 37.25a18.15 18.15 0 014.24-2.08zM492 63.14a27.24 27.24 0 01-4.15-2.26l-31.67 62a28.84 28.84 0 014.27 2zM624.13 78.15a38.56 38.56 0 011.49-4.48l-94.57-30.58a28.38 28.38 0 01-1.38 4.51zM641 118.31l-31.61 65.27c1.45.63 2.86 1.34 4.23 2.09l31.63-65.3a35.45 35.45 0 01-4.25-2.06zM556.59 128.87L571.21 182c1.48-.51 3-.95 4.53-1.33l-14.6-53a18 18 0 01-4.55 1.2zM502.05 227.22a18.65 18.65 0 01-.08 3.25c-.06.5-.15 1-.25 1.46l33.76 1.13c0-1.56 0-3.13.11-4.72zM624.5 97.58a35.31 35.31 0 01-1.06-4.6l-52.23 12a17.83 17.83 0 011 4.62zM750.62 145.9a13 13 0 012.48-4 30839 30839 0 00-41.27-25c-8.46-5.1-15.5-9.34-21.4-12.88a36.13 36.13 0 01-2.5 4c18.34 10.98 42.31 25.5 62.69 37.88zM727.89 203.88a23.8 23.8 0 014 2.54l26.24-43.7a12.79 12.79 0 01-4-2.45zM640.77 227.12c.14 1.56.2 3.13.2 4.72l52.37-2.84a23.83 23.83 0 01-.26-4.72zM512.16 184.76l34.1-57.51a17.53 17.53 0 01-4-2.58L508 182.44l-36.52-20.56a27.54 27.54 0 01-2.42 4.06l36.53 20.57-14.86 25.07a18.22 18.22 0 014.11 2.32l14.87-25.08 32 18q1.14-2.09 2.43-4.05z" className="cls-1"/>
                <path d="M663 51.62a35.85 35.85 0 00-37.37 22.05 36.1 36.1 0 00-2.42 9.33 35.67 35.67 0 00.23 10 35.31 35.31 0 001.06 4.6 36 36 0 0016.5 20.71 35.82 35.82 0 0029 2.92 34.63 34.63 0 004.38-1.78A35.82 35.82 0 00663 51.62z" className="cls-3"/>
                <path d="M506.66 9a28.33 28.33 0 00-18.82 51.86 27.24 27.24 0 004.16 2.28 28.21 28.21 0 0025.4-1.39 28.84 28.84 0 003.9-2.66 28.65 28.65 0 009.76-16c.18-.86.34-1.72.44-2.6A28.34 28.34 0 00506.66 9zM494.88 213.9a18.22 18.22 0 00-4.11-2.32 17.63 17.63 0 00-4.67-1.21 18.11 18.11 0 1015.62 21.56c.1-.48.19-1 .25-1.46a18.65 18.65 0 00.08-3.25 18.15 18.15 0 00-7.17-13.32z" className="cls-4"/>
                <path d="M731.87 206.42a23.83 23.83 0 00-22.44-3.88 24.25 24.25 0 00-4.31 1.94 24 24 0 00-11.92 18c-.07.59-.09 1.17-.12 1.75a24 24 0 1038.79-17.81z" className="cls-3"/>
                <path d="M471.89 137.64a28.41 28.41 0 00-11.45-12.73 28.84 28.84 0 00-4.27-2 28.31 28.31 0 103.71 51.23 28.55 28.55 0 009.22-8.17 27.54 27.54 0 002.42-4.06 28 28 0 002-19.8 27.68 27.68 0 00-1.63-4.47z" className="cls-5"/>
                <path d="M571.21 105a18.1 18.1 0 00-25.33-10.21 18.33 18.33 0 00-3.88 2.7 18 18 0 00-5.62 17.08 17.67 17.67 0 001.55 4.47 18 18 0 004.37 5.61 17.53 17.53 0 004 2.58 18.08 18.08 0 0025-22.23z" className="cls-3"/>
                <path d="M764.25 137.69a13 13 0 00-13.63 8.21 12.67 12.67 0 00-.81 6.06 13 13 0 008.3 10.76 12.74 12.74 0 003.09.78 13 13 0 003-25.81z" className="cls-4"/>
                <path fill="#f68622" stroke="#d35327" strokeMiterlimit="10" d="M641 231.84c0-1.59-.06-3.16-.2-4.72a52.56 52.56 0 00-4.2-16.31q-.95-2.17-2.09-4.24a52.88 52.88 0 00-20.86-20.9c-1.37-.75-2.78-1.46-4.23-2.09a53 53 0 00-33.65-2.94c-1.54.38-3 .82-4.53 1.33a53 53 0 00-27 20.84q-1.29 2-2.43 4.05a52.68 52.68 0 00-6 18.88 53.378 53.378 0 00-.35 7.32A52.75 52.75 0 00641 231.84z"/>
            </g>
            <g id="top_hex" data-name="top hex" ref={(e) => top_hex = e}>
                <path d="M209.35 251.1l-73.43 51.89v84.74l73.43 51.75.02-.01 73.23-51.74v-84.74l-73.25-51.89zM209.35 0l-73.43 51.89v84.74l73.43 51.75.02-.01 73.23-51.74V51.89L209.35 0z" className="cls-7"/>
                <path d="M318.15 60.25l-73.44 51.89v84.74l73.44 51.75.01-.01 73.24-51.74v-84.74l-73.25-51.89z" className="cls-7"/>
                <path d="M318.15 190.82l-73.44 51.89v84.74l73.44 51.75.01-.01 73.24-51.74v-84.74l-73.25-51.89zM100.8 60.25l-73.44 51.89v84.74l73.44 51.75.01-.01 73.23-51.74v-84.74L100.8 60.25z" className="cls-7"/>
                <path d="M100.8 190.82l-73.44 51.89v84.74l73.44 51.75.01-.01 73.23-51.74v-84.74l-73.24-51.89z" className="cls-7"/>
            </g>
            <g id="top_rx" ref={(e) => top_rx = e} data-name="top rx">
                <circle cx="274.25" cy="87.09" r="73.13" className="cls-3"/>
                <path d="M314.05 84h-14.87l-11.25 14.78-9.21-15.2a19.7 19.7 0 006.58-4.65 20.66 20.66 0 004.31-7 23.75 23.75 0 001.5-8.37 22.7 22.7 0 00-1.59-8.33 24.41 24.41 0 00-4.52-7.38 22.34 22.34 0 00-7-5.23 19.93 19.93 0 00-8.76-1.94h-30.4v68.75h13.36v-23h13.36l14.14 23.2-18.7 24.55h14.72l10.58-13.83 8.83 14.51h14.67l-15.31-25.27zm-37.56-14.85a10.43 10.43 0 01-3.1 4 7.06 7.06 0 01-4.45 1.5h-16.75V52.4h16.17a7.7 7.7 0 014.5 1.45 11.44 11.44 0 013.44 3.92 11.85 11.85 0 011.35 5.77 13.16 13.16 0 01-1.16 5.61z" className="cls-8"/>
            </g>
            <g id="top_red_pill" ref={(e) => top_red_pill = e} data-name="top red pill">
                <path d="M658.16 284.91a30.13 30.13 0 00-30 30 30.13 30.13 0 0030 30h42.05v-60z" className="cls-5"/>
                <path d="M742.25 284.91h-42V345h42a30.13 30.13 0 0030-30 30.13 30.13 0 00-30-30.09z" className="cls-9"/>
            </g>
            <g id="top_beaker_outline" ref={(e) => top_beaker_outlineRef = e} data-name="top beaker outline">
                <path d="M198.88 256.18l-51.06-84.07V69.27h9.4a11.29 11.29 0 000-22.58H46.86a11.29 11.29 0 000 22.58H55v102.84L4 256.18a23.5 23.5 0 0020 35.7h154.79a23.5 23.5 0 0020.09-35.7z" className="cls-10"/>
                <path d="M198.88 256.18l-28.46-46.86c-9.4 5.15-21 10-31.6 10-17.89 0-33.32-16.45-63.84-16.45-19.13 0-36.32 7.41-46.49 12.94L4 256.18a23.5 23.5 0 0020 35.7h154.79a23.5 23.5 0 0020.09-35.7zM99.06 94.54a3.14 3.14 0 00-3.13-3.13H55v6.26h40.93a3.14 3.14 0 003.13-3.13zM99.06 122a3.14 3.14 0 00-3.13-3.13H55v6.27h40.93a3.14 3.14 0 003.13-3.14zM99.06 149.38a3.14 3.14 0 00-3.13-3.13H55v6.27h40.93a3.14 3.14 0 003.13-3.14z" className="cls-10"/>
            </g>
            <g id="top_scientist" data-name="top scientist" ref={(e) => top_scientistRef = e}>
                <path d="M339.52 110.69s1 66.47-12.62 105.33l18.1-6.81 17-20.46 9.55-10.9 3.06-14.66s-13.29-12.27-17.38-26.25-4.09-19.09-4.09-19.09zM456.77 110.69s-1 66.47 12.62 105.33l-18.07-6.81-17-20.46-9.55-10.9-3.06-14.66s13.29-12.27 17.38-26.25 4.09-19.09 4.09-19.09z" className="cls-11"/>
                <path d="M444.84 202.05s38.18 17.72 45 30.33 8.52 60.68 8.52 60.68-42.61 11.94-100.22 14.32c-57.6-2.39-100.21-14.32-100.21-14.32s1.7-48.06 8.52-60.68 45-30.33 45-30.33 35.79 54.88 55.56 54.88 37.83-54.88 37.83-54.88z" className="cls-12"/>
                <path d="M371.9 174.44s-31 11.25-33.75 54.54c0 0 11.59 11.93 22.84 16.7 0 0-2.73 5.11-6.48 11.25 22.16 10.56 31.74 29.43 43.29 50.11v-57.61s-31-23.52-36.81-51.13c.01 0 9.89-16.7 10.91-23.86zM423.71 174.44s31 11.25 33.75 54.54c0 0-11.59 11.93-22.84 16.7 0 0 2.73 5.11 6.47 11.25-22.15 10.56-31.73 29.43-43.29 50.11v-57.61s31-23.52 36.82-51.13c0 0-9.89-16.7-10.91-23.86z" className="cls-12"/>
                <path fill="#ae8658" d="M423.37 158.42s2.73 31.36 10.91 39.88l1.36 6.13s-8.35 29.66-38 48.07c-29.65-18.41-38-48.07-38-48.07l1.36-6.13c8.18-8.52 10.91-41.93 10.91-41.93s6.47 12.27 26.93 12.27 24.53-10.22 24.53-10.22z"/>
                <path d="M447.14 118.22c0 30.11-21.44 54.51-47.88 54.51s-47.88-24.4-47.88-54.51 21.43-63.77 47.88-63.77 47.88 33.66 47.88 63.77z" className="cls-14"/>
                <ellipse cx="454.84" cy="101.15" className="cls-14" rx="22.04" ry="9.09" transform="rotate(-62.47 454.836 101.148)"/>
                <ellipse cx="342.58" cy="101.15" className="cls-14" rx="9.09" ry="22.04" transform="rotate(-27.53 342.585 101.146)"/>
                <path d="M414.85 60.59s-18.07 26.24-64.77 28c0 0-4.09 6.81-4.43 10.91 0 0-12.61-7.16-10.57-35.46s31-62 69.54-48.4c0 0 17.12-8.69 36.82 11.25 27.95 28.29 15.33 56.24 15.33 56.24L453 95.35 443.82 93l-13.29-8.86s-8.86-7.53-15.68-23.55z" className="cls-11"/>
                <path d="M425.8 78.22c-2.42-.15-22.83-1.37-55.34 0-19.47.49-24.24 11-24.24 23.87 0 13.2 11.5 23.89 25.68 23.89a26.06 26.06 0 0022.1-11.74 4.88 4.88 0 018.78.09 26.05 26.05 0 0022 11.66c14.18 0 25.68-10.69 25.68-23.89.02-12.98-4.83-23.53-24.66-23.88z" className="cls-15"/>
                <path fill="#47abc6" stroke="#a3d5e3" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5" d="M424.8 120.54a20.77 20.77 0 01-17.39-9.07c-.12-.2-.25-.4-.38-.59l-.2-.32s-2.79-6.07-8.47-6.07c-5.89 0-8.82 6.59-8.82 6.59l-.18.27a20.72 20.72 0 01-17.46 9.19c-11.15 0-20.23-8.27-20.23-18.44 0-10.77 3.22-18 18.93-18.42 10.21-.43 20.13-.65 29.58-.65 14.79 0 23.64.53 25.26.64h.26c16.04.33 19.3 7.57 19.3 18.43 0 10.17-9 18.44-20.2 18.44z"/>
            </g>
            <g id="top_monitor" data-name="top monitor" ref={(e) => top_monitor = e}> 
                <rect width="172.19" height="141.41" x="211.84" y="253.79" className="cls-17" rx="9.67"/>
                <path d="M223.9 267.93h148.06v113.13H223.9z" className="cls-1"/>
                <path d="M238.87 395.19H357v38a3.6 3.6 0 01-3.6 3.6H242.47a3.6 3.6 0 01-3.6-3.6v-38z" className="cls-17"/>
                <circle cx="259.94" cy="417.24" r="9.98" className="cls-18"/>
                <circle cx="287.39" cy="417.24" r="9.98" className="cls-18"/>
                <circle cx="336.05" cy="417.24" r="6.45" className="cls-18"/>
                <path d="M375.56 322h-17.8a2.41 2.41 0 00-2.28 1.64l-.57 1.69-4.21 12.47a.47.47 0 01-.91-.09l-1.69-12.38-.45-3.33-2-14.5a2.93 2.93 0 00-2.42-2.44 2.63 2.63 0 00-2.62 1.56l-6.34 14a2.4 2.4 0 01-2.19 1.41h-11a2.41 2.41 0 00-2.28 1.64l-.57 1.69L306.82 359a.47.47 0 01-.91-.09l-4.57-33.61-.46-3.33-4.4-32.43a2.85 2.85 0 00-5.43-.77l-14.13 31.77a2.4 2.4 0 01-2.2 1.43h-6a2.41 2.41 0 00-2.28 1.64l-.57 1.69-4.21 12.47a.48.48 0 01-.92-.09l-1.68-12.38-.45-3.33-2-14.49a2.93 2.93 0 00-2.42-2.45 2.62 2.62 0 00-2.62 1.56l-6.34 14a2.41 2.41 0 01-2.19 1.41h-21.36a1.67 1.67 0 000 3.33h23.57a2.41 2.41 0 002.2-1.41l.86-1.92 4.69-10.35a.47.47 0 01.9.14l1.39 10.2.45 3.33 2.41 17.68a2.64 2.64 0 002.29 2.3h.33a2.63 2.63 0 002.49-1.79l5.61-16.51a2.41 2.41 0 012.28-1.64h5.73a2.4 2.4 0 002.2-1.43l.85-1.9 12.8-28.78a.47.47 0 01.9.13l3.9 28.65.45 3.33 5.18 38.09a2.78 2.78 0 002.54 2.44h.29a2.79 2.79 0 002.68-1.93l12.51-37a2.41 2.41 0 012.28-1.63h10.8a2.41 2.41 0 002.19-1.41l.87-1.92 4.68-10.35a.47.47 0 01.9.14l1.39 10.2.45 3.33 2.41 17.68a2.66 2.66 0 002.3 2.3h.32a2.61 2.61 0 002.49-1.79l5.61-16.51a2.41 2.41 0 012.28-1.64h15.41a1.67 1.67 0 100-3.33z" className="cls-8"/>
            </g>

            <g ref={(e) => finger_circlesRef = e}>
                <circle cx="501.57" cy="318.42" r="56.29" className="cls-12"/>
                <circle cx="501.57" cy="318.42" r="40.71" fill="#75c0d5" stroke="#377bb1" strokeMiterlimit="10"/>
                <circle cx="501.57" cy="318.42" r="24.88" className="cls-15"/>
            </g>
            <g id="top_finger" data-name="top finger" ref={(e) => top_fingerRef= e}>
                <path fill="#d3bb9c" d="M637.6 373.66c-.11-.15-.2-.31-.32-.47l-.17-.21a46.84 46.84 0 00-1.91-2.49l-22.08-28.62a10.79 10.79 0 00-15.12-2.05 10.79 10.79 0 00-3.26 13.06l-7-9.09a10.79 10.79 0 00-15.08-2.06A10.8 10.8 0 00569 353.8l-6.62-8.58a10.79 10.79 0 00-15.08-2.05 10.78 10.78 0 00-2.88 13.8l-33.93-44.63a10.8 10.8 0 00-15.08-2 10.8 10.8 0 00-2.06 15.09l58.15 76.49-31.39-12.34a10.8 10.8 0 00-13.95 6.09 10.8 10.8 0 006.08 14L584 437.75l.2.06a43.35 43.35 0 0053.44-64.15z"/>
            </g>
            <g id="top_pill" ref={(e) => top_pillRef = e} data-name="top pill">
                <path d="M184.5 176.33a30.11 30.11 0 00-30 30 30.11 30.11 0 0030 30h42.05v-60z" className="cls-15"/>
                <path d="M268.59 176.33h-42v60.06h42a30.11 30.11 0 0030-30 30.11 30.11 0 00-30-30.06z" className="cls-9"/>
            </g>
            </svg>

    )
}

export default Doctor