import React from "react";
import ShowHideDescription from "../SessionPresentationCards/PresentationElements/ShowHideDescription";


const VideoCardExtended = ({img, title, description, speakers, videoModalID, gainVideoAccess, openModalHandler}) => {
    return (
        <div className="col-12 my-5">
        <div>
          <div className="row">
            <div className="col-md-4" >
              <img className="img-fluid" src={img}  onClick={() => gainVideoAccess ? openModalHandler(videoModalID) : openModalHandler("requestVideoAccess")} style={{ cursor: "pointer" }}/>
            </div>
            <div className="col-md-8 text-left pl-lg-5">
              <div>
                <h5 className="font-weight-semi-bold grey-title mt-3 mt-md-0">
                  {title}
                </h5>
              </div>
              <div className="pt-1">
                <ShowHideDescription speakers={speakers} description={description} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default VideoCardExtended