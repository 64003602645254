import React from 'react'

export const TopArrow = (props) => {
  return (
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.96 58.19" className={`arrow ${props.className}`}>
      <path d="M1.4 40C15.62 2.66 62.48-5.93 103.79 7.4c26.86 8.66 44.63 28.88 51.64 38.42" stroke="#e50000" fill="none" stroke-miterlimit="10" stroke-width="3" className="path"/>
      <path d="M133.83 40.67l24.41-11.79a1.2 1.2 0 011.76 1.17l-2 27a1.2 1.2 0 01-1.86.9l-22.47-15.22a1.19 1.19 0 01.16-2.06z" fill="#e50000" className="arrow-head"/>
    </svg>
  )
}

export const LeftArrow = (props) => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 675.69 87.58" className={`arrow ${props.className}`}>
      <path className="cls-1" d="M.57,76.12c74-30.6,191.36-81,357.87-73.95,60.35,2.55,103.71,5.95,278,61.2" stroke="#e50000" fill="none" stroke-miterlimit="10" stroke-width="4" className="path"/>
      <path className="cls-2" d="M609.27,85.9,635.43,27a1.2,1.2,0,0,1,2.06-.22l38,52.13a1.2,1.2,0,0,1-.84,1.9l-64.13,6.81A1.19,1.19,0,0,1,609.27,85.9Z" fill="#e50000" className="arrow-head"/>
    </svg>
  )
}


export const RightArrow = (props) => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 675.69 87.58" className={`arrow ${props.className}`}>
      <path className="cls-1" d="M.57,76.12c74-30.6,191.36-81,357.87-73.95,60.35,2.55,103.71,5.95,278,61.2" stroke="#e50000" fill="none" stroke-miterlimit="10" stroke-width="4" className="path"/>
      <path className="cls-2" d="M609.27,85.9,635.43,27a1.2,1.2,0,0,1,2.06-.22l38,52.13a1.2,1.2,0,0,1-.84,1.9l-64.13,6.81A1.19,1.19,0,0,1,609.27,85.9Z" fill="#e50000" className="arrow-head"/>
    </svg>
  )
}



export const BottomArrow = (props) => {
    return (
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.96 58.19" className={`arrow ${props.className}`}>
        <path d="M1.4 40C15.62 2.66 62.48-5.93 103.79 7.4c26.86 8.66 44.63 28.88 51.64 38.42" stroke="#e50000" fill="none" stroke-miterlimit="10" stroke-width="3" className="path"/>
        <path d="M133.83 40.67l24.41-11.79a1.2 1.2 0 011.76 1.17l-2 27a1.2 1.2 0 01-1.86.9l-22.47-15.22a1.19 1.19 0 01.16-2.06z" fill="#e50000" className="arrow-head"/>
      </svg>
    )
  }


  export const BottomRightArrow = (props) => {
    return (
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.96 58.19" className={`arrow ${props.className}`}>
        <path d="M1.4 40C15.62 2.66 62.48-5.93 103.79 7.4c26.86 8.66 44.63 28.88 51.64 38.42" stroke="#e50000" fill="none" stroke-miterlimit="10" stroke-width="3" className="path"/>
        <path d="M133.83 40.67l24.41-11.79a1.2 1.2 0 011.76 1.17l-2 27a1.2 1.2 0 01-1.86.9l-22.47-15.22a1.19 1.19 0 01.16-2.06z" fill="#e50000" className="arrow-head"/>
      </svg>
    )
  }
  
