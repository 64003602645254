import React from 'react'
import styled from "styled-components";

const Button = styled.a`
  background: #02519d;
  color: white;
  padding: .8rem 3rem;
  font-family: FSAlbertArabicBold, Arial, Helvetica, sans-serif;
  cursor: pointer;
  text-transform: ${props => props.upper ? 'uppercase' : 'initial'};
  &:hover{
      color: white;
      text-decoration: none;
  }
`;

const ButtonLink = (props) => {
    return(
        <Button {...props} className={`d-inline-flex align-items-center ${props.className ? props.className : ''}`}>
            {props.children}
        </Button>
    )
}

export default ButtonLink