import React from "react";
import ReactHtmlParser from 'react-html-parser';

const Speakers = ({speakers}) => {
    return (
        speakers.map(speaker => {
            return (
                <div className="grey-title my-3" key={speaker.jobTitle}>
                <p style={{ fontSize: "1.1rem" }}>
                {ReactHtmlParser(speaker.nameAndCreds)}
                </p>
                <small>
                {ReactHtmlParser(speaker.jobTitle)} <br /> {ReactHtmlParser(speaker.company)}
                </small>
            </div>
            )
        })
    )
}

export default Speakers