import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../Modals/ModalsCSS/modal.css";
import SelectChallengeVideo from "../Modals/ChallengeVids/SelectChallengeVideo";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import LinkedInTag from "react-linkedin-insight";
import "./sidenavbar.css"
import MenuItems from './MenuItems';
import SideNavLogo from './SideNavLogo'
import DonationTracker from "./DonationTracker/DonationTracker";
import DonationTrackerModal from "./SideNavModals/DonationTrackerModal";
import LearnMore from './SideNavModals/LearnMore'
import SurveyModal from './SideNavModals/SurveyModal'

LinkedInTag.init("807690");

ReactGA.initialize("UA-203934706-1");

const SideNavBar = (props) => {
  useEffect(() => {ReactGA.pageview(window.location.pathname + window.location.search)});


  const [loading, setLoading] = useState(true);
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const toggleMenu = () => setOpenMobileNav((prevState) => !prevState);

  const [modalState, setModalState] = useState(null)
  const onOpenModal = (id) => setModalState(id);
  const onCloseModal = (id) => {
    setModalState(id)
    setLoading(true)
  }


  const hideSpinner = () =>  setLoading(false)


  // const onFinishedFormSubmit = () => {
  //   LinkedInTag.track(4690970);
  // };

  return (
    <>

      <button
        id="sidebarCollapse"
        type="button"
        onClick={toggleMenu}
        className={`btn shadow-sm mb-0 toggleBtn ${
          openMobileNav ? "active" : ""
        }`}
        id="toggleNavMobile"
        style={{ backgroundColor: "#f26925" }}>
        {openMobileNav ? (
          <FaTimes className="text-white" />
        ) : (
          <FaBars className="text-white" />
        )}
      </button>

      

      <div
        className={`bg-white ${openMobileNav ? "active" : ""}  ${props.svgPage ? "svgPage vertical-nav" : "sticky-vertical-nav"}`}
        id="sidebar"
      >
        {props.svgPage && <div className={`fixed-background bg-white`}></div>}
        {/* Navigation */}
        <ul className="nav flex-column bg-white">
          <SideNavLogo />
          <li className="nav-item" style={{ cursor: "pointer" }}>
            <a
              id="nav-hover"
              onClick={() => onOpenModal("whatsYourChallenge")}
              className="nav-link nav-text">
              What's Your Challenge?
            </a>
          </li>
          <MenuItems/>
          </ul>
          <DonationTracker bicyclistsFound={props.donationDisplay} modalState={onOpenModal} closeModal={onCloseModal} />
      </div>


      {/* Modal components */}

      <SelectChallengeVideo 
        modalId={modalState}
        closeModal={onCloseModal}
        modalState={onOpenModal}
        tour={false}
      />

      <LearnMore 
        modalId={modalState} 
        closeModal={onCloseModal} 
      />

      <DonationTrackerModal 
        bicyclistsFound={props.donationDisplay} 
        modalState={onOpenModal}
        modalId={modalState}
        closeModal={onCloseModal} 
      />
          
     <SurveyModal 
        modalId={modalState}
        closeModal={onCloseModal}
        hideSpinnerHandler = {hideSpinner}
        isLoading = {loading}
      />
        
    </>
  );
};
export default withRouter(SideNavBar);
