import React, { useState, useEffect } from "react";
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import { steps } from "./Tour/Data";
import "../../App.css";
import "react-responsive-modal/styles.css";
import CityscapeSVG from "../../components/CitySVG/CityscapeSVG/CityscapeSVG";
import PortraitMessage from "../../components/CitySVG/mobile/PortraitMessage";
import Tour from "reactour";
import SelectChallengeVideo from "../../components/Modals/ChallengeVids/SelectChallengeVideo";
import Music from "./../../components/CitySVG/Music";
import ButtonLink from "../../components/UI/Button/ButtonLink";
import { FaAngleRight } from "react-icons/fa";
import "../../components/Modals/ModalsCSS/modal.css";
import "./Home.css";
import {useWindowDimension} from '../../components/UI/useWindowDimension'
import ReactGA from 'react-ga';

ReactGA.initialize('UA-203934706-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const Home = (props) => {
  const [isTourOpen, setIsTourOpen] = useState(
    localStorage.getItem("showTour") || true
  );

  // const [isTourOpen, setIsTourOpen] = useState( true);
  
  const [width, height] = useWindowDimension();

  useEffect(() => {
      localStorage.setItem('showTour', isTourOpen);
    }, [isTourOpen]);


    const [modalState, setModalState] = useState(null)
    const onOpenModal = (id) => setModalState(id);
    const onCloseModal = (id) => setModalState(id);

    let svgViewBox = "0 0 3788 2556";

    if (width >= 800 && width <= 1024) {
      svgViewBox = "0 0 2700 2000";
    } else if (width === 1280 && height >= 680 && height <= 800) {
      svgViewBox = "150 0 2500 2000";
    }else if (width === 1280 && height >= 850 && height <= 1024) {
      svgViewBox = "150 0 2400 2000";
    }else {
      svgViewBox = "0 0 3788 2556";
    }
  
// Mobile portrait
  if (width <= 950 && height >= 440) {
    return (
      <>
        <SideNavBar {...props} />
        <PortraitMessage
          title={"Welcome to our HIMSS22 Virtual Experience"}
          links=""
        />
      </>
    );
    // Mobile landscape
  } else if (width >= 500
    && width <= 882) {
      //width was 882
      return(
        <>
          <SideNavBar {...props} />
          <div className="container-fluid" style={{overflow:'hidden', backgroundColor:'#56a145'}}>
          <div className="row">
            <div className="col-12">
              <div style={{height: '88vh'}} className="city-wrapper">
          <Music resetBikeSoundHandler={props.resetBikeSoundHandler} />
          <CityscapeSVG addDonationToken={props.addDonationToken} svgViewBox={svgViewBox}/>
          </div>
            </div>
          </div>
        </div>
        </>
      )

  } else {
    return (
      <>
        <Tour
          steps={steps}
          isOpen={JSON.parse(isTourOpen)}
          onRequestClose={() => setIsTourOpen(false)}
          showButtons={true}
          accentColor="#02519d"
          rounded={10}
          showNumber={false}
          showNavigation={false}
          prevButton={<ButtonLink style={{display:'none', visibility:'hidden'}} />}
          nextButton={
            <ButtonLink style={{padding:'.4rem 1.2rem', fontFamily: 'FSAlbertArabicRegular', position: 'absolute'}} upper>
              Next<FaAngleRight style={{marginBottom:'.1rem'}} />
            </ButtonLink>
            }
          lastStepNextButton={
            <ButtonLink style={{padding:'.4rem 1.2rem', fontFamily: 'FSAlbertArabicRegular', position: 'absolute'}} upper onClick={() => onOpenModal("whatsYourChallenge")}>
              Next<FaAngleRight style={{marginBottom:'.1rem'}} />
            </ButtonLink>
          }
        />

        <SelectChallengeVideo 
          modalId={modalState}
          closeModal={onCloseModal}
          modalState={onOpenModal}
          tour={true}
        />
        <SideNavBar {...props} />
        <div className="container-fluid" style={{overflow:'hidden', backgroundColor:'#56a145'}}>
          <div className="row">
            <div className="col-12">
              <div style={{height: '88vh'}} className="city-wrapper">
                <Music resetBikeSoundHandler={props.resetBikeSoundHandler} playBikeSound={props.playBikeSound} />
                <CityscapeSVG addDonationToken={props.addDonationToken} svgViewBox={svgViewBox}/>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Home;