import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/branding/BDLogo.svg"

const SideNavLogo = () => {
    return (
        <div className="px-3">
        <div className="media d-flex align-items-center">
          <Link to="/">
            <img
              src={logo}
              alt="BD logo - click to go to home page"
              width="95"
              className="mt-3"
            />
          </Link>
        </div>
        <hr style={{ color: "black" }} />
      </div>
    )
}

export default SideNavLogo