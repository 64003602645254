import React, {useState} from 'react'
import { FaAngleDoubleRight } from "react-icons/fa";
import styled from "styled-components";
import ReactHtmlParser from 'react-html-parser';
import Speakers from './Speakers';


const IconSpan = styled.span`
  cursor: pointer;
  font-weight: bold;
  color: #02519d;
`
const Wrapper = styled.div`
    transition: all 500ms ease-out;
    overflow-y: hidden;
    transform-origin: top; 
    display: flex;
    align-items: center;

    &.hide{
        transform: scaleY(0);
        height: 0rem
    
    }
    &.show{
        opacity: 1;
        transform: scaleY(1);
        height: min-content;
    }

`
const Description = styled.p`
    transition: all 350ms ease-out;
    display: inline-block;

    &.hide{
        opacity: 0;
        transform: translateY(-100%);
    }
    &.show{
        opacity: 1;
        transform: translateY(0%);
    }
`

const RightIcon = styled(FaAngleDoubleRight)`
    transition: all 350ms ease-in;
    text-decoration: underline;

    &.hide{
        transform: rotate(0deg)
    }
    &.show{
        transform: rotate(180deg)
    }
`

const ShowHideDescription = (props) => {
    const [showMore, setShowMore] = useState(false)
    const handleShowMoreOnClick = () => setShowMore(prevState => !prevState)
    let showLessOrMoreText = "MORE";
    showLessOrMoreText = showMore ? showLessOrMoreText = "LESS" : showLessOrMoreText = "MORE"; 

    return (
        <div>
        <Wrapper className={`${showMore ? 'show' : 'hide'}`}>
       
            <Description className={`${showMore ? 'show' : 'hide'} grey-title`}>
                {ReactHtmlParser(props.description)}
                {props.speakers && <Speakers speakers={props.speakers}/> }
            </Description> 
        </Wrapper>
        <IconSpan 
            onClick={handleShowMoreOnClick}>
             <RightIcon className={showMore ? 'show' : 'hide'}/> {showLessOrMoreText}
        </IconSpan>
        </div>
    )
}

export default ShowHideDescription;
