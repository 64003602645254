import React from "react";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import { Container, Row, Col } from "react-bootstrap";
import ReactGA from "react-ga";

ReactGA.initialize("UA-203934706-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const ChallengeVideos = ({
  mainTitle,
  description,
  data,
  handlerToOpenModal,
  tour,
}) => {
  return (
    <Container className={`pt-3 ${!tour ? "pb-5" : ""}`}>
      <Slide bottom>
        <Row>
          <Col sm={12}>
            <div style={{ color: "#02519d" }}>
              <p className="h4 font-weight-bold pb-2">{mainTitle}</p>
            </div>
            <div style={{ color: "#000" }}>{description}</div>
          </Col>
        </Row>
      </Slide>
      <Row className="pt-4">
        {data.map((video) => {
          return (
            <React.Fragment key={video.id}>
              <Col
                md={4}
                style={{ cursor: "pointer" }}
                onClick={(e) => handlerToOpenModal(e.currentTarget.id)}
                id={video.id}>
                <Fade top>
                  <img src={video.img} alt={video.alt} className="img-fluid" />
                  <p className="text-center font-weight-semi-bold pt-3 grey-title">
                    {video.title}
                  </p>
                </Fade>
              </Col>
            </React.Fragment>
          );
        })}
        {tour && (
          <div className="col-12 text-left pt-5">
            <p className="grey-title font-weight-semi-bold">Step 5 of 5</p>
          </div>
        )}
      </Row>
    </Container>
  );
};

export default ChallengeVideos;