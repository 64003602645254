import React from 'react'
import styled from "styled-components";

const Button = styled.button`
  background: #02519d;
  color: white;
  padding: .7rem 3rem;
  font-family: FSAlbertArabicBold, Arial, Helvetica, sans-serif;
  text-transform: ${props => props.upper ? 'uppercase' : 'initial'};

  &:hover{
      color: white !important;
  }
`;


const ButtonElement = (props) => {
    return (
        <Button className="btn text-white rounded-0 mb-4" {...props}>
            {props.children}
        </Button>
    )
}

export default ButtonElement