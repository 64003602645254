import React from 'react'
import ButtonLink from '../UI/Button/ButtonLink'
import ModalCloseButton from './ModalCloseButton'
import './PopUpReminder.css'

const PopUpReminder = ({showOrHide, onClose}) => {
    return (
        <div className={`container pop-up ${showOrHide ? 'show' : ''}`}>
            <div className="row">
                <div className="col-4">
                    <div className="bg-light sign text-center pb-4 pt-3 px-3">
                        <ModalCloseButton onClose={onClose} className="text-right" />
                        <p className="h4 font-weight-bold" style={{color: '#02519d'}}>We're here to help</p>
                        <p className="pb-3">Have a question or want to learn more? <br />
                         Click below to request information.
                        </p>
                        <ButtonLink to="/connect-with-us" onClick={() => onClose(false)} >Connect now</ButtonLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopUpReminder;