import React, {useEffect, useLayoutEffect} from 'react'
import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './divider.css'
gsap.registerPlugin(ScrollTrigger)

const Divider = () => {
    let div_beakerRef = null
    let div_backgroundRef = null
    let div_cubesRef = null
    let div_circuits = null
    let div_pill_pack = null
    let div_molecule = null


    //scroll trigger effects
    useLayoutEffect(() => {

        const tl = gsap.timeline({
            delay: .3,
            scrollTrigger: {
                trigger: '.divider',
                end: 'bottom top',
                toggleActions: "play none none reverse",
            }
        })

        tl
            .fromTo(div_backgroundRef, {transformOrigin: 'center center', scale: .75, opacity: 0}, {opacity: 1, scale: 1, duration: 1, ease: "sine.inOut"}, 0)
            .fromTo(div_pill_pack, {yPercent: -100, xPercent: 100, opacity: 0}, {yPercent: 0, xPercent: 0, opacity: 1, duration: 1.2, ease: "sine.inOut"}, 0)
            .fromTo(div_beakerRef, {yPercent: 100, opacity: 0, rotate: 180, transformOrigin: 'center center'}, {yPercent: 0, opacity: 1, rotate: 0, duration: 1, ease: "sine.inOut"}, "<")
            .fromTo(div_molecule, {yPercent: -100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1.2, ease: "sine.inOut"}, "<")
            .fromTo(div_cubesRef, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1.2, ease: "sine.inOut"}, "<")
            .fromTo(div_circuits, {yPercent: -100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1.2, ease: "sine.inOut"}, "<")

          return () =>  {
            let killAnimation = tl
            tl.kill()
         }
      }, []);


    //random floating effect for background
    useEffect(() => {

        let tweenProperty = (element, prop, min, max, direction) => {
    
            let randomDur = gsap.utils.random(.5, 1, .05, true);
    
            gsap.to(element, {
                [prop]: gsap.utils.random(min, max),
                duration: randomDur(),
                ease: "Sine.easeInOut",
                onComplete: tweenProperty,
                onCompleteParams: [element, prop, min, max, direction * -1]
            });
        }
    
        gsap.utils.toArray(div_backgroundRef).forEach(element => {
            tweenProperty(element, "x", 0, 5, 1)
            tweenProperty(element, "y", 10, 20, -1)
          });

      }, []);


    return(
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 -80 1448.79 400" className="enterprise-divider divider">
            <defs/>
            <path id="div_background" ref={(e) => div_backgroundRef = e} d="M19.52 73.92c24-22.06 64.89-32.86 92-37.79 37.23-6.8 75.42-6.11 113.2-3.77 90.28 5.57 179.71 20.4 269.83 28.21 80.29 6.95 162 8.29 241.35-7.52C812.9 37.73 883.37 7 964.46 13.81c33.69 2.83 67.35 18.93 100.41 27.66 48.51 12.82 94.3 19 146.25 13.74 37.82-3.82 75.23-10.11 113.3-11.35 36.63-1.19 86.22-3.22 111.72 28.47 20.11 25 14.93 64.27-6.33 88.28s-54.76 34.28-86.83 34.31-63.38-9-94.14-18.07c-28.67-8.46-63.15-22-93.25-17.2-32 5.12-64.19 16.63-95.8 24.42-77.87 19.19-139.43 25.13-219.85 12.74-104.42-16.09-204.69-21-310.53-21-85.66 0-168.83 20.38-253.37 24-11.4.49-22.79 1.22-34.19 1.81-72.61 3.72-166.8 13.93-222.48-43.93C6.75 144.57-2 126.49.38 108.44c1.8-13.8 8.96-25.17 19.14-34.52z" className="cls-1" data-name="div background"/>
            <g id="div_circuits" data-name="div circuits"  ref={(e) => div_circuits = e}>
                <rect width="181.68" height="132.26" x="1230.64" y="47.43" className="cls-2" rx="12.33" transform="rotate(-90 1321.485 113.555)"/>
                <rect width="160.54" height="113.8" x="1241.21" y="56.66" fill="#47abc6" rx="6.12" transform="rotate(-90 1321.485 113.555)"/>
                <path d="M1293.21 135a9.06 9.06 0 01-3.89 3.08l12.11 14.28v41.45h5v-43.3z" className="cls-1"/>
                <path d="M1285.87 120.6a9.06 9.06 0 109.07 9.08 9.09 9.09 0 00-9.07-9.08z" className="cls-4"/>
                <path d="M1354.07 140a9 9 0 01-4.15-2.77l-5.63 6.79v49.82h5v-48z" className="cls-1"/>
                <path d="M1356.82 122.24a9.07 9.07 0 109.08 9.07 9.07 9.07 0 00-9.08-9.07z" className="cls-4"/>
                <path d="M1344 94.45a9 9 0 01-3.84-3.15l-9.81 11.57v90.92h5v-89.1z" className="cls-1"/>
                <path d="M1347.59 77.05a9.07 9.07 0 109.07 9.07 9.07 9.07 0 00-9.07-9.07zM1317.93 53.53a9.07 9.07 0 00-2.27 17.85 9.41 9.41 0 002.27.29 9.07 9.07 0 000-18.14zM1289.14 77.05a9.06 9.06 0 109.07 9.07 9.07 9.07 0 00-9.07-9.07z" className="cls-4"/>
                <path d="M1320.55 71.28a9.08 9.08 0 01-2.62.39 9.41 9.41 0 01-2.27-.29v44.27l-19.73-23.5a9.1 9.1 0 01-4.24 2.68l23.31 27.78a2.52 2.52 0 00.65.53v70.65h5V71.73a3.42 3.42 0 00-.1-.45z" className="cls-1"/>
            </g>
            <g id="div_molecule" data-name="div molecule" ref={(e) => div_molecule = e}>
                <path d="M841.14 181.56a4.33 4.33 0 000-6.14l-12.57-13.16a24.25 24.25 0 11-6.29 6L835 181.59a4.35 4.35 0 006.14-.03z" className="cls-4"/>
                <path d="M828.54 162.26l12.57 13.16a4.34 4.34 0 01-6.11 6.17l-12.75-13.34-43.4-45.43a24.45 24.45 0 006.45-5.82zM656.36 106.52l9.89 13.48a4.34 4.34 0 01-7 5.18L648.91 111l-32.5-44.13a24.37 24.37 0 007.26-4.79z" className="cls-5"/>
                <path d="M789.6 108.14a24.19 24.19 0 01-4.3 8.87 24.45 24.45 0 01-6.45 5.81 24.24 24.24 0 01-34.58-9.93l15.23-4.21a4.34 4.34 0 10-2.35-8.36l-15.24 4.21a24.27 24.27 0 1147.69 3.61z" className="cls-4"/>
                <path d="M762.51 103.33a4.35 4.35 0 01-3 5.35l-15.23 4.21-1.63.45-53.33 15a24.24 24.24 0 00-1.89-8.49l53.66-15 .83-.23 15.24-4.21a4.35 4.35 0 015.35 2.92z" className="cls-5"/>
                <path d="M665.35 126a4.33 4.33 0 00.9-6.07l-9.89-13.43a24.24 24.24 0 11-7.45 4.52l10.37 14.09a4.34 4.34 0 006.07.89zM630.45 50.54a24.07 24.07 0 01-6.78 11.58 24.25 24.25 0 116.78-11.58z" className="cls-4"/>
            </g>
            <g id="div_cubes" data-name="div cubes" ref={(e) => div_cubesRef = e}>
                <g className="cube1">
                    <path d="M1045.67 63.1l-5.43-4.37-24.67-19.87v39.8l30.1 24.3V63.09v.01z" className="cls-6"/>
                    <path d="M1051.08 58.73l-5.41 4.36v39.86l30.02-24.29v-39.8l-24.61 19.87z" className="cls-7"/>
                    <path d="M1045.67 14.5l-30.1 24.36 24.67 19.87 5.43 4.37v-.01l5.41-4.36 24.61-19.87-30.02-24.36z" className="cls-8"/>
                </g>
                <g className="cube2">
                    <path d="M984.69 114.9l-5.43-4.36-24.67-19.87v39.79l30.1 24.3V114.9z" className="cls-6"/>
                    <path d="M990.1 110.54l-5.41 4.36v39.85l30.02-24.29V90.67l-24.61 19.87z" className="cls-7"/>
                    <path d="M984.69 66.3l-30.1 24.37 24.67 19.87 5.43 4.36 5.41-4.36 24.61-19.87-30.02-24.37z" className="cls-8"/>
                </g>
                <g className="cube3"> 
                    <path d="M1106.65 116.12l-5.43-4.36-24.67-19.87v39.79l30.1 24.3v-39.86z" className="cls-6"/>
                    <path d="M1112.06 111.76l-5.41 4.36v39.85l30.02-24.29V91.89l-24.61 19.87z" className="cls-7"/>
                    <path d="M1106.65 67.52l-30.1 24.37 24.67 19.87 5.43 4.36 5.41-4.36 24.61-19.87-30.02-24.37z" className="cls-8"/>
                </g>
                <g className="cube4">
                    <path d="M1045.67 167.93l-5.43-4.37-24.67-19.87v39.79l30.1 24.3v-39.86.01z" className="cls-6"/>
                    <path d="M1051.08 163.56l-5.41 4.36v39.86l30.02-24.3v-39.79l-24.61 19.87z" className="cls-7"/>
                    <path d="M1045.67 119.33l-30.1 24.36 24.67 19.87 5.43 4.37v-.01l5.41-4.36 24.61-19.87-30.02-24.36z" className="cls-8"/>
                </g>

            </g>
            <g id="div_beaker" data-name="div beaker" ref={(e) => div_beakerRef = e}>
                <path d="M492.32 171.37l-41.59-68.47V15.56h-75.59v87.34l-41.59 68.47a19.15 19.15 0 0016.36 29.08H476a19.14 19.14 0 0016.32-29.08z" className="cls-9"/>
                <path fill="#75c0d5" stroke="#377bb1" strokeMiterlimit="10" d="M492.32 171.37l-23.18-38.16c-7.66 4.2-17.12 8.12-25.73 8.12-14.58 0-27.15-13.4-52-13.4-15.58 0-29.58 6-37.86 10.54l-20 32.9a19.14 19.14 0 0016.36 29.08H476a19.14 19.14 0 0016.32-29.08z"/>
                <path d="M411 39.72a2.56 2.56 0 00-2.55-2.55h-33.31v5.1h33.33a2.56 2.56 0 002.53-2.55zM411 62.06a2.56 2.56 0 00-2.55-2.56h-33.31v5.11h33.33a2.56 2.56 0 002.53-2.55zM411 84.39a2.56 2.56 0 00-2.55-2.55h-33.31v5.1h33.33a2.56 2.56 0 002.53-2.55z" className="cls-11"/>
                <rect width="108.28" height="18.39" x="358.44" y=".75" className="cls-9" rx="9.19"/>
            </g>
            <g id="div_pill_pack" data-name="div pill pack" ref={(e) => div_pill_pack = e}>
                <rect width="169.27" height="99.57" x="68.98" y="65.13" className="cls-2" rx="25.72"/>
                <circle cx="100.51" cy="97.22" r="13.92" className="cls-5"/>
                <circle cx="100.51" cy="134.28" r="13.92" className="cls-5"/>
                <circle cx="135.92" cy="97.22" r="13.92" className="cls-5"/>
                <circle cx="135.92" cy="134.28" r="13.92" className="cls-5"/>
                <circle cx="171.32" cy="97.22" r="13.92" className="cls-5"/>
                <circle cx="171.32" cy="134.28" r="13.92" className="cls-5"/>
                <circle cx="206.72" cy="97.22" r="13.92" className="cls-5"/>
                <circle cx="206.72" cy="134.28" r="13.92" className="cls-5"/>
            </g>
            </svg>

    )
}

export default Divider