import React from "react";
import { Modal } from "react-responsive-modal";
import ChallengeVideos from "./ChallengeVideos";
import SingleChallengeVideo from "./SingleChallengeVideo";
import { DataChallengeVideos } from "./DataChallengeVideos";

const SelectChallengeVideo = ({modalId, closeModal, modalState, tour}) => {
    const handlerToOpenChildModal = (currentVideoClicked) => {
        // closeStartingChallengeVideosModal();
        // setOpenModal(currentVideoClicked);
        modalState(currentVideoClicked)
      };
    return (
        <>
        <Modal
            open={modalId == "whatsYourChallenge"}
            onClose={closeModal}
            center
            classNames={{
            modal: "customModal",
            }}>
            <ChallengeVideos
                mainTitle="What's your challenge?"
                description="Select the main challenge impacting your health system's medication management processes below, and learn how BD can help."
                handlerToOpenModal={handlerToOpenChildModal}
                data={DataChallengeVideos}
                tour = {tour}
            />
      </Modal>
      {DataChallengeVideos.map((video) => {
        return (
          <SingleChallengeVideo
            {...video}
            show={modalId === video.id}
            handlerToCloseModal={closeModal}
            id={video.id}
            key={video.id}
          />
        );
      })}
      </>

    )
}

export default SelectChallengeVideo