import React from "react";
import PresentationModal from "./PresentationModal";

const PresentationModals = ( {modalId, closeModal}) => {
    return (
      presentations.map(presentation => {
        return (
            <PresentationModal
              key={presentation.id}
              id={presentation.id}
              title={presentation.title}
              iframeSrc={presentation.iframeSrc}
              modalId={modalId}
              closeModal={closeModal}
            />
        )
      })
     
    )
}

export default PresentationModals

const presentations = [ {
  id:"analyticsPharmacyProgramLassiter",
  title:"Analytics: the key to high performance pharmacy programs",
  iframeSrc:"https://player.vimeo.com/video/588551720?autoplay=1&color=ED621D&byline=0&portrait=0",
},
{
  id:"connectedMedicationManagementWellman",
  title:"Connected medication management: insights from the BD/ASHP21 Assessment Model",
  iframeSrc:"https://player.vimeo.com/video/632143127?h=e2babdf9c1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
},
{
  id:"redefiningConnectivityKuca",
  title:"Redefining connectivity: medication management interoperability at Catholic Health System",
  iframeSrc:"https://player.vimeo.com/video/589942438?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=bd04520bfe"
},
{
  id:"dataMountainDumitru",
  title:"Scaling the data mountain: leveraging analytics to drive operational excellence",
  iframeSrc:"https://player.vimeo.com/video/589973674?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=438fd1af10"
},
{
  id:"socialHourPanelSuarez",
  title:"Social hour panel: strategies for partnering with medical device manufacturers to strengthen cybersecurity",
  iframeSrc:"https://player.vimeo.com/video/632116084?h=fb171176ef&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
},
{
  id:"improvingMedicationDurham",
  title:"Improving the medication management system with rule-based EMR initiatives",
  iframeSrc:"https://player.vimeo.com/video/588547007?autoplay=1&color=ED621D&byline=0&portrait=0"
},
{
  id:"are-you-exposed",
  title:"Are you exposed?",
  iframeSrc:"https://player.vimeo.com/video/720922025?h=265f4d7eb2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=5847"
},
{
  id:"practical-applications",
  title:"Don’t stop yet: practical applications of connected medication management",
  iframeSrc:"https://player.vimeo.com/video/720803570?h=9146b75ebd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
}
]