import React from 'react'
import styled from "styled-components";
import Slide from 'react-reveal/Slide';
import ReactHtmlParser from 'react-html-parser';

const Jumbotron = styled.section`
    background-image: url(${props => props.image});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;
    min-height: 19rem;
    @media (min-width: 300px) and (max-width: 768px) {
        background-image: linear-gradient(to bottom, rgba(16, 159, 150, 0.7), rgba(16, 159, 150, 0.6)), url(${props => props.image});
      }
    @media (min-width: 769px) and (max-width: 1499px) {
        background-image: linear-gradient(to right, rgba(16, 159, 150, 0.8), rgba(16, 159, 150, 0.3)), url(${props => props.image});
      }
`;

const Header = (props) => {
    return (
            <Jumbotron className="row text-white p-md-5 py-5" image={props.image}>
           
                 
                        <div className="col-sm-8 col-lg-7 pl-md-4 d-flex justify-content-center flex-column">
                            <Slide left>
                                <p className="h1 pt-4 pt-md-0">
                                    {ReactHtmlParser(props.title)}
                                </p>
                            </Slide>
                            <p className="lead">{ReactHtmlParser(props.description)}</p>
                        </div>
                        <div className="col"></div>
              
            
            </Jumbotron>
    )
}

export default Header