import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { FaVolumeDown, FaVolumeMute } from "react-icons/fa";

const MusicDiv = styled.div`
  position: absolute;
  cursor: pointer;
  right: 0rem;
  top: 0rem;
  padding: 2rem;
  z-index: 10;
`;

const PlayingIcon = styled(FaVolumeDown)`
  color: #fff;
  font-size: 2rem;
`;

const MutedIcon = styled(FaVolumeMute)`
  color: #fff;
  font-size: 2rem;
`;

const Music = (props) => {
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [isBellPlaying, setIsBellPlaying] = useState(false);
  const audio = useRef(null);
  const bell = useRef(null);

  const onClickHandler = () => {
    setIsMusicPlaying((prevState) => !prevState);
    setIsBellPlaying((prevState) => !prevState);
    audio.current.muted = isMusicPlaying;
    bell.current.muted = isBellPlaying;
    !isMusicPlaying ? audio.current.play() : audio.current.pause();

  };

  useEffect(() => {   
    if(isBellPlaying) {
      bell.current.currentTime = 0
      bell.current.play()
    } else {
      bell.current.pause();
    }

    props.resetBikeSoundHandler()
  
   },[props.playBikeSound]);

  return (
    <>
      <audio
        src={require("../../music/bicycle-bell.mp3")}
        muted
        autoPlay
        ref={bell}
      />
    <MusicDiv onClick={onClickHandler} id="music">
      {isMusicPlaying ? <PlayingIcon /> : <MutedIcon />}
      <audio
        src={require("../../music/city-sound-new01_mixdown.mp3")}
        muted
        autoPlay
        loop
        ref={audio}
      />
    </MusicDiv>
    </>
  );
};

export default Music;