import React, { useEffect, useState, useRef } from "react";
import GlobalStyle from "./globalStyles";
import Home from "./pages/HomePage/Home";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import SolutionsMarket from "./pages/Solutions Marketplace/SolutionsMarket"
import ConnectWithUs from "./pages/Connect With Us/ConnectWithUs"
import ResourceHub  from "./pages/BD Resource Hub/ResourceHub"
import Education from "./pages/Education Center/Education"
import OurCommunity from "./pages/Support Our Community/OurCommunity"
import EnterpriseinventoryOp from "./pages/Enterprise Inventory Optimization/EnterpriseInventoryOp"
import PharmacyAutomation from "./pages/Pharmacy Automation/PharmacyAutomation"
import EnterpriseInterop from "./pages/Enterprise Interoperability/EnterpriseInterop"
import CloudBasedAnalytics from "./pages/Cloud Based Analytics/CloudBasedAnalytics";
import EducationArchive from './pages/Archive/Education Center/EducationArchive'
import NotFound from './pages/NotFound/NotFound'
import PopUpReminder from "./components/PopUpReminder/PopUpReminder";
import Cookies from 'js-cookie'
import ScrollToTop from "./components/UI/ScrollToTop";
import { gsap } from "gsap"
import {bikeCircleAnimation} from '../src/components/CitySVG/animations/bikeCircle'
import "jquery/dist/jquery.min.js";
import "jquery/dist/jquery.slim.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactGA from 'react-ga';
import LinkedInTag from 'react-linkedin-insight';



LinkedInTag.init('807690');
LinkedInTag.track(4690970);

ReactGA.initialize('UA-203934706-1');
ReactGA.pageview(window.location.pathname + window.location.search);

let inTenMinutes = new Date(new Date().getTime() + 10 * 60 * 1000);

function App() {
  let donationColorTimeLine = useRef(gsap.timeline({ paused: false }));
  Cookies.set('popUp', 'yes', { expires: inTenMinutes})
  const [showPopUpReminder, setShowUpReminder] = useState(false)
  const [countPopUpReminder, setPopUpReminder] = useState(0)
  const [donationTokenCountFromHome, setDonationTokenCountFromHome] = useState(0)
  const [clickedOnBikes, setClickedOnBike] = useState ({
    rightTopRoadBike : false,
    theaterBike : false,
    leftRoadBike : false,
    marketplaceBike: false,
    hospitalBike: false,
    signBike: false
}) 

const [playBikeSound, setPlayBikeSound] = useState (false)
const [userSubmittedInfo, setUserSubmittedInfo] = useState(false)
const resetBikeSound = () => setPlayBikeSound(false)

let interval;

  const handleCloseOnClick = () => setShowUpReminder(false)

  const getDonationsFoundHandler = (bikeId, bikeCircle) => {

    if (!clickedOnBikes[bikeId]) {

      setPlayBikeSound(true)
      bikeCircleAnimation(bikeCircle)
      donationColorTimeLine.current
      .to('.donation-background', {backgroundColor: '#c8f5cf', duration: .6})
      .to('.donation-background', {backgroundColor: '#c8e2f5', duration: .3})

      setDonationTokenCountFromHome(prevState => prevState + 1)

      setClickedOnBike(prevState => ({
        ...prevState,
        [bikeId] : true
      }))
    }
  }

  useEffect(() => {
     interval = setInterval(() => { checkIfPopUpShouldShow()}, 300000);
  }, [])


const handlerTheaterUserSubmission = () => setUserSubmittedInfo(true)

  const checkIfPopUpShouldShow = () => {
    if(Cookies.get('popUp') != null && !showPopUpReminder) {
      setShowUpReminder(true)
      setPopUpReminder(prevCount => prevCount + 1)
    } else if ( Cookies.get('popUp') === null) {
      if(countPopUpReminder < 2 && !showPopUpReminder) {
        setShowUpReminder(true)
        setPopUpReminder(prevCount => prevCount + 1)
        clearInterval(interval)
      } else {
        clearInterval(interval)
      }
    }
  }   

    return (
      <>
        <BrowserRouter>
        <ScrollToTop/>
        <GlobalStyle />
          <Switch >
            <Route path="/" exact render={() => <Home addDonationToken={getDonationsFoundHandler} donationDisplay={donationTokenCountFromHome} svgPage={true} playBikeSound={playBikeSound} resetBikeSoundHandler={resetBikeSound} />}  />
            <Route path="/solutions-marketplace" render={() => <SolutionsMarket donationDisplay={donationTokenCountFromHome}  svgPage={true} />} />
            <Route path="/resource-hub" render={() => <ResourceHub donationDisplay={donationTokenCountFromHome} />} />
            <Route path="/connect-with-us" render={() => <ConnectWithUs donationDisplay={donationTokenCountFromHome} />} />
            <Route path="/education-center" render={() => <Education donationDisplay={donationTokenCountFromHome} handleUserAccess={handlerTheaterUserSubmission}  gainVideoAccess={userSubmittedInfo} />} />
            <Route path="/education-center-archive" render={() => <EducationArchive donationDisplay={donationTokenCountFromHome} handleUserAccess={handlerTheaterUserSubmission}  gainVideoAccess={userSubmittedInfo} />} />
            <Route path="/community" render={() => <OurCommunity donationDisplay={donationTokenCountFromHome} />} />
            <Route path="/support-our-community" render={() => <OurCommunity donationDisplay={donationTokenCountFromHome} />} />
            <Route path="/cloud-based-analytics" render={() => <CloudBasedAnalytics donationDisplay={donationTokenCountFromHome} />} />
            <Route path="/enterprise-iosv" render={() => <EnterpriseinventoryOp donationDisplay={donationTokenCountFromHome} />} />
            <Route path="/pharmacy-automation" render={() => <PharmacyAutomation donationDisplay={donationTokenCountFromHome} />} />
            <Route path="/enterprise-interoperability" render={() => <EnterpriseInterop donationDisplay={donationTokenCountFromHome} />} />
            <Route path="*"  render={() => <NotFound />} />
          </Switch>
          <Footer />
          <PopUpReminder showOrHide={showPopUpReminder} onClose={handleCloseOnClick} />
        </BrowserRouter>
      </>
        
      );
      
}

export default App;