import React, { useState, useEffect } from "react";
// import CityMarketPlaceSVG from "../../components/CitySVG/CityMarketPlaceSVG.js/CityMarketPlaceSVG";
import SolutionsMarketPlaceSVG from "../../components/CitySVG/SolutionsMarketPlaceSVG/SolutionsMarketPlaceSVG";
import PortraitMessage from "../../components/CitySVG/mobile/PortraitMessage";
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-203934706-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const linkData = [
  { url: "/cloud-based-analytics", name: "Cloud-Based Analytics" },
  { url: "/pharmacy-automation", name: "Pharmacy Automation" },
  { url: "/enterprise-interoperability", name: "Enterprise Interoperability" },
  { url: "/enterprise-iosv", name: "Enterprise Inventory Optimization & Supply Visibility" },
];

const SolutionsMarket = (props) => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const onWindowResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => window.removeEventListener("resize", onWindowResize);
  }, []);



  if (windowWidth <= 768 && windowHeight >= 440) {
    return (
      <>
        <SideNavBar {...props} />
        <PortraitMessage
          title={"Welcome to the Solutions Marketplace"}
          links={linkData}
        />
      </>
    );
  } else {
    return (
      <>
        <SideNavBar {...props} />
        <div className="container-fluid solutions-container-fluid" style={{overflow:'hidden'}}>
          <div className="row">
            <div className="col-12">
              <div style={{height: '90%'}} className="market-wrapper">
                <SolutionsMarketPlaceSVG  />
              </div>
            </div>
          </div>
        </div>
        
      </>
    );
  }
};

export default SolutionsMarket;