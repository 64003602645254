import React, {useState} from "react";
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import Header from "../../components/UI/Header/Header";
import HeaderImg from '../../images/headers/marketplace-header.png'
import videoPoster from '../../images/archive/HIMSS22/bd-video-poster-lady-icons.jpg'
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import KeepExploring from "../../components/UI/Button/KeepExploring";
import ReactGA from 'react-ga';
import PharmacyDivider from './PharmacyDivider'
import PharmacyDoctor from './PharmacyDoctor'
import TopDescription from '../components/TopDescription'
import VideoModal from "../components/VideoModals/VideoModal";
import MobileDivider from './mobile/MobileDivider'
import {useWindowDimension} from '../../components/UI/useWindowDimension'
import ButtonHref from "../../components/UI/Button/ButtonHref"
import hackensackPDF from "../../Documents/archive/HIMSS22/bd-hsca-hsia-hackensack-meridian-case-study.pdf";


ReactGA.initialize('UA-203934706-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const PharmacyAutomation = (props) => {

  const [modalState, setModalState] = useState(null)
  const onOpenModal = (id) => {setModalState(id)};
  const onCloseModal = () => setModalState(null);
  const [width, height] = useWindowDimension();

  return (
    <>

      <div className="container-fluid">
        
        <div className="row">
          <div className="col-xl-2 p-xl-0">
            <SideNavBar {...props}/>
          </div>
          <div className="col-xl-10 bg-wild-sand subpage-content ">
            <Header image={HeaderImg} title="Pharmacy Automation"/>
    
              <div className="row px-md-5 d-flex align-items-center pt-5">
                <TopDescription 
                  description="BD HealthSight™ closes gaps and creates visibility across BD medication management solutions and your EMR to help improve efficiency, 
                  reduce waste and improve patient outcomes."
                />
                <div className="col-lg-7 text-md-right order-1 order-lg-2 mb-5">
                  <PharmacyDoctor />
                </div>
              </div>
              <div className="row px-md-5">
                <div className="col-md-12">
                {width <= 500 ? 
                  <MobileDivider />
                  : <PharmacyDivider />
                }
                </div>
              </div>
              <div>

                <VideoModal 
                  id="medicationManagement"
                  modalId={modalState}
                  closeModal={onCloseModal}
                  title="Effectively Managing Supply Challenges: Lessons Learned from the COVID-19 Pandemic"
                  iframeSrc="https://player.vimeo.com/video/580004224?autoplay=1&color=ED621D&byline=0&portrait=0"
                />

              <div className="row px-md-5" style={{marginBottom: '6.25rem'}} >
              <Slide left>
                <div className="col-md-6 pb-5">

                  <img
                    className="img-fluid"
                    src={videoPoster}
                    alt="Video poster of illustrated woman and icons"
                    onClick={() => onOpenModal("medicationManagement")}
                    style={{cursor:'pointer'}}
                  />
                </div>
                </Slide>
                  <div className="col-md-6">
                    <Slide right>
                      <h3 className="font-weight-bold grey-title">Informed Clinical Care & Enterprise Visibility</h3>
                    </Slide>
                    <Fade>
                    <div className="hr-line"></div>
                    <p className="mt-4 mb-5 grey-title lead">
                      With increased visibility, prioritization and data standardization, clinical pharmacists can now make 
                      clinical decisions in near real-time and based upon what’s most important to their own individual workflows.  
                      The combination of BD HealthSight™ Viewer, BD Pyxis™ IV Prep and  BD HealthSight™ Clinical Advisor’s powerful 
                      and flexible analytics, alerting and reporting enables you to align to what’s most important to you – 
                      helping enable timely clinical decisions and insightful adjustments to medication therapy.
                    </p>
                    <h5 className="font-weight-semi-bold grey-title mt-4 pt-3">
                      Hackensack Meridian Case Study
                    </h5>
                      <div className="mb-5">
                      <ButtonHref href={hackensackPDF} target="_blank">
                        Download Now
                      </ButtonHref>
                      </div>
                      </Fade>
                  </div>
                </div>

                  {/* <InformationCard 
                    img={}
                    imgAlt=""
                    title=""
                    description=""
                    contentLabel=""
                    btnText=""
                    btnDownload={}
                  /> */}
                  <div className="row pb-5">
                    <div className="col-12 text-center py-5">
                      <KeepExploring solutions={true} />
                    </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PharmacyAutomation;