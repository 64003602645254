import React, {useLayoutEffect, useEffect, useRef} from 'react'
import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './mobileDivider.css'
gsap.registerPlugin(ScrollTrigger)

const MobileDivider = () => {
  let div_pill_pack = null
  let div_molecule = null
  let div_beaker = null
  let divider_background= null

  useLayoutEffect(() => {

    const tl = gsap.timeline({
        delay: .3,
        scrollTrigger: {
            trigger: '.mobileDivider-rp',
            end: 'bottom top',
        }
    })

    tl
        .fromTo(divider_background , { opacity: 0}, { opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
        .fromTo(div_pill_pack, {xPercent: -200, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
        .fromTo(div_beaker, {xPercent: 200, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
        .fromTo(div_molecule, {xPercent: -200, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)

      return () =>  {
        let killAnimation = tl
        tl.kill()
     }

  }, []);

    return (
<svg xmlns="http://www.w3.org/2000/svg" id="divider" viewBox="0 0 1491.96 1267.15" className="mobileDivider-rp">
  <path fill="#d0ecf3" d="M0 0h1491.96v1267.15H0z" ref={(e) => divider_background = e}/>
  <g id="div_molecule" data-name="div molecule" ref={(e) => div_molecule = e}>
    <path d="M928.28 1190.3a6.33 6.33 0 0 0 0-9l-18.3-19.15a35.27 35.27 0 1 1-9.16 8.71l18.56 19.43a6.32 6.32 0 0 0 8.9.01Z" className="cls-2"/>
    <path d="m909.94 1162.2 18.3 19.15a6.33 6.33 0 0 1-8.9 9l-18.56-19.43-63.21-66.15a35.39 35.39 0 0 0 9.4-8.46ZM659.21 1081l14.39 19.55a6.32 6.32 0 1 1-10.15 7.54l-15.1-20.51-47.35-64.24a35.33 35.33 0 0 0 10.58-7Z" className="cls-3"/>
    <path d="M853.23 1083.39a35.35 35.35 0 0 1-66 6.91l22.19-6.12A6.33 6.33 0 0 0 806 1072l-22.2 6.13a35.35 35.35 0 1 1 69.46 5.26Z" className="cls-2"/>
    <path d="M813.77 1076.38a6.32 6.32 0 0 1-4.37 7.8l-22.19 6.12-2.36.66-77.67 21.79a35.31 35.31 0 0 0-2.75-12.37l78.15-21.92 1.19-.33L806 1072a6.32 6.32 0 0 1 7.77 4.38Z" className="cls-3"/>
    <path d="M672.3 1109.43a6.33 6.33 0 0 0 1.3-8.85L659.21 1081a35.26 35.26 0 1 1-10.86 6.58l15.1 20.51a6.32 6.32 0 0 0 8.85 1.34ZM621.47 999.5a35.37 35.37 0 1 1-25.62-43 35.17 35.17 0 0 1 25.62 43Z" className="cls-2"/>
  </g>
  <g id="div_beaker" data-name="div beaker" ref={(e) => div_beaker = e}>
    <path d="M873.28 716.55 809 610.63V475.54H692v135.09l-64.3 105.92a29.6 29.6 0 0 0 25.31 45H848a29.61 29.61 0 0 0 25.28-45Z" className="cls-4"/>
    <path fill="#7abbd2" stroke="#377bb1" strokeMiterlimit="10" strokeWidth="1.55" d="m873.28 716.55-35.85-59c-11.85 6.48-26.49 12.55-39.81 12.55-22.55 0-42-20.72-80.44-20.72-24.1 0-45.76 9.33-58.57 16.3l-30.91 50.9a29.6 29.6 0 0 0 25.31 45H848a29.6 29.6 0 0 0 25.28-45.03Z"/>
    <path d="M747.52 512.91a4 4 0 0 0-3.94-3.95H692v7.89h51.56a4 4 0 0 0 3.96-3.94ZM747.52 547.45a4 4 0 0 0-3.94-3.94H692v7.89h51.56a4 4 0 0 0 3.96-3.95ZM747.52 582a4 4 0 0 0-3.94-4H692v8h51.56a4 4 0 0 0 3.96-4Z" className="cls-6"/>
    <rect width="167.48" height="28.44" x="666.2" y="452.63" className="cls-4" rx="9.19"/>
  </g>
  <g id="div_pill_pack" data-name="div pill pack" ref={(e) => div_pill_pack = e}>
    <rect width="368.85" height="216.97" x="561.84" y="41.72" fill="#fff" stroke="#a9a9ab" strokeLinejoin="round" strokeWidth="3.27" rx="25.72"/>
    <circle cx="630.55" cy="111.63" r="30.34" className="cls-8"/>
    <circle cx="630.55" cy="192.39" r="30.34" className="cls-8"/>
    <circle cx="707.69" cy="111.63" r="30.34" className="cls-8"/>
    <circle cx="707.69" cy="192.39" r="30.34" className="cls-8"/>
    <circle cx="784.84" cy="111.63" r="30.34" className="cls-8"/>
    <circle cx="784.84" cy="192.39" r="30.34" className="cls-8"/>
    <circle cx="861.98" cy="111.63" r="30.34" className="cls-8"/>
    <circle cx="861.98" cy="192.39" r="30.34" className="cls-8"/>
  </g>
</svg>

    )
}

export default MobileDivider