import React from "react";
import Header from "../../components/UI/Header/Header";
import HeaderImg from '../../images/headers/marketplace-header.png'
import inventoryPDF from "../../Documents/archive/HIMSS22/bd-eBook-7-keys-to-unlock-medication-inventory-optimization.pdf";
import frustrationsPDF from "../../Documents/archive/HIMSS22/BD-key-insights-white-paper-solving-frontline-frustrations-with-better-medication-inventory-management.pdf";
import optimizePDF from "../../Documents/archive/HIMSS22/BD-key-insights-white-paper-How-to-effectively-optimize-medication-inventory.pdf";
import portalPDF from "../../Documents/archive/HIMSS22/bd-pyxis-supply-knowledge-portal-overview-for-himss-approved.pdf";
import EIOImg from "../../images/archive/HIMSS22/bd-enterprise-inventory-infographic.jpg";
import staffMemberImg from "../../images/archive/HIMSS22/bd-desk-pc-staff-member.jpg";
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import KeepExploring from "../../components/UI/Button/KeepExploring";
import ReactGA from 'react-ga';
import Doctor from "./Doctor";
import Divider from "./Divider";
import TopDescription from '../components/TopDescription'
import InformationCard from "../components/InformationCard";
import {useWindowDimension} from '../../components/UI/useWindowDimension'
import MobileDivider from "./mobile/MobileDivider";



ReactGA.initialize('UA-203934706-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const EnterpriseinventoryOp = (props) => {
  const [width, height] = useWindowDimension();
return (
<div className="container-fluid">
  <div className="row">
    <div className="col-xl-2 p-xl-0">
      <SideNavBar {...props} />
    </div>
    <div className="col-xl-10 bg-wild-sand subpage-content ">
      <Header 
        image={HeaderImg}
        title="Enterprise Inventory Optimization <span class='d-xl-block h1'>& Supply Visibility"
      />

        <div className="row px-md-5 d-flex align-items-center pt-5">
          <TopDescription 
            description="BD Pyxis™ medication and supply technologies can address your medication error challenges, 
            give time back to your clinical teams and ensure medications are available when and where they are needed."
          />
          <div className="col-lg-7 text-md-right order-1 order-lg-2 mb-5">
            <Doctor />
          </div>
        </div>
        <div className="row px-md-5">
          <div className="col-md-12 pb-5">
          {width <= 500 ? 
                  <MobileDivider />
                  : <Divider />
                }
          </div>
        </div>
     
       
            <InformationCard 
              img={EIOImg}
              imgAlt="Enterprise Inventory Optimization infographic for inventory waste, worflow inefficiencies, and managing shortages"
              title="Enterprise Inventory Optimization"
              description="Creating a comprehensive view of your medication inventory—from wholesaler orders to pharmacy to cabinets to 
              bedside—makes for more effective overall management. With enterprise inventory optimization from BD, you get 
              near real-time visibility to help you create more efficient workflows and achieve your inventory management goals.  
              One automated source for all your connected technologies can empower your pharmacy to be more proactive and make 
              better-informed decisions, helping to identify potential medication errors, minimize latency and create positive 
              financial impacts through cost avoidance. "
              pdfsArr={[
                {title:"7 Keys: Inventory Optimization E-Book", download: inventoryPDF}, 
                {title:"Optimize Inventory Investments White Paper", download: optimizePDF},
                {title:"Remove Staff Roadblocks White Paper", download: frustrationsPDF}
              ]}
            /> 
              
              <InformationCard 
              img={staffMemberImg}
              imgAlt="Receptionist at desk looking at papers"
              title="BD Pyxis™ Supply with Knowledge Portal"
              description="BD Pyxis™ Supply Knowledge Portal is a single web portal to monitor and make data-driven decisions 
              around your supply chain management. This powerful, user-friendly application lets you quickly 
              translate data into actionable information with on-demand reporting and analytics.​  
              You will gain enhanced system-wide web based reporting to help reduce costs and standardize physician preference cards. "
              pdfsArr={[{title:"Pyxis Supply Knowledge Portal", download: portalPDF}]}
            /> 
         
              <div className="row pb-5">
                  <div className="col-12 text-center py-5">
                    <KeepExploring solutions={true} /> 
                  </div>
                </div>
  
    </div>
  </div>
</div>
);
};

export default EnterpriseinventoryOp;