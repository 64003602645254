import React from "react";
import { Modal } from "react-responsive-modal";
import checkmark from "../../../../images/forms/checkmark__branded.svg";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";


const ThankYou = ({modalId, id, closeModal, title, description}) => {
    return (
      <Modal
        open={modalId === id}
        onClose={closeModal}
        classNames={{
          modal: "customModal",
        }}
        center
        focusTrapped={false}>
        <div style={{ overflow: "hidden" }} className="py-md-4 p-0 container">
          <div className="row">
            <div className="col-4 offset-4 my-3">
              <Bounce top>
                <img src={checkmark} alt="" className="img-fluid" />
              </Bounce>
            </div>
            <div className="pb-4 col-12 text-center">
              <Fade>
                <h4 style={{ color: "#02519d" }} className="font-weight-bold">
                  {title}
                </h4>
                <p className="lead">{description}</p>
              </Fade>
            </div>
          </div>
        </div>
      </Modal>
    )
}

export default ThankYou