import React from 'react'
import { FaTimes } from 'react-icons/fa'

const ModalCloseButton = ({onClose, className}) => {
    return(            
        <div className={className} onClick={() => onClose(false)} style={{cursor: 'pointer'}}>
            <FaTimes/>
        </div>
    )
}

export default ModalCloseButton