import React from 'react'
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

const KeepExploringLink = styled(Link)`
  color: #004594;
  transition: 300ms;
  &:hover{
      text-decoration: underline;
  }
`;


const KeepExploring = ({solutions = false}) => {
    return (
      <>
        <KeepExploringLink to="/" className="font-weight-semi-bold h5">
            <FaAngleDoubleLeft/> Keep exploring city
      </KeepExploringLink>

      {solutions && 
      <>
        <span className="px-2 lead grey-title d-none d-md-inline">|</span>
        <KeepExploringLink to="/solutions-marketplace" className="font-weight-semi-bold h5 d-block d-md-inline py-4">
          Go to Solutions Marketplace <FaAngleDoubleRight/> 
        </KeepExploringLink>
      </>
      }
    </>
    )
}

export default KeepExploring