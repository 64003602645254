import React from "react"

const DonationTracker = ( {bicyclistsFound, modalState}) => {
    return (
        <>
            <div
                className="py-2 donation-tracker-wrapper"
                style={{ backgroundColor: "#f6f4f4" }}>
                <h5 className="font-weight-bold text-center mb-0">
                    Donation Tracker
                </h5>
                <div
                    className="mb-2 text-center donation-background"
                    style={{ backgroundColor: "#c8e2f5", padding: "15px" }}>
                    <p className="pt-1">Bicyclists Found:</p>
                    <span className="h4" id="output-area">
                    {bicyclistsFound} <small>of</small> 6
                    </span>
                </div>
                <div className="text-center pt-2">
                    <button
                    type="button"
                    className="btn text-white rounded-0 w-75"
                    onClick={() => modalState("finishExperience")}
                    style={{ backgroundColor: "#f26925" }}>
                    Finish experience
                    </button>
                </div>
                <div className="text-center pt-2 mb-4">
                    <a
                    onClick={() => modalState("learnMore")}
                    className="font-weight-semi-bold"
                    style={{
                        textDecoration: "none",
                        color: "#004594",
                        cursor: "pointer",
                    }}>
                    Learn more
                    </a>
                </div>
            </div>
        </>
    )
}

export default DonationTracker