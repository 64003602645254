import React from "react";
import ReactHtmlParser from 'react-html-parser';

const PresentationTime = ({date, time, isOdd, location}) => {
    return (
        <>
        {date &&
            <div className={`col-md-4 pt-4 ${isOdd ? "bg-white" : ""}`}>
            <h5 className="font-weight-semi-bold my-3 grey-title">
                {ReactHtmlParser(date)} 
                <br /> 
                {time &&
                    <>
                    {ReactHtmlParser(time)}
                    <br />
                   </>

                }
                <br /> 
                {ReactHtmlParser(location)}
                
                </h5>
            </div> 
        }
        </>
    )
}

export default PresentationTime