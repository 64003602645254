import React, {useLayoutEffect, useEffect, useRef} from 'react'
import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './mobileDivider.css'
gsap.registerPlugin(ScrollTrigger)

const MobileDivider = () => {
    let div_secure_lock_cloud = null;
    let div_stethoscope= null;
    let div_laptop = null;
    let divider = null;
    let computerGraphics = useRef(gsap.timeline({ repeat: -1, yoyo: true}));

    useLayoutEffect(() => {

        const tl = gsap.timeline({
            delay: .3,
            scrollTrigger: {
                trigger: '.mobileDivider-hpc',
                end: 'bottom top',
            }
        })

        tl
            .fromTo(divider, { opacity: 0}, { opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
            .fromTo(div_secure_lock_cloud, {xPercent: -200, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
            .fromTo(div_laptop , {xPercent: 200, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
            .fromTo(div_stethoscope , {xPercent: -200, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)

          return () =>  {
            let killAnimation = tl
            tl.kill()
         }

      }, []);


      useEffect(() => {
        computerGraphics.current
        .to('#div_laptop > .graphics', 1, {
            opacity: 0,
            stagger: -.6,
            delay: 2
        })

        return () =>  {
            let killAnimation = computerGraphics.current
            killAnimation.kill()
         }
        
      }, []);

    return (
            <svg xmlns="http://www.w3.org/2000/svg" id="divider" viewBox="0 0 1491.96 1267.15" className="mobileDivider-hpc">
            <defs>
                <style>
                </style>
            </defs>
            <path fill="#d0ecf3" d="M0 0h1491.96v1267.15H0z" ref={(e) => (divider = e)}/>
            <g id="div_secure_lock_cloud" data-name="div secure lock cloud"  ref={(e) => (div_secure_lock_cloud = e)}>
                <path fill="#fff" stroke="#a9a9ab" strokeLinejoin="round" strokeWidth="3.72" d="M939.83 200a61.68 61.68 0 0 0-61.68-61.68 62.75 62.75 0 0 0-7.17.43c0-.95.07-1.91.07-2.88a65.35 65.35 0 0 0-91-60.09A80.08 80.08 0 0 0 631.93 118c0 2.44.13 4.84.34 7.23a68.7 68.7 0 1 0-11.83 136.45h257.87A61.66 61.66 0 0 0 939.83 200Z"/>
                <rect width="140.58" height="106.71" x="680.37" y="198.05" fill="#47abc6" rx="6.51"/>
                <path d="M720.54 155.23A27.32 27.32 0 0 1 747.83 128h5.66a27.31 27.31 0 0 1 27.28 27.28v42.82h23v-42.87a50.35 50.35 0 0 0-50.29-50.29h-5.66a50.35 50.35 0 0 0-50.29 50.29v42.82h23Z" className="cls-4"/>
                <rect width="11.5" height="39.62" x="745.49" y="228.72" fill="#2e667e" rx="2.32"/>
                <path d="M820.8 219.62h-23.16M820.8 235.65h-23.16M820.8 251.69h-23.16M820.8 267.73h-23.16M820.8 283.76h-23.16M703.66 219.62H680.5M703.66 235.65H680.5M703.66 251.69H680.5M703.66 267.73H680.5M703.66 283.76H680.5" className="cls-6"/>
            </g>
            <g id="div_stethoscope" data-name="div stethoscope" ref={(e) => (div_stethoscope = e)}>
                <path fill="#929495" d="M822.08 855.23h-3.68a5.65 5.65 0 0 0 0 11.29h3.68A18.44 18.44 0 0 1 840.5 885v102.47a29.82 29.82 0 0 1-13.49 25l-85 55.78-85-55.78a29.82 29.82 0 0 1-13.5-25V885a18.45 18.45 0 0 1 18.43-18.43h3.67a5.65 5.65 0 1 0 0-11.29h-3.67A29.75 29.75 0 0 0 632.17 885v102.47a41 41 0 0 0 18.6 34.43l85.33 56v111.38a45.47 45.47 0 1 0 90.94 0v-20.84a5.64 5.64 0 0 0-11.28 0v20.84a34.19 34.19 0 0 1-68.37 0V1078.2l85.81-56.3a41.05 41.05 0 0 0 18.59-34.43V885a29.75 29.75 0 0 0-29.71-29.77Z"/>
                <circle cx="822.16" cy="1130.81" r="37.62" fill="#fff" stroke="#a9a9ab" strokeLinejoin="round" strokeWidth="2.96"/>
                <circle cx="822.16" cy="1130.81" r="17.22" className="cls-4"/>
                <ellipse cx="667.44" cy="862.05" className="cls-9" rx="13.45" ry="19.89"/>
                <ellipse cx="813.23" cy="862.05" className="cls-9" rx="13.45" ry="19.89"/>
            </g>
            <g id="div_laptop" data-name="div laptop" ref={(e) => (div_laptop = e)}>
                <path fill="#cccccb" d="M506.29 679.22h478.4v28.94a11.07 11.07 0 0 1-11.07 11.07H517.36a11.07 11.07 0 0 1-11.07-11.07v-28.94Z"/>
                <path d="M562.3 408.79h366.39a9.91 9.91 0 0 1 9.91 9.91v260.52H552.38V418.71a9.91 9.91 0 0 1 9.92-9.92Z" className="cls-4"/>
                <rect width="324.51" height="215.46" x="582.8" y="436.05" fill="#fff" rx="2.86"/>
                <path d="M770.48 473.87h-49.39l-24.7 35.68 24.7 35.68h49.39l24.69-35.68-24.69-35.68zM770.48 545.23h-49.39l-24.7 35.69 24.7 35.68h49.39l24.69-35.68-24.69-35.69z" className="cls-12 graphics"/>
                <path d="M696.39 509.55H647l-24.7 35.68 24.7 35.69h49.39l24.7-35.69-24.7-35.68zM844.57 509.55h-49.4l-24.69 35.68 24.69 35.69h49.4l24.69-35.69-24.69-35.68z" className="cls-13 graphics"/>
            </g>
            </svg>
    )
}

export default MobileDivider