import React from "react";
import { Link } from 'react-router-dom';
import notFoundImg from '../../images/ASHP21/NotFound/404-manhole-BD-colors.svg'
import logo from '../../images/branding/BDLogo.svg'
import './notFound.css'


const NotFound = (props) => {
return (
    <section  className="bg-wild-sand notFound pt-5" style={{height: 'auto', minHeight: '100%'}}>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <Link to="/">
                        <img
                        src={logo}
                        alt="BD logo - click to go to home page"
                        width="95"
                        className="mt-3"
                        />
                    </Link>
                </div>
            </div>
        </div>
        <div className="container pt-5 mt-5 bg-wild-sand">
            <div className="row align-items-center">
                <div className="col-md-6">
                    <p className="display-5 mt-3 font-weight-bold" style={{color:'rgb(1, 70, 149)'}}>Oops! Page not found.</p>
                    <p className="h4 mt-3">You may have mistyped the address or the page may have been moved.</p>
                    <p className="h4 mt-3">Explore our <Link to="/" className="h4 font-weight-bold" style={{color:'rgb(1, 70, 149)'}}>HIMSS22 Virtual Experience Cityscape</Link> and discover solutions
                        for connected medication and much more.
                    </p>
                </div>
                <div className="col-md-6 my-5">
                    <img src={notFoundImg} alt="construction man in a manhole with a 404 sign" />
                </div>
            </div>
        </div>
    </section>
    )
}

export default NotFound