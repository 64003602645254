import React, {useEffect, useLayoutEffect} from 'react'
import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './divider.css'
gsap.registerPlugin(ScrollTrigger)

const Divider = () => {

    let magnifierRef = null;
    let div_cloudRef = null;
    let div_background = null
    let top_conveyor_belt = null
    let top_stretcher = null
    let div_files = null


    //scroll trigger effects
    useLayoutEffect(() => {

        const tl = gsap.timeline({
            delay: .3,
            scrollTrigger: {
                trigger: '.divider',
                end: 'bottom top',
                toggleActions: "play none none reverse",
            }
        })

        tl
            .fromTo(div_background, {transformOrigin: 'center center', scale: .75, opacity: 0}, {opacity: 1, scale: 1, duration: 1, ease: "sine.inOut"}, 0)
            .fromTo(top_stretcher, {yPercent: 100, opacity: 0, transformOrigin: 'center center'}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, "<")
            .fromTo(div_cloudRef, {yPercent: -100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1.2, ease: "sine.inOut"}, "<")
            .fromTo(div_files, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1.2, ease: "sine.inOut"}, "<")
            .fromTo(top_conveyor_belt, {yPercent: -100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1.2, ease: "sine.inOut"}, 0)
            .fromTo(magnifierRef, {transformOrigin: 'center center', xPercent: -200}, {yPercent: 0, xPercent: 0, duration: 1.5, ease: "sine.inOut"}, ">")

          return () =>  {
            let killAnimation = tl
            tl.kill()
         }
      }, []);


    //random floating effect for background
    useEffect(() => {

        let tweenProperty = (element, prop, min, max, direction) => {
    
            let randomDur = gsap.utils.random(.5, 1, .05, true);
    
            gsap.to(element, {
                [prop]: gsap.utils.random(min, max),
                duration: randomDur(),
                ease: "Sine.easeInOut",
                onComplete: tweenProperty,
                onCompleteParams: [element, prop, min, max, direction * -1]
            });
        }
    
        gsap.utils.toArray('#div_background').forEach(element => {
            tweenProperty(element, "x", 0, 5, 1)
            tweenProperty(element, "y", 10, 20, -1)
          });
      }, []);

    return(
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 -80 1448.79 400" className="enterprise-inventory-divider divider">
            <defs/>
            <path id="div_background" ref={(e) => div_background= e} fill="#d0ecf3" d="M667.39 206.24c33.05-5.77 65.88-13 98.6-20.05 65.18-14.07 131.48-27.13 198.44-26.68 66.26.44 129 20.46 192.31 37.38 58 15.5 120.56 34.37 181.24 30.64 29-1.79 58.57-11.18 79.37-31.51 19.85-19.41 29.7-52.1 14.8-75.54-13.87-21.8-42.58-27.84-68.28-30.63-74.78-8.1-149.89-2.49-225-6.95-82.37-4.9-207.23-41.26-289.72-42.12-165-1.72-287.66 38.09-452 52.5-42.83 3.72-79.78 11.16-123.85 3.77C211.84 86.74-1 19.09 0 139.89c.24 28.79 23.31 53.4 49.9 64.47 30.29 12.64 66.1 12.43 98.48 12.57 86.55.37 170.83-14.4 257.4-14.26 58.76.1 114.24 18 174.17 14.36 29.36-1.79 58.49-5.74 87.44-10.79z" data-name="div background"/>
            <path id="div_cloud" ref={(e) => div_cloudRef = e} d="M1108.92 144a36.51 36.51 0 00-47.47-31 41.06 41.06 0 00-69.05-24.28 2.27 2.27 0 01-3.85-1.64V87a58.15 58.15 0 00-116-6.25 51.88 51.88 0 00-47.69 54.61c1.51 27.67 25.13 49 52.84 49h195.16a36.54 36.54 0 0036.06-40.36z" className="cls-2" data-name="div cloud"/>
            <g id="div_files"  ref={(e) => div_files = e}  data-name="div files">
                <rect width="91.23" height="115.34" x="1273.87" y="83.53" className="cls-2" rx="3.31"/>
                <rect width="91.23" height="115.34" x="1250.71" y="58.11" className="cls-2" rx="3.31"/>
                <rect width="91.23" height="115.34" x="1227.55" y="32.69" className="cls-2" rx="3.31"/>
                <path fill="#e1212b" d="M1273.16 71.35c7.49-9.32 22.47-9.32 30-2.76s7.49 19.66 0 32.77c-5.24 9.83-18.72 19.66-30 26.21-11.23-6.55-24.71-16.38-30-26.21-7.49-13.11-7.49-26.22 0-32.77s22.52-6.59 30 2.76z"/>
                <path fill="#fff" d="M1290 86.67h-8a.87.87 0 01-.87-.87v-8a.87.87 0 00-.88-.87h-14.07a.87.87 0 00-.88.87v8a.87.87 0 01-.87.87h-8a.87.87 0 00-.87.87v14.08a.87.87 0 00.87.87h8a.87.87 0 01.87.87v8a.87.87 0 00.88.87h14.07a.87.87 0 00.88-.87v-8a.87.87 0 01.87-.87h8a.87.87 0 00.87-.87V87.54a.87.87 0 00-.87-.87z"/>
            </g>
            <g id="top_stretcher" ref={(e) => top_stretcher = e} data-name="top stretcher">
                <path d="M686.85 80.57a5.41 5.41 0 00-7.6-.93l-50.87 39.75-50.86-39.75a5.42 5.42 0 10-6.67 8.54l48.73 38.09-41.38 32.35a5.42 5.42 0 006.68 8.54l43.5-34 43.51 34a5.42 5.42 0 006.68-8.54l-41.38-32.35 48.73-38.09a5.41 5.41 0 00.93-7.61z" className="cls-5"/>
                <path d="M583.35 164.05h96.54v7.74h-96.54z" className="cls-5"/>
                <path d="M696.14 59.52H574.81L509.4 31.36a15.15 15.15 0 00-19.82 8 15.13 15.13 0 007.95 19.82l66.65 28.69a15 15 0 007.29 1.9h124.67a15.1 15.1 0 100-30.2z" className="cls-6"/>
                <circle cx="577.28" cy="167.92" r="19.49" className="cls-7"/>
                <circle cx="681.81" cy="167.92" r="19.49" className="cls-7"/>
            </g>
            <g id="top_conveyor_belt" ref={(e) => top_conveyor_belt = e} data-name="top conveyor belt">
                <path fill="#929495" stroke="#5d574f" stroke-miterlimit="10" d="M327.48 143.63H61.19a15.7 15.7 0 100 31.39h266.29a15.7 15.7 0 000-31.39z"/>
                <path d="M71.57 169.28l30.49-26.61-37.4-.56-9.15 7.98 16.06 19.19zM158.8 169.28l30.5-26.61-37.41-.56-9.15 7.98 16.06 19.19zM246.03 169.28l30.5-26.61-37.41-.56-9.14 7.98 16.05 19.19zM333.27 169.28l30.49-26.61-37.4-.56-9.15 7.98 16.06 19.19z" className="cls-9"/>
                <path d="M383.73 94.61H117.44a15.64 15.64 0 00-11.32 4.86l-54.36 47.35a15.6 15.6 0 019.43-3.19h266.29A15.67 15.67 0 01339 170l53.83-46.89a15.72 15.72 0 006.62-12.77 15.74 15.74 0 00-15.72-15.73z" className="cls-10"/>
                <circle cx="63.97" cy="159.33" r="12.53" className="cls-10"/>
                <circle cx="151.21" cy="159.33" r="12.53" className="cls-10"/>
                <circle cx="238.44" cy="159.33" r="12.53" className="cls-10"/>
                <circle cx="325.68" cy="159.33" r="12.53" className="cls-10"/>
                <circle cx="63.97" cy="159.33" r="6.26" className="cls-9"/>
                <circle cx="151.21" cy="159.33" r="6.26" className="cls-9"/>
                <circle cx="238.44" cy="159.33" r="6.26" className="cls-9"/>
                <circle cx="325.68" cy="159.33" r="6.26" className="cls-9"/>
                <path d="M107.07 40.09L80 58.88h62.72l27.07-18.79h-62.72z" className="cls-13"/>
                <path d="M128.08 40.09l-27.06 18.79h20.06l27.06-18.79h-20.06z" className="cls-13"/>
                <path d="M142.72 58.88v71.95l27.07-18.78V40.09l-27.07 18.79z" className="cls-14"/>
                <path d="M107.07 58.88H80v71.95h62.72V58.88h-35.65z" className="cls-15"/>
                <path d="M101.18 58.88h20.06v27.06h-20.06zM188.25 40.09l-27.06 18.79h62.72l27.06-18.79h-62.72z" className="cls-13"/>
                <path d="M209.26 40.09L182.2 58.88h20.06l27.06-18.79h-20.06z" className="cls-13"/>
                <path d="M223.91 58.88v71.95l27.06-18.78V40.09l-27.06 18.79z" className="cls-14"/>
                <path d="M188.25 58.88h-27.06v71.95h62.72V58.88h-35.66z" className="cls-15"/>
                <path d="M182.36 58.88h20.06v27.06h-20.06z" className="cls-13"/>
                <path d="M269.44 58.88h-27.07v71.95h62.72V58.88h-35.65z" className="cls-15"/>
                <path d="M269.44 40.09l-27.07 18.79h62.72l27.06-18.79h-62.71z" className="cls-13"/>
                <path d="M290.45 40.09l-27.06 18.79h20.05l27.07-18.79h-20.06zM263.55 58.88h20.06v27.06h-20.06z" className="cls-13"/>
                <path d="M305.09 58.88v71.95l27.06-18.78V40.09l-27.06 18.79z" className="cls-14"/>
                <g ref={(e) => magnifierRef = e}>
                    <circle cx="329.22" cy="59.93" r="55.05" fill="#a3d5e3" style={{opacity: '.45'}}/>
                    <path fill="#377bb1" d="M397.42 166.62l-35.59-60-.08.06c-.88.59-1.78 1.13-2.67 1.7-1.05.67-2.1 1.33-3.16 2l-7 3.71-.09-.17c-1 .42-2.07.81-3.13 1.15a31.2 31.2 0 01-3.58.89 11.31 11.31 0 00.8 1.69l35.69 60.18a6.09 6.09 0 005.54 2.74 15 15 0 007.44-2.31 16.32 16.32 0 005.11-4.61c1.64-2.49 1.91-5.03.72-7.03z"/>
                    <path d="M329.57 121.51a60.76 60.76 0 1160.75-60.76 60.82 60.82 0 01-60.75 60.76zm0-112a51.21 51.21 0 1051.2 51.2 51.26 51.26 0 00-51.2-51.16z" className="cls-6"/>
                </g>
                
            </g>
            </svg>

    )
}

export default Divider