import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../../../images/branding/footer-logo.png'
import mobilePreview from '../../../images/mobile/mobileCityPreview.JPG'
import {FaMobileAlt, FaAngleRight } from "react-icons/fa";
import './PortraitMessage.css'

const PortraitMessage = (props) => {
    const {links = ''} = props;
    console.log(props.mobilePreview)

    return(
        <div 
        style={{height: '120%', 
              backgroundImage: `linear-gradient(to top, rgba(16, 159, 150, .7), rgba(16, 159, 150, 1)), url(${mobilePreview})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}
      >
        <div className="container pt-5">
          <div className="row">
            <div className="col-12 text-center pt-5">
              <img src={logo} alt="" className="img-fluid" />
            </div>
            <div className="col-12 pt-4 text-white">
              <p className="h2 font-weight-bold text-center">{props.title}</p>
              <FaMobileAlt className="h2 rotate-mobile d-block mx-auto" />
              <p className="py-2 ">For the best interactive experience, we recommend viewing our site on your desktop.</p>
              <p className="">Otherwise, rotate your device to landscape mode for this page to view our dynamic city!</p>
              <p className="py-2 ">You may rotate your device vertically on the other pages.</p>
              {props.links !== '' && 
                <>
                    <p className="lead font-weight-bold my-3">Solutions Marketplace Quick Access:</p>
                    <ul className="pb-3" style={{listStyleType: 'none'}}>
                    { links.map((link, index) => {
                            return (
                                <li key={index} className="py-2 lead"><FaAngleRight/><Link className="text-white font-weight-bold" to={link.url}>{link.name}</Link></li>
                            )
                        }) }
                    </ul>
                </>
              }
              
            </div>
          </div>
        </div>
      </div>
    )
}


export default PortraitMessage