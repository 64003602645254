import React from "react";
import Header from "../../components/UI/Header/Header";
import HeaderImg from '../../images/headers/marketplace-header.png'
import KeepExploring from "../../components/UI/Button/KeepExploring";
import Doctor from './Doctor'
import Divider from './Divider'
import nurseStationImg from "../../images/archive/HIMSS22/bd-nurse-med-station.jpg";
import nurseDispenseStationImg from "../../images/archive/HIMSS22/bd-nurse-med-station-2.jpg";
import supplyPDF from "../../Documents/archive/HIMSS22/uhn-transforming-the-or-supply-chain.pdf";
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import ReactGA from 'react-ga';
import TopDescription from "../components/TopDescription";
import {useWindowDimension} from '../../components/UI/useWindowDimension'
import MobileDivider from "./mobile/MobileDivider";
import InformationCard from "../components/InformationCard";

ReactGA.initialize('UA-203934706-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const EnterpriseInterop = (props) => {
  const [width, height] = useWindowDimension();

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-2 p-xl-0">
          <SideNavBar {...props} />
        </div>

        <div className="col-xl-10 bg-wild-sand subpage-content" >
          <Header 
            image={HeaderImg}
            title="Enterprise Interoperability"
          />

      
            <div className="row px-md-5 d-flex align-items-center pt-5">
              <TopDescription 
                description="BD Pyxis™ Interoperability leverages your EMR investment to enhance workflow efficiency and charge capture across the health system."
              />
              <div className="col-lg-7 text-md-right order-1 order-lg-2 pb-5 pb-lg-0">
                <Doctor />
              </div>
            </div>
            <div className="row px-lg-5">
              <div className="text-center col-12">
              {width <= 500 ? 
                  <MobileDivider />
                  : <Divider />
                }
              </div>
            </div>


            <InformationCard 
                img={nurseStationImg}
                imgAlt="Nurse at medical station"
                title="BD Pyxis™ Interoperability"
                description="Leverage the investment in your EHR by connecting it to BD Pyxis™ MedStation™ ES, 
                BD Pyxis™ Anesthesia System ES, and BD Pyxis™ Logistics solutions. 
                Enhance clinical workflow efficiency for Nursing, Pharmacy and in the OR."
              />

              <InformationCard 
                img={nurseDispenseStationImg}
                imgAlt="Nurse at medical station dispensing medication"
                title="BD Pyxis™ Supply Interoperability"
                description="Leverage the investment in your procedural EHR by connecting it to the BD Pyxis™ 
                Supply point of care technologies. This feature provides improved case pick accuracy, 
                reduced manual processes & documentation, improved regulatory compliance and charge capture. 
                You clinicians will be able to chart, charge and reorder all in one step!"
                contentLabel="OR Transformation: UHN Case Study"
                btnText="Download now"
                btnDownload={supplyPDF}
              /> 
          <div className="row pb-5">
            <div className="col-12 text-center py-5">
              <KeepExploring solutions={true} /> 
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default EnterpriseInterop;