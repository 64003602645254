import React, {useLayoutEffect, useEffect, useRef} from 'react'
import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './divider.css'
gsap.registerPlugin(ScrollTrigger)

const Divider = () => {

    let div_secure_lock_cloud = null;
    let div_laptop = null;
    let div_chart = null;
    let div_syringe = null;
    let div_white_pill = null;
    let divider_background = null;
    let div_stethoscope = null
    let computerGraphics = useRef(gsap.timeline({ repeat: -1, yoyo: true}));


    //scroll trigger effects
    useLayoutEffect(() => {

        const tl = gsap.timeline({
            delay: .3,
            scrollTrigger: {
                trigger: '.divider',
                end: 'bottom top',
                toggleActions: "play none none reverse",
            }
        })

        tl
            .fromTo(divider_background, {transformOrigin: 'center center', scale: .75, opacity: 0}, {opacity: 1, scale: 1, duration: 1, ease: "sine.inOut"}, 0)
            .fromTo(div_secure_lock_cloud, {yPercent: -100, xPercent: 100, opacity: 0}, {yPercent: 0, xPercent: 0, opacity: 1, duration: 1.2, ease: "sine.inOut"}, 0)
            .fromTo(div_laptop, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, "<")
            .fromTo(div_stethoscope, {yPercent: -100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1.2, ease: "sine.inOut"}, "<")
            .fromTo(div_chart, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1.2, ease: "sine.inOut"}, "<")
            .fromTo(div_syringe, {yPercent: -100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1.2, ease: "sine.inOut"}, "<")
            .fromTo(div_white_pill, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, transformOrigin: 'center center', rotate: 360, duration: 1.2, ease: "sine.inOut"}, "<")

          return () =>  {
            let killAnimation = tl
            tl.kill()
         }

      }, []);

      useEffect(() => {
        computerGraphics.current
        .to('#div_laptop > .graphics', 1, {
            opacity: 0,
            stagger: -.6,
            delay: 2
        })

        return () =>  {
            let killAnimation = computerGraphics.current
            killAnimation.kill()
         }
        
      }, []);



    //random floating effect for background
    useEffect(() => {

        let tweenProperty = (element, prop, min, max, direction) => {
    
            let randomDur = gsap.utils.random(.5, 1, .05, true);
    
            gsap.to(element, {
                [prop]: gsap.utils.random(min, max),
                duration: randomDur(),
                ease: "Sine.easeInOut",
                onComplete: tweenProperty,
                onCompleteParams: [element, prop, min, max, direction * -1]
            });
        }
    
        gsap.utils.toArray('.cloud-divider > path').forEach(element => {
            tweenProperty(element, "x", 0, 5, 1)
            tweenProperty(element, "y", 10, 20, -1)
          });

      }, []);

    return (
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 -80 1448.79 400" className="cloud-divider divider">
            <defs/>
            <defs>
                <style>
                </style>
            </defs>
            <path id="divider_background" ref={(e) => (divider_background = e)}  fill="#d0ecf3" d="M1429.26 72.16c-24-22.06-64.89-32.86-91.94-37.79-37.24-6.8-75.42-6.11-113.2-3.78-90.29 5.58-179.76 20.41-269.89 28.22-80.28 7-162 8.29-241.35-7.52C635.89 36 565.41 5.21 484.32 12.05 450.63 14.88 417 31 383.92 39.71c-48.51 12.82-94.3 19-146.25 13.74-37.82-3.82-75.23-10.11-113.31-11.35-36.63-1.19-86.21-3.22-111.72 28.47-20.1 25-14.92 64.27 6.34 88.28s54.75 34.28 86.82 34.31 63.39-9 94.15-18.07c28.67-8.46 63.14-22 93.25-17.2 32 5.12 64.19 16.63 95.8 24.42 77.87 19.19 139.43 25.12 219.85 12.74 104.41-16.09 204.68-21 310.52-21 85.67 0 168.84 20.38 253.37 24 11.41.49 22.8 1.22 34.2 1.81 72.61 3.72 166.8 13.93 222.48-43.93 12.62-13.11 21.33-31.19 19-49.24-1.81-13.81-8.97-25.18-19.16-34.53z" data-name="divider background"/>
            <g id="div_chart" data-name="div chart" ref={(e) => (div_chart= e)} >
                <path fill="none" stroke="#e60c88" strokeLinecap="round" strokeMiterlimit="10" d="M806.01 176.96h123.34"/>
                <path d="M940.13 203.28H796a2.93 2.93 0 01-2.93-2.92V17.23a2.93 2.93 0 012.93-2.92h94.26l52.79 53.49v132.56a2.92 2.92 0 01-2.92 2.92z" className="cls-3"/>
                <path d="M817 83.91h22a3.43 3.43 0 013.43 3.43V177h-28.85V87.35a3.43 3.43 0 013.42-3.44z" className="cls-4"/>
                <path d="M856.68 116h22a3.43 3.43 0 013.43 3.43V177h-28.86v-57.56a3.43 3.43 0 013.43-3.44z" className="cls-5"/>
                <path d="M896.35 97.44h22a3.43 3.43 0 013.43 3.43V177h-28.86v-76.13a3.43 3.43 0 013.43-3.43z" className="cls-6"/>
                <path d="M943.05 67.8h-50.3a2.54 2.54 0 01-2.54-2.53v-51z" className="cls-3"/>
                <path d="M853.88 38.44A19.7 19.7 0 00843.39 28l-8 17.85z" className="cls-4"/>
                <path d="M831 27.11a19.58 19.58 0 00-13.53 26.07l17.89-7.31z" className="cls-5"/>
                <path d="M843.39 28a19.5 19.5 0 00-7.58-1.52 19.24 19.24 0 00-4.77.61l4.36 18.76z" className="cls-6"/>
                <path fill="#2b7339" d="M817.51 53.18a19.6 19.6 0 0035.86 1.67l-18-9z"/>
                <path d="M853.88 38.44l-18.48 7.43 18 9a19.55 19.55 0 00.51-16.41z" className="cls-8"/>
            </g>
            <g id="div_secure_lock_cloud" ref={(e) => (div_secure_lock_cloud = e)} data-name="div secure lock cloud" >
                <path d="M191.25 116.59a24.88 24.88 0 00-24.89-24.88 25.37 25.37 0 00-2.9.17v-1.16a26.37 26.37 0 00-36.7-24.25A32.32 32.32 0 0067 83.5c0 1 0 2 .14 2.91a28 28 0 00-4.61-.41 27.73 27.73 0 00-.16 55.45h104a24.89 24.89 0 0024.88-24.86z" className="cls-3"/>
                <rect width="56.73" height="43.06" x="86.55" y="115.81" className="cls-5" rx="6.51"/>
                <path d="M102.76 98.54a11 11 0 0111-11h2.28a11 11 0 0111 11v17.27h9.28V98.54a20.33 20.33 0 00-20.3-20.3h-2.28a20.32 20.32 0 00-20.29 20.3v17.27h9.28z" className="cls-9"/>
                <rect width="4.64" height="15.99" x="112.83" y="128.19" fill="#2e667e" rx="2.32"/>
                <path d="M143.22 124.52h-9.35M143.22 130.99h-9.35M143.22 137.46h-9.35M143.22 143.93h-9.35M143.22 150.4h-9.35M95.95 124.52H86.6M95.95 130.99H86.6M95.95 137.46H86.6M95.95 143.93H86.6M95.95 150.4H86.6" className="cls-11"/>
            </g>
            <g id="div_white_pill" ref={(e) => (div_white_pill = e)} data-name="div white pill">
                <path d="M1373.25 88.68L1341.63 151a36.05 36.05 0 0031.62-62.29zM1349.46 79.71a36.05 36.05 0 00-21 65.36l32.26-63.56a35.78 35.78 0 00-11.26-1.8z" className="cls-3"/>
                <path fill="#fff" stroke="#b3b3b6" strokeMiterlimit="10" strokeWidth="1.5" d="M1360.71 81.51l-32.26 63.56a35.94 35.94 0 0013.18 5.9l31.62-62.29a35.79 35.79 0 00-12.54-7.17z"/>
            </g>
            <g id="div_stethoscope" ref={(e) => (div_stethoscope = e)} data-name="div stethoscope" >
                <path d="M669.22 6.62h-1.86a2.86 2.86 0 000 5.71h1.86a9.33 9.33 0 019.33 9.33v51.88a15.09 15.09 0 01-6.83 12.66l-43 28.23-43-28.23a15.1 15.1 0 01-6.84-12.66V21.66a9.34 9.34 0 019.33-9.33H590a2.86 2.86 0 100-5.71h-1.86a15.05 15.05 0 00-15 15v51.92A20.78 20.78 0 00582.52 91l43.19 28.34v56.37a23 23 0 0046 0v-10.58a2.86 2.86 0 00-5.71 0v10.55a17.31 17.31 0 01-34.61 0v-56.21L674.85 91a20.76 20.76 0 009.41-17.43V21.66a15 15 0 00-15.04-15.04z" className="cls-8"/>
                <circle cx="669.27" cy="146.09" r="19.04" className="cls-3"/>
                <circle cx="669.27" cy="146.09" r="8.72" className="cls-9"/>
                <ellipse cx="590.96" cy="10.07" className="cls-13" rx="6.81" ry="10.07"/>
                <ellipse cx="664.75" cy="10.07" className="cls-13" rx="6.81" ry="10.07"/>
            </g>
            <g id="div_syringe" ref={(e) => (div_syringe = e)} data-name="div syringe">
                <path d="M1206.82 156.33h27.11v25.41h-27.11a8.53 8.53 0 01-8.53-8.53v-8.35a8.53 8.53 0 018.53-8.53z" className="cls-14" transform="rotate(-145 1216.133 168.993)"/>
                <path fill="#47abc6" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5" d="M1181.956 171.742l-105.548-73.905h0l25.105-35.854h0l105.548 73.905a8.62 8.62 0 012.117 12.005l-15.217 21.733a8.62 8.62 0 01-12.005 2.116z"/>
                <path d="M1186.58 146.14l11.5-16.43M1172.81 136.51l11.51-16.44M1159.04 126.87l11.51-16.44M1145.27 117.23l11.51-16.44M1131.51 107.59l11.5-16.43M1117.74 97.95l11.51-16.43M1103.97 88.31l11.51-16.43" className="cls-16"/>
                <path d="M1052.2 56.73h40.35v23.34h-40.35z" className="cls-14" transform="rotate(-145 1072.385 68.364)"/>
                <path d="M1074.94 23.2a3.65 3.65 0 01.9 5.08L1036 85.16a3.65 3.65 0 01-6-4.18l39.82-56.88a3.65 3.65 0 015.12-.9z" className="cls-17"/>
                <path d="M1229.59 180.85l44 30.81 6.03-1.13-47.52-33.26-2.51 3.58z" className="cls-8"/>
                <path d="M1113.69 44.56a3.65 3.65 0 01.9 5.08l-49.14 70.19a3.65 3.65 0 01-6-4.18l49.14-70.19a3.65 3.65 0 015.1-.9z" className="cls-17"/>
            </g>
            <g id="div_laptop" ref={(e) => (div_laptop = e)} data-name="div laptop">
                <path fill="#cccccb" d="M269.44 164.39h238.12v8.85a11.07 11.07 0 01-11.07 11.07h-216a11.07 11.07 0 01-11.07-11.07v-8.85h.02z"/>
                <path d="M302.3 29.79h172.4a9.91 9.91 0 019.91 9.91v124.69H292.38V39.7a9.91 9.91 0 019.92-9.91z" className="cls-9"/>
                <rect width="161.52" height="107.24" x="307.52" y="43.36" fill="#fff" rx="2.86"/>
                <path d="M400.94 62.18h-24.59l-12.29 17.76 12.29 17.76h24.59l12.29-17.76-12.29-17.76z" className="cls-4 graphics"/>
                <path d="M400.94 97.7h-24.59l-12.29 17.76 12.29 17.76h24.59l12.29-17.76-12.29-17.76z" className="cls-4 graphics"/>
                <path d="M364.06 79.94h-24.58l-12.3 17.76 12.3 17.76h24.58l12.29-17.76-12.29-17.76zM437.81 79.94h-24.58L400.94 97.7l12.29 17.76h24.58L450.1 97.7l-12.29-17.76z" className="cls-6 graphics"/>
            </g>
</svg>

    )
}

export default Divider