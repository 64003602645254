export const Himss22_presentations = [
    {
        date:"Tuesday, March 15 -<br>Thursday, March 17",
        time:"",
        location:"Orange County Convention Center <br> Hall E, Booth 8240",
        title:"<span class='h4 font-weight-semi-bold '>Visit BD at the HIMSS22 Interoperability Showcase</span>",
        speakers:[{nameAndCreds:"", jobTitle:"", company:""}],
        showHideDescription:"BD is proud to participate in the HIMSS22 Interoperability Showcase with connectivity partners: Epic and Codonics. Stop by Hall E, Booth 8240 to see firsthand how BD technologies connect to support EMR interoperability and system integration to enhance data exchange and analytics. The results are improved medication safety and operational workflow efficiency as well as informed clinical care which helps protect both patients and those who care for them.",
        presentationComingSoon: false,
        registerLink:"",
        sessionModalID:""
    },
    {
        date:"Tuesday, March 15",
        time:"10:30 AM - 1:00 PM ET",
        location:"Rosen Plaza <br>Grand Ballroom C",
        title:"<span class='h4 font-weight-semi-bold'>NursePitch Innovation Workshop & Roundtable Luncheon </span>",
        speakers:[
            {nameAndCreds:"Rosemary Kuca, RN, MS", jobTitle:"Director of Ancillary IT Clinical Systems", company:"Catholic Health System"},
            {nameAndCreds:"Mary E. Durham, PharmD, BCPS", jobTitle:"Director of Pharmacy & Supply Chain", company:"University Health"},
            {nameAndCreds:'Donald "Chip" Gerhart, RPh', jobTitle:"Manager, Pharmacy Informatics", company:"WellSpan Health"}
        
        ],
        showHideDescription:"Join HIMSS and ANA for the NursePitch Innovation Workshop and Roundtable Luncheon at HIMSS Global Conference & Exhibition. These events provide expert insight and know-how for moving innovative technology from ideas to reality. This is an opportunity to inspire and educate entrepreneurial nursing technologists on how to further develop innovative ideas into marketable products.",
        note:"Sponsored by BD",
        presentationComingSoon:false,
        registerLink:"https://www.himss.org/global-conference/session-nursepitch-innovation-workshop-roundtable-luncheon",
        sessionModalID:"",
    },

    {
        date:"Tuesday, March 15",
        time:"1:30 - 2:30 PM ET",
        location:"Orange County Convention Center <br> Room 207C",
        title:"<span class='h4 font-weight-semi-bold'>Are you exposed?</span><br/> Evolving capital equipment strategies to reduce cybersecurity risks",
        speakers:[
            {nameAndCreds:"Rosemary Kuca, RN, MS", jobTitle:"Director of Ancillary IT Clinical Systems", company:"Catholic Health System"},
            {nameAndCreds:"Mary E. Durham, PharmD, BCPS", jobTitle:"Director of Pharmacy & Supply Chain", company:"University Health"},
            {nameAndCreds:"Moderator: Terrence Head", jobTitle:"Cybersecurity Officer, North America", company:"BD"}
        ],
        showHideDescription:"As healthcare confronts the impact of a multi-year pandemic, providers face another longstanding threat: cybersecurity risks. The incidence of ransomware attacks remains higher than pre-pandemic levels, with potential to lead to delayed patient care, exposure of sensitive health information and significant financial hardships for affected organizations.<br><br>Cybersecurity breaches within healthcare systems have been attributed to ineffective security protocols, inadequate training and obsolete technology. With increased staffing shortages, hardware deficiencies and competing priorities, hospital IT leaders find themselves searching for an ideal time to upgrade device software and hardware.<br><br>This session aims to change the narrative. Instead of asking “should I upgrade?”, leaders will question if they can afford to wait to upgrade. The session will cover the risks of using outdated technologies, how to plan for upgrades, budgeting tips and tricks and deployment lessons learned. Attendees will leave with a collection of knowledge and tools to help ease the pain of their health systems’ next capital equipment upgrade.",
        presentationComingSoon:false,
        sessionModalID:"are-you-exposed",
        registerLink:"",
    },

    {
        date:"Tuesday, March 15",
        time:"3:00 - 4:00 PM ET",
        location: "Orange County Convention Center<br>Room W303A",
        title:"<span class='h4 font-weight-semi-bold'>Artificial Unintelligence (AU): when data & analytics go wrong!</span>",
        speakers:[{nameAndCreds:"Craig Greszler, PharmD, MBA", jobTitle:"Senior Manager, Medical Affairs", company:"BD"}],
        showHideDescription:"This session aims to flip the script on traditional artificial intelligence (AI) discussions by exploring potential unforeseen hazards introduced through utilization of advanced technology. Audience members will embark on an immersive experience into the technical and humanistic causes of automation bias. The presentation will start with a brief didactic overview of error sources and possible control measures to mitigate risk. The audience will then be invited to participate in a real-life application of the presented material through a series of interactive case studies.",
        presentationComingSoon:false,
        sessionModalID:"",
        registerLink:"https://www.himss.org/global-conference/session-artificial-unintelligence-au-when-data-and-analytics-go-wrong"
    },

    {
        date:"Wednesday, March 16",
        time:"10:45 - 11:45 AM ET",
        location:"Orange County Convention Center<br>Hall E, Booth 8240 <br>Interoperability Showcase Education Theater",
        title:"<span class='h4 font-weight-semi-bold'>Don’t stop yet: practical applications of connected medication management</span>",
        speakers:[
            {nameAndCreds:"Dick Taylor, MD", jobTitle:"Chief Clinical Informatics Officer", company:"Washington University St. Louis and BJC Healthcare"},
            {nameAndCreds:"Kersten Weber Tatarelis, PharmD, BCPS AQ-ID, FASHP", jobTitle:"Vice President, Pharmacy Operations", company:"Advocate Aurora Health"},
            {nameAndCreds:"Megan Park, PharmD, MBA, DPLA", jobTitle:"System Director, Clinical Pharmacy Informatics", company:"Intermountain Healthcare"},
            {nameAndCreds:"Moderator: Doina Dumitru, PharmD, MBA, FASHP", jobTitle:"Sr. Director, Medical Affairs", company:"BD"}
        ],
        showHideDescription:"The complexity and risk of the medication management process involves over 50 steps to address the lifecycle of a medication from procurement, to storage, preparation and ultimately, delivery to the patient for administration. While some technologies such as the EMR have begun to address documentation challenges, further connectivity among medication management devices is still necessary to address existing patient safety risks and operational challenges to produce the highest quality controls. Maximizing these connected medication management systems with intra- and interoperability enable health systems to deliver patient care while leveraging data and analytics to drive operational excellence. This multi-health system panel will share their perspectives, guiding principles, and lessons learned on practical applications of connected medication management systems.",
        presentationComingSoon:false,
        sessionModalID:"practical-applications",
        registerLink:"",
    },

    {
        date:"Wednesday, March 16,",
        time:"3:15 - 3:35 PM ET",
        location:"Orange County Convention Center<br> Hall D, Booth 7143",
        title:"<span class='h4 font-weight-semi-bold'>Data analytics: homegrown vs. vendor developed</span>",
        speakers:[{nameAndCreds:"Mary E. Durham, PharmD, BCPS", jobTitle:"Director of Pharmacy & Supply Chain", company:"University Health"},],
        showHideDescription:"Data is the driving force in healthcare for more efficient care, better outcomes and patient centric care plans. Over the past decade, we have seen an exponential rise in the number of interconnected patient care devices and the ability of the EHR to compile/quantify this data. Data fragmentation is perhaps the leading cause of inefficient analytics as much data is overlooked because it is relegated into data silos. While most healthcare organizations have invested heavily in the use of big data tools, a lack of staff members to stitch together the data as well as constraints on the data analytics team prevent second level analysis from being a regular occurrence.<br><br>This discussion will be focused on the pros and cons of both internal data analytics as well as vendor supplied analytics tools.",
        presentationComingSoon:false,
        sessionModalID:"",
        registerLink:"",
    },

    {
        date:"Thursday, March 17",
        time:"2:30 - 3:30 PM ET",
        location: "Orange County Convention Center<br>Room W414A",
        title:"<span class='h4 font-weight-semi-bold'>Agile inventory management with prescriptive analytics</span>",
        speakers:[
            {nameAndCreds:"Samantha Bastow, PharmD, MBA", jobTitle:"Senior Manager, Medical Affairs", company:"BD"},
            {nameAndCreds:"Megan Park, PharmD, MBA, DPLA", jobTitle:"System Director, Clinical Pharmacy Informatics", company:"Intermountain Healthcare"}
        ],
        showHideDescription:"The pandemic has emphasized a known need for more adaptable supply chain management. This pressure often leads to increased expenses, workforce burnout and possible delays in patient care. To gain better insight into supply chain strategy, many hospitals have established business intelligence programs to evaluate historical data. While these descriptive analyses have demonstrated improved inventory optimization for some, there remains a need for a more proactive approach, which could further reduce expenses and the labor effort required to react to data insights. One approach has been to move from descriptive to predictive analytics. However, for health systems to derive the greatest value from their data, pursuing prescriptive analytics practices would provide recommended actions based on a calculated forecast. During this presentation, we will walk through the stages of data analytics, providing examples of each and recommended steps to progress along the analytics maturity curve.",
        presentationComingSoon:false,
        sessionModalID:"",
        registerLink:"https://www.himss.org/global-conference/session-agile-inventory-management-prescriptive-analytics"
    },

]


export const Ashp21_presentations = [
    {
        date:"",
        time:"",
        title:"<span class='h4 font-weight-semi-bold '>Virtual CE Midday Symposium</span> </br> Black Swan Events: The Role of Pharmacy Leadership and Technology in Disaster Preparedness",
        speakers:[
            {nameAndCreds:"Joel A. Hennenfent, PharmD., MBA, BCPS, FASHP", jobTitle:["Vice President Pharmacy and Professional Health Services<br>University Health Kansas City"], company:"Kansas City, Missouri"},
            {nameAndCreds:"Megan A. Corrigan, PharmD., M.P.H., BCPS", jobTitle:["Director of Pharmacy Services & Emergency Management<br>Advocate Good Samaritan Hospital"], company:"Downers Grove, Illinois"},
            {nameAndCreds:"Susan Marx Mashni, PharmD., BCPS", jobTitle:"SVP, Chief Pharmacy Officer<br>Mount Sinai Health System<br>Associate Professor<br>Icahn School of Medicine", company:"New York, New York"},
            {nameAndCreds:"Mark Sullivan, PharmD., MBA, BCPS, FASHP", jobTitle:"Associate Chief Pharmacy Officer<br>Vanderbilt University Medical Center", company:"Nashville, Tennessee"},
        ],
        note: "BD has provided an educational grant in support of this continuing education activity.",
        showHideDescription:"The current rise in Black Swan events, namely drug shortages, cyberattacks, civil unrest, natural disasters, and the COVID‐19 pandemic, has heightened the need for pharmacists to check their department’s readiness for these emergency situations. This educational activity will outline the role of the pharmacist in emergency preparedness and response. Resources and technologies that can be employed for emergency readiness and response will be reviewed. The experiences of pharmacists who led their departments during multiple disaster events.",
        presentationComingSoon:true,
        sessionModalID:"middaySymposium"
    },
    {
        date:"",
        time:"",
        title:"<span class='h4 font-weight-semi-bold '>Poster Session</span> <br> Initiation of a Discharge Pharmacy Within a Busy Urban Emergency Department: The First Year",
        speakers:[
            {nameAndCreds:"Samantha Bastow, PharmD, MBA,", jobTitle:["BD Medical Affairs"], company:""}
        ],
        note: "",
        showHideDescription:"",
        presentationComingSoon:true,
        sessionModalID:"dischargePharmacy"
    },   
    {
        date:"",
        time:"",
        title:"<span class='h4 font-weight-semi-bold '>Virtual Promotional Theater</span> <br> Journey Into the Unknown: How Pharmacy Leaders Are Addressing the Medication Inventory Management Challenge Across the Care Continuum",
        speakers:[
            {nameAndCreds:"David Webster, RPh, MSBA", jobTitle:["Director of Acute Care Pharmacy Operations <br/>Assistant Professor of Clinical Community and Preventive Medicine"], company:"University of Rochester Medical Center / Strong Memorial Hospital"},
            {nameAndCreds:"Andrea Magill, PharmD, MPA, MS.", jobTitle:["Sr. Pharmacy Manager, Medication Management Automation <br/> Asst. Residency Program Director"], company:"Mayo Clinic"},
            {nameAndCreds:"Michael Borgmann, MS", jobTitle:["Director of Pharmacy Technology"], company:"Avera Health System"},
            {nameAndCreds:"Ashley Dalton, PharmD., MHA ", jobTitle:"Associate Chief Pharmacy Officer", company:"UC San Diego Health System "},
            {nameAndCreds:"Moderated by W. Perry Flowers, RPh, MS", jobTitle:"Vice President, Medical Affairs", company:"BD"}
        ],
        note: "This Virtual Promotional Theater is conducted at the 2021 ASHP Midyear Clinical Meeting & Exhibition and is a promotional activity provided by BD and is not certified for continuing education credit. The content of the Promotional Theater and opinions expressed by presenters are those of the sponsor or presetners and not of the American Society of Health-System Pharmacists",
        showHideDescription:"Over the past several years, pharmacy leadership has been called upon to provide an increasing role in the care continuum. These areas include ambulatory care clinics, hospital at home, long term care, and behavioral health.  As pharmacy leaders seek to ensure medication safety and security in the acute care setting and across this care continuum, adequate management of the associated inventory is a critical factor for consideration.   This panel discussion includes pharmacy leaders who have adopted centralized medication management to solve many of these challenges, while tracking key performance indicators to measure their improvements.  The panelists will discuss how the application of technology to support pharmacy inventory management has allowed their teams to improve efficiencies and streamline workflows.",
        presentationComingSoon:true,
        sessionModalID:"promotionalTheater"
    },
    
    {
        date:"",
        time:"",
        title:"<span class='h4 font-weight-semi-bold '>Virtual CE Midday Symposium</span> <br> Surface Monitoring Recommendations for Hazardous Drugs: Does Health System Size or Location Matter?",
        speakers:[
            {nameAndCreds:"Patricia C. Kienle, M.P.A., BCSCP, FASHP", jobTitle:["Director, Accreditation and Medication Safety"], company:"Cardinal Health"},
            {nameAndCreds:"Andre D. Harvin, PharmD., M.S.", jobTitle:"Director of Pharmacy, Oncology", company:"Cone Health"},
            {nameAndCreds:"Clinton L. Meachum, CPhT, CSPT", jobTitle:"Compounding Regulatory Coordinator", company:"Cone Health"}
        ],
        note: "BD has provided an educational grant in support of this continuing education activity.",
        showHideDescription: "Surface contamination with hazardous drugs (HDs) is an ongoing concern that has been addressed by organizations in the form of published standards and best practice guidelines, some of which are under revision and are not currently enforceable. In this activity, the faculty reviews the status of these documents, as well as applicable recommendations from international guidelines. The action plan for routine monitoring of surfaces for HD contamination and post-spill analysis implemented by one health system for use in its affiliated cancer institutes located in urban and rural communities is also described.",
        presentationComingSoon:true,
        sessionModalID:"middaySymposiumCE"
    },
    ]

export const Himss21_presentations = [{
    date:"",
    time:"",
    title:"Analytics: the key to high performance pharmacy programs",
    speakers:[{nameAndCreds:"Joe Lassiter, PharmD", jobTitle:"CEO", company:"The Robertson Group (TRG)"}],
    showHideDescription:"Data derived and accumulated in hospital pharmacies carries potential for unlocking organizational value but requires a transition from metrics reporting to insightful and actionable analytics. Learn how quality pharmacy analytics correlate to high performing pharmacy programs.",
    presentationComingSoon:false,
    sessionModalID:"analyticsPharmacyProgramLassiter"
},
{
    date:"",
    time:"",
    title:"Connected medication management: insights from the BD/ASHP21 Assessment Model",
    speakers:[
      {nameAndCreds:"Stephen Wellman", jobTitle:"Vice President of Content Strategy", company:"ASHP Media"},
      {nameAndCreds:"Doina Dumitru, PharmD, MBA, FASHP", jobTitle:"Sr. Director, Medical Affairs", company:"BD"},
    ],
    showHideDescription:"BD and ASHP have partnered to explore data, trends and analytics for the evolution of connected medication management. This session will discuss research efforts to develop a model illustrating the connected medication management journey.",
    presentationComingSoon:false,
    sessionModalID:"connectedMedicationManagementWellman"
},
{
    date:"",
    time:"",
    title:"Redefining connectivity: medication management interoperability at Catholic Health System",
    speakers:[{nameAndCreds:"Rosemary Kuca, RN, MS", jobTitle:"Director of Ancillary IT Clinical Systems", company:"Catholic Health System"}],
    showHideDescription:"Technology continues to quickly surpass healthcare systems' ability to keep up with the latest software versions and server compatibilities. Learn about a five-hospital health system's journey to device interoperability across pharmacy and nursing practice while navigating EMR transitions.",
    presentationComingSoon:false,
    sessionModalID:"redefiningConnectivityKuca"
},
{
    date:"",
    time:"",
    title:"Social hour panel: strategies for partnering with medical device manufacturers to strengthen cybersecurity",
    speakers:[
      {nameAndCreds:"Rob Suárez", jobTitle:"Chief Information Security Officer", company:"BD"},
      {nameAndCreds:"Terrence Head", jobTitle:"Cybersecurity Officer", company:"North America, BD"},
      {nameAndCreds:"W. Glenn Hilburn, RN, MBA, CHCIO", jobTitle:"Vice President of Information Technology", company:"Grady Health System"},
      {nameAndCreds:"Rosemary Kuca, RN, MS", jobTitle:"Director of Ancillary IT Clinical Systems ", company:"Catholic Health System"},
    ],
    showHideDescription:"With new cybersecurity threats emerging daily, strong collaboration with manufacturers is essential to managing medical device security effectively and protecting patient safety and privacy. Attend this social hour event to hear from BD Executive Leadership and BD customers on how to protect your network and your patients, manage legacy environments, and apply emerging best practices for device cybersecurity management.",
    presentationComingSoon:false,
    sessionModalID:"socialHourPanelSuarez"
},
{
    date:"",
    time:"",
    title:"Scaling the data mountain: leveraging analytics to drive operational excellence",
    speakers:[{nameAndCreds: "Doina Dumitru, PharmD, MBA, FASHP", jobTitle: "Sr. Director Medical Affairs", company: "BD"}],
    showHideDescription:"Mounds of data and reports have proven to be cumbersome, overwhelming and not very useful--especially in clinical care settings. Join us to learn about strategies to make use of data and reporting with analytics and key performance indicators, addressing key metrics and priorities for your health system.",
    presentationComingSoon:false,
    sessionModalID:"dataMountainDumitru"
}
]