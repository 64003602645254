import React, { useState, useRef } from "react";
import Header from "../../components/UI/Header/Header";
import HeaderImg from "../../images/headers/resource-header.png";
import ButtonElement from "../../components/UI/Button/ButtonElement";
import medManagementPDF from '../../Documents/ASHP21/ResourceHub/BD_WP_connected_medication_management_October_2021.pdf'
import ButtonHref from "../../components/UI/Button/ButtonHref"
import {LeftArrow, RightArrow } from './ResourceHub.elements'
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Image1 from "../../images/archive/HIMSS22/ResourceHub/bd-med-infographic.png";
import Image2 from "../../images/archive/HIMSS22/ResourceHub/lady-infographic.png";
import Image3 from "../../images/archive/HIMSS22/ResourceHub/lady-machine.png";
import Image4 from "../../images/archive/HIMSS22/ResourceHub/whitepaper-icon.png";
import Image6 from "../../images/archive/HIMSS22/ResourceHub/video-icon.png";
import Image7 from "../../images/archive/HIMSS22/ResourceHub/media-icon.png";
import Image8 from "../../images/archive/HIMSS22/ResourceHub/media-01.png";
import Image9 from "../../images/archive/HIMSS22/ResourceHub/media-02.png";
import Image10 from "../../images/archive/HIMSS22/ResourceHub/media-03.png";
import Image12 from "../../images/archive/HIMSS22/ResourceHub/media-05.png";
import Image13 from "../../images/archive/HIMSS22/ResourceHub/medical-bldgs-landscape.png";
import { Modal } from "react-responsive-modal";
import "../../components/Modals/ModalsCSS/modal.css";
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import InfographicPrint1 from "../../Documents/archive/HIMSS22/1_Protecting-patients-from-medication-errors-requires-more-than-single-platform-EMRs_PRINT-compressed.pdf";
import InfographicDisplay1 from "../../images/archive/HIMSS22/ResourceHub/1_Protecting patients from medication errors requires more than single-platform EMRs-72DPI.png";
import InfographicPrint2 from "../../Documents/archive/HIMSS22/4_Leveraging-analytics-to-improve-connected-medication-management-process-efficiencies_PRINT-compressed.pdf";
import InfographicDisplay2 from "../../images/archive/HIMSS22/ResourceHub/4_Leveraging analytics to improve connected medication management process efficiencies-72DPI.png";
import InfographicPrint3 from "../../Documents/archive/HIMSS22/2_Breaking-down-the-barriers-to-connected-medication-management_PRINT-compressed.pdf";
import InfographicDisplay3 from "../../images/archive/HIMSS22/ResourceHub/2_Breaking down the barriers to connected medication management-72DPI.png";
import BeyondTheEHRPDF from "../../Documents/MMS_MM_Beyond-the-EHR_WP.pdf";
import KeepExploring from "../../components/UI/Button/KeepExploring";
import ReactGA from "react-ga";

ReactGA.initialize("UA-203934706-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const infographicArr = [
  {
    title:
      "Protecting patients from medication errors requires more than single-platform EMRs",
    display: InfographicDisplay1,
    download: InfographicPrint1,
  },
  {
    title:
      "Leveraging analytics to improve connected medication management process efficiencies",
    display: InfographicDisplay2,
    download: InfographicPrint2,
  },
  {
    title: "Breaking down the barriers to connected medication management",
    display: InfographicDisplay3,
    download: InfographicPrint3,
  },
];

const BDASHP = (props) => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [modalImageCount, setModalImageCount] = useState(0);

  const [openModal1, setOpenModal1] = useState(false);

  const onOpenModal1 = () => setOpenModal1(true);
  const onCloseModal1 = () => setOpenModal1(false);

  const [openModal2, setOpenModal2] = useState(false);

  const onOpenModal2 = () => setOpenModal2(true);
  const onCloseModal2 = () => setOpenModal2(false);

  const [openModal3, setOpenModal3] = useState(false);

  const onOpenModal3 = () => setOpenModal3(true);
  const onCloseModal3 = () => setOpenModal3(false);

  const infographicModal = useRef(null);

  const handleForwardPagination = () => {
    if (!(modalImageCount >= infographicArr.length - 1)) {
      setModalImageCount((prevCount) => prevCount + 1);
      scrollTop();
    }
  };

  const handleBackwardPagination = () => {
    if (modalImageCount != 0) {
      setModalImageCount((prevCount) => prevCount - 1);
      scrollTop();
    }
  };

  const scrollTop = () => {
    infographicModal.current.scrollTop = 0;
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        classNames={{
          modal: "customModal",
        }}
        focusTrapped={false}>
        {infographicArr
          .filter((img, index) => index === modalImageCount)
          .map((img, index) => {
            return (
              <div className="container" key={index}>
                <div className="row">
                  <div className="col-12">
                    <p
                      className="py-3 h5 font-weight-bold"
                      style={{ color: "#02519d" }}>
                      {img.title}
                    </p>
                    <div
                      style={{ overflowY: "scroll", height: "30rem" }}
                      ref={infographicModal}>
                      <a href={img.download} target="_blank">
                        <img
                          src={img.display}
                          alt={img.title}
                          className="img-fluid border"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row py-3 d-flex align-items-center">
                  <div className="col-md-4 col-6">
                    <p className="small">
                      Image {modalImageCount + 1} of {infographicArr.length}
                    </p>
                  </div>
                  <div className="col-md-4 col-6 text-md-center text-right">
                    <a
                      href={img.download}
                      target="_blank"
                      style={{ color: "#02519d" }}
                      className="font-weight-bold">
                      Download
                    </a>
                  </div>
                  <div className="col-md-4 col-12 text-md-right text-center">
                    <LeftArrow
                      onClick={handleBackwardPagination}
                      className={`${modalImageCount === 0 ? "disabled" : ""}`}
                    />
                    <RightArrow
                      onClick={handleForwardPagination}
                      className={`${
                        modalImageCount === infographicArr.length - 1
                          ? "disabled"
                          : ""
                      }`}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </Modal>

      <Modal
        open={openModal1}
        onClose={onCloseModal1}
        classNames={{
          modal: "customModal",
        }}
        center
        focusTrapped={false}>
        <Container style={{ overflow: "hidden" }} className="py-md-4 p-0">
          <Row>
            <Col sm={12} className="pb-4">
              <Fade right>
                <p
                  className="pb-2 font-weight-bold h5 px-2 px-md-0"
                  style={{ color: "#02519d" }}>
                  BD Connected Medication Management Solutions - BD/ASHP 2021
                </p>
              </Fade>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  src="https://player.vimeo.com/video/579210478?autoplay=1&color=ED621D&byline=0&portrait=0"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal>

      <Modal
        open={openModal2}
        onClose={onCloseModal2}
        classNames={{
          modal: "customModal",
        }}
        center
        focusTrapped={false}>
        <Container style={{ overflow: "hidden" }} className="py-md-4 p-0">
          <Row>
            <Col sm={12} className="pb-4">
              <Fade right>
                <p
                  className="pb-2 font-weight-bold h5 px-2 px-md-0"
                  style={{ color: "#02519d" }}>
                  Leveraging IT investments with BD Connected Medication
                  Management Solutions - BD/ASHP 2021
                </p>
              </Fade>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  src="https://player.vimeo.com/video/579210507?autoplay=1&color=ED621D&byline=0&portrait=0"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal>

      <Modal
        open={openModal3}
        onClose={onCloseModal3}
        classNames={{
          modal: "customModal",
        }}
        center
        focusTrapped={false}>
        <Container style={{ overflow: "hidden" }} className="py-md-4 p-0">
          <Row>
            <Col sm={12} className="pb-4">
              <Fade right>
                <p
                  className="pb-2 font-weight-bold h5 px-2 px-md-0"
                  style={{ color: "#02519d" }}>
                  UConn Health and the integrated Pyxis Medication Availability
                  Solution - BD/ASHP 2021
                </p>
              </Fade>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  src="https://player.vimeo.com/video/579210517?autoplay=1&color=ED621D&byline=0&portrait=0"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal>

      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-2 p-xl-0">
            <SideNavBar {...props} />
          </div>

          <div className="col-xl-10 bg-wild-sand subpage-content ">
            <Header
              image={HeaderImg}
              title="BD Resource Hub"
              description="Browse our resource library to learn more."
            />

         
              <div className="row px-md-5 pt-5">
                <div className="col-md-12 mt-3">
                  <Slide left>
                    <h2 className="font-weight-bold gray-title">
                      New views into medication management
                    </h2>
                  </Slide>
                  <div className="hr-line"></div>
                  <p className="lead py-3">
                    View infographics to explore how BD is reinventing medication
                    management across the care continuum.
                  </p>

                  <div>
                    <img
                      src={Image13}
                      className="img-fluid"
                      width={400}
                      onClick={onOpenModal}
                      style={{ cursor: "pointer" }}
                    />
                  </div>

                  <div className="mb-5 pt-4">
                    <ButtonElement onClick={onOpenModal}>
                      View infographic gallery
                    </ButtonElement>
                  </div>
                </div>

                {/* <div className="col-12 gray-title mb-5">
                  <Slide left>
                    <h2 className="font-weight-bold mt-4 gray-title">
                      Featured Session
                    </h2>
                  </Slide>
                  <div className="hr-line"></div>
                  <div className="row">
                    <div className="col-lg-10">
                      <h4 className="font-weight-bold mt-4">
                        Tuesday, August 10, 4:00 PM, Booth #6326 <br />
                        Connected medication management: insights from the BD/ASHP
                        Assessment Model
                      </h4>

                      <p className="lead mt-3 mb-4">
                        BD and ASHP have partnered to explore data, trends and
                        analytics for the evolution of connected medication
                        management. This session will discuss research efforts to
                        develop a model illustrating the connected medication
                        management journey.
                      </p>

                      <div className="mb-4">
                        <p className="h5 mb-0">Stephen Wellman</p>
                        <p>Vice President of Content Strategy</p>
                        <p>ASHP Media</p>
                      </div>

                      <div className="mt-2 mb-4">
                        <p className="h5 mb-0">
                          Doina Dumitru, Pharm D, MBA, FASHP
                        </p>
                        <p>Senior Director, Medical Affairs</p>
                        <p>BD</p>
                      </div>

                      <div className="mb-5">
                        <ButtonLink to="/education-theater#schedule">
                          View session schedule
                        </ButtonLink>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="row px-lg-5">
                <div className="col-md-2">
                  <Fade bottom>
                    <img src={Image4} className="img-fluid" width={215} alt="" />
                  </Fade>
                </div>

                <div className="col-12">
                  <div>
                    <h2 className="font-weight-bold mt-4">White paper</h2>

                    <p className="my-3">
                      Read these white papers to learn about the trajectory of
                      connected medication management adoption today.
                    </p>

                    <div className="hr-line"></div>

                    <h5 className="mt-4 mb-4 font-weight-bold gray-title">
                        Connected Medication Management: Getting Healthcare Organizations Across the Finish Line
                      </h5>

                      <div className="mb-5">
                      <ButtonHref href={medManagementPDF} target="_blank">
                        View white paper
                      </ButtonHref>
                      </div>

                    <h5 className="mt-4 mb-4 font-weight-bold gray-title">
                      Beyond the EHR: Best Practices in Enterprise Medication
                      Management
                    </h5>

                    <div className="mb-5">
                      <ButtonHref href={BeyondTheEHRPDF} target="_blank">
                        View white paper
                      </ButtonHref>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row px-lg-5 mt-5">
                <div className="col-md-2">
                  <Fade bottom>
                    <img src={Image6} className="img-fluid" alt="" />
                  </Fade>
                </div>
                <div className="col-12">
                  <h2 className="font-weight-bold mt-4 gray-title">Videos</h2>

                  <p className="lead mt-2 mb-3">
                    Tune in to see how a connected medication management system
                    can support your efforts across the care continuum.
                  </p>

                  <div className="hr-line mb-5"></div>
                </div>

                <div className="col-md-4 p-4">
                  <div
                    className="text-center gray-title"
                    onClick={onOpenModal1}
                    style={{ cursor: "pointer" }}>
                    <img className="img-fluid" src={Image1} />
                    <p className="font-weight-semi-bold mt-3">
                      BD Connected Medication{" "}
                      <span className="d-md-block font-weight-semi-bold">
                        Management Solutions
                      </span>
                    </p>
                  </div>
                </div>

                <div className="col-md-4 p-4">
                  <div
                    className="text-center gray-title"
                    onClick={onOpenModal2}
                    style={{ cursor: "pointer" }}>
                    <img className="img-fluid" src={Image2} />
                    <p className="font-weight-semi-bold mt-3">
                      Leveraging IT Investments with BD Connected{" "}
                      <span className="d-md-block font-weight-semi-bold">
                        Medication Management Solutions
                      </span>
                    </p>
                  </div>
                </div>

                <div className="col-md-4 p-4">
                  <div
                    className="text-center gray-title"
                    onClick={onOpenModal3}
                    style={{ cursor: "pointer" }}>
                    <img className="img-fluid" src={Image3} />
                    <p className="font-weight-semi-bold mt-3">
                      UConn Health and the Integrated{" "}
                      <span className="d-md-block font-weight-semi-bold">
                        Pyxis Medication Availability Solution
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="row px-lg-5">
                <div className="mt-5 pt-5 col-md-2">
                  <Fade bottom>
                    <img src={Image7} className="img-fluid" alt="" />
                  </Fade>
                </div>

                <div className="col-md-12">
                  <h2 className="font-weight-bold mt-4 gray-title">Media</h2>

                  <p className="lead mt-2 mb-3">
                    View articles in HealthcareITNews to learn more about the
                    future of connected medication management.
                  </p>

                  <div className="hr-line mb-5"></div>
                </div>
              </div>
              <div className="row px-lg-5 mb-5 text-center">
                <div className="col-lg-4 col-md-6 my-3">
                  <a
                    href="https://www.healthcareitnews.com/news/protecting-patients-medication-errors-requires-more-single-platform-emrs"
                    target="_blank"
                    className="gray-title font-weight-bold">
                    <div>
                      <img src={Image8} className="img-fluid" alt="" />
                      <p className="pt-3 text-center padding-text">
                        Protecting patients from medication errors requires more
                        than single-platform EMRs
                      </p>
                    </div>
                  </a>
                </div>

                <div className="col-lg-4 col-md-6 my-3">
                  <a
                    href="https://www.healthcareitnews.com/news/leveraging-analytics-improve-connected-medication-management-process-efficiencies"
                    target="_blank"
                    className="gray-title font-weight-bold">
                    <img src={Image9} className="img-fluid" alt="" />
                    <p className="pt-3 text-center padding-text">
                      Leveraging analytics to improve connected medication
                      management process efficiencies
                    </p>
                  </a>
                </div>

                <div className="col-lg-4 col-md-6 my-3">
                  <a
                    href="https://www.healthcareitnews.com/news/managing-medications-amidst-pandemic"
                    target="_blank"
                    className="gray-title font-weight-bold">
                    <img src={Image10} className="img-fluid" alt="" />
                    <p className="pt-3 text-center padding-text">
                      Managing medications amidst a pandemic
                    </p>
                  </a>
                </div>

                <div className="col-lg-4 col-md-6 my-3">
                  <a
                    href="https://www.healthcareitnews.com/news/connectivity-value-integrated-solutions-and-their-impact-patient-care"
                    target="_blank"
                    className="gray-title font-weight-bold">
                    <img src={Image12} className="img-fluid" alt="" />
                    <p className="pt-3 padding-text">
                      Connectivity: The value of integrated solutions and their
                      impact on patient care
                    </p>
                  </a>
                </div>
              </div>

              <div
                className="row px-lg-5 text-center"
                style={{ paddingBottom: "10rem" }}>
                {/* <div className="col-md-4 ">
                      <a href="https://www.healthcareitnews.com/news/protecting-patients-medication-errors-requires-more-out-box-emrs" target="_blank"
                        className="gray-title font-weight-bold">
                        <img src={Image11} className="img-fluid" alt="" />
                        <p className="pt-3 text-center">
                          Protecting patients from medication errors <span className="d-lg-block">requires more than out-of-the-box EMRs</span>
                      </p>
                      </a>
                    </div> */}

                <div className="col-12 text-center py-5 mt-5">
                  <KeepExploring />
                </div>
              </div>
          
          </div>
        </div>
      </div>
    </>
  );
};

export default BDASHP;
