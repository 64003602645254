import React, {useEffect, useRef} from 'react'
import { gsap } from "gsap"
import './PharmacyDoctor.css'

// const PharmacyDoctor = () => {

//     let top_doctorRef = null
//     let top_finger_outlineRef = null
//     let top_cross_outlineRef = null
//     let top_filesRef = null
//     let top_gearRef = null;
//     let doctorTimeLine = useRef(gsap.timeline({ paused: false }));

//     useEffect(() => {
//         gsap.set(top_doctorRef, {
//            y: -800,
//            scale: 2,
//           force3D: true,
//         });
//         gsap.set(top_filesRef.childNodes, {
//             x: -500,
//            force3D: true,
//          });


//          gsap.fromTo(top_finger_outlineRef, {x: -5}, {x: 0, duration: 1.3, ease: "sine.inOut", repeat: -1, yoyo: true})
//          gsap.fromTo(top_cross_outlineRef, {y: 5}, {y: 0, duration: 1.3, ease: "sine.inOut", repeat: -1, yoyo: true})
//          gsap.to(top_gearRef, {rotate: 360, transformOrigin: '50% 50%', duration: 20, repeat: -1, yoyo: true})
         

//         doctorTimeLine.current
//         .to(top_doctorRef, {duration: 1, y: 0, scale: 1, delay: .5, ease: "back.out(1.5)", force3D: true })
//         .to(top_filesRef.childNodes, { duration: 1, x: 0, ease: "elastic.out(1, 0.5)", stagger: -.5, force3D: true}, "-=30%")

//       }, []);

const PharmacyDoctor = () => {

    let top_doctorRef = null
    let top_finger_outlineRef = null
    let top_cross_outlineRef = null
    let top_filesRef = null
    let top_gearRef = null;
    let top_heart_background = null;
    let top_cart_outline = null;
    let top_pill_pack = null
    let doctorTimeLine = useRef(gsap.timeline({ delay: .4, force3D: true}));

    useEffect(() => {

         gsap.fromTo(top_finger_outlineRef, {x: -5}, {x: 0, duration: 1.3, ease: "sine.inOut", repeat: -1, yoyo: true})
         gsap.fromTo(top_cross_outlineRef, {y: 5}, {y: 0, duration: 1.3, ease: "sine.inOut", repeat: -1, yoyo: true})
         gsap.to(top_gearRef, {rotate: 360, transformOrigin: '50% 50%', duration: 20, repeat: -1, yoyo: true})
         

        doctorTimeLine.current
        .fromTo(top_doctorRef, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
        .fromTo(top_heart_background, {transformOrigin: 'center center', scale: 0, opacity: 0}, {scale: 1, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
        .fromTo(top_cross_outlineRef, {xPercent: -100, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
        .fromTo(top_filesRef.childNodes, {xPercent: -100, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, stagger: -.5, ease: "elastic.out(1, 0.5)"}, 0)
        .fromTo(top_gearRef, {xPercent: 100, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
        .fromTo(top_finger_outlineRef, {yPercent: -100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
        .fromTo(top_cart_outline, {xPercent: 100, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
        .fromTo(top_pill_pack , {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)


        // return () =>  {
        //     const killAnimation = doctorTimeLine.current
        //     doctorTimeLine.kill()
        //  }
      }, []);


      useEffect(() => {

        let tweenProperty = (element, prop, min, max, direction) => {
    
            let randomDur = gsap.utils.random(.5, 1, .05, true);
    
            gsap.to(element, {
                [prop]: gsap.utils.random(min, max),
                duration: randomDur(),
                ease: "Sine.easeInOut",
                onComplete: tweenProperty,
                onCompleteParams: [element, prop, min, max, direction * -1]
            });
        }
    
        gsap.utils.toArray('#top_heart_background').forEach(element => {
            tweenProperty(element, "x", 0, 10, 1)
            tweenProperty(element, "y", 10, 20, -1)
          });
      }, []);


    return(
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 718.7 497.17" className="pharmacy-doctor">
        <defs/>
        <path id="top_heart_background" fill="#d0ecf3" d="M266.13 98c42.4-52.75 127.22-52.75 169.63-15.63s42.41 111.34 0 185.56c-29.69 55.67-106 111.34-169.63 148.46-63.62-37.12-139.95-92.79-169.64-148.46-42.41-74.22-42.41-148.45 0-185.56S223.72 45.26 266.13 98z" data-name="top heart background"/>
        <g id="top_cart_outline" data-name="top cart outline">
            <path d="M486.78 354.95h86.05v14.58h-86.05z" className="cls-2"/>
            <path d="M487.79 235.46h16.62a1.81 1.81 0 001.81-1.8V217a1.8 1.8 0 011.8-1.81h29.12a1.81 1.81 0 011.8 1.81v16.62a1.81 1.81 0 001.8 1.8h16.63a1.81 1.81 0 011.8 1.8v29.12a1.8 1.8 0 01-1.8 1.8h-16.63a1.81 1.81 0 00-1.8 1.81v16.62a1.81 1.81 0 01-1.8 1.8H508a1.8 1.8 0 01-1.8-1.8V270a1.81 1.81 0 00-1.81-1.81h-16.6a1.8 1.8 0 01-1.8-1.8v-29.13a1.81 1.81 0 011.8-1.8z" className="cls-3"/>
            <path d="M394.59 190.4l45.22 126.89a7.28 7.28 0 006.86 4.84h150.09L589.08 354a7.29 7.29 0 005.38 8.8 7.74 7.74 0 001.71.2 7.3 7.3 0 007.09-5.58l9.36-38.81a7 7 0 00.85-2.07l14.95-63.41 23.81-98.69h58.43a7.3 7.3 0 000-14.59h-64.18a7.3 7.3 0 00-7.09 5.59l-8.5 35.25H401.46a7.29 7.29 0 00-6.87 9.74zm17.21 4.85h215.27l-12.79 54.24-14 58.06H451.82z" className="cls-2"/>
            <circle cx="596.17" cy="363.7" r="31.67" className="cls-4"/>
            <circle cx="467.09" cy="363.7" r="31.67" className="cls-4"/>
        </g>
        <g id="top_finger_outline" data-name="top finger outline" ref={(e) => (top_finger_outlineRef = e)}>
            <circle cx="574.46" cy="115.76" r="52.67" className="cls-5"/>
            <circle cx="574.46" cy="115.76" r="38.09" className="cls-5"/>
            <circle cx="574.46" cy="115.76" r="23.28" className="cls-5"/>
            <path d="M447.18 64.07c.1.15.19.3.3.44l.16.2c.57.8 1.16 1.58 1.79 2.33l20.65 26.77a10.1 10.1 0 0014.12 1.93 10.12 10.12 0 003.05-12.23l6.56 8.49a10.1 10.1 0 0014.11 2 10.1 10.1 0 003.42-11.3l6.2 8a10.09 10.09 0 0014.11 1.92 10.09 10.09 0 002.7-12.91l31.74 41.75a10.09 10.09 0 0014.11 1.92 10.1 10.1 0 001.93-14.11l-54.41-71.59 29.36 11.54a10.1 10.1 0 0013.06-5.69 10.09 10.09 0 00-5.69-13.06L497.37 4.11l-.19-.06a40.56 40.56 0 00-50 60z" className="cls-5"/>
        </g>
        <g id="top_doctor" data-name="top doctor" ref={(e) => (top_doctorRef = e)}>
            <path fill="#f68622" stroke="#d35327" strokeMiterlimit="10" d="M343 73.69s-13.1-36.39-57.29-27.26-47.71 56.63-53.85 71.72c-14.05 34.73-61.17 48.65-51.38 96.06 9.68 46.87 61.71 56.79 61.71 56.79s23.19-32.44 33.46-36.79 21.71-9.79 21.71-9.79 24.1-3.15 32.42-2.08S379 253.52 379 253.52s49.22-8.76 44.49-56.54c-2.81-28.31-25.82-29-30.63-52.32s6-42.57-11.32-60.21A39.37 39.37 0 00343 73.69z"/>
            <path d="M410.73 338.29s-83.14 26.8-233-1.64c0 0 8.23-123.92 127.44-121.42 104.47 2.19 105.56 123.06 105.56 123.06z" className="cls-7"/>
            <ellipse cx="257.18" cy="316.61" className="cls-8" rx="5.88" ry="9.98" transform="rotate(-35.12 257.193 316.61)"/>
            <ellipse cx="210.38" cy="327.96" className="cls-8" rx="9.98" ry="5.88" transform="rotate(-84.12 210.37 327.954)"/>
            <path d="M341.87 230.77c-.72-.34-1.45-.65-2.18-1-.17 1.72-.37 3.43-.54 5.14l.64.29c17 8 35.82 26.39 39.23 66.83l4.91-.41c-3.61-42.76-23.87-62.35-42.06-70.85zM261.19 239.94c1.16-.82 2.41-1.6 3.72-2.36-.24-.25-.49-.49-.72-.74a38.81 38.81 0 00-2.92-2.82c-1 .61-2 1.24-2.91 1.89-23 16.12-28.13 37.58-29.21 44.14-16.26 2.42-22.62 13.23-25 19.64-4 10.79-2 22.09 2.13 26.36l3.55-3.41c-2.68-2.79-4.38-12.22-1.06-21.26 2.5-6.81 8.48-15.24 22.78-16.7 7.38-.75 13.22.87 17.33 4.83 8.15 7.85 7.32 22.5 7.31 22.64l4.91.33c.05-.7 1-17-8.79-26.5-4.56-4.41-10.63-6.55-18.08-6.39 1.48-7.04 7.07-25.68 26.96-39.65z" className="cls-9"/>
            <path d="M329.78 348.69s20.24-91.89-4.92-136.2V202.1s23 16.41 29.53 28.44l-9.84 18.05 15.31 21.33s-12.03 43.76-30.08 78.77zM283.29 197.18s-23 10.39-32.82 35c0 0 9.3 12 15.87 17.5l-8.21 23s29.54 37.19 34.46 75.48c0 0 2.19-91.89-10.94-127.44z" className="cls-7"/>
            <path d="M325.68 192.53s3.74 25.53 4 32.64-10.19 16.92-21.4 16.92-35.66-5.53-26.09-47.64c2.18-9.6 4.92-13.68 4.92-13.68a27.77 27.77 0 0017.23 11.76 46.18 46.18 0 0021.34 0z" className="cls-10"/>
            <path d="M275.09 137c-6.47 27.95 7 54.94 30 60.27s46.94-13 53.41-41-6.5-65.53-29.58-70.86-47.36 23.65-53.83 51.59z" className="cls-10"/>
            <ellipse cx="269.26" cy="143.22" className="cls-10" rx="12.17" ry="15.86" transform="rotate(-19.18 269.228 143.194)"/>
            <path d="M329.15 84s31.34 34.72 30.9 54.27c0 0 12.1-18.11 3.69-33.14s-29.85-27.76-29.85-27.76z" className="cls-11"/>
            <path d="M331.82 82.08s.18 8.92-15.74 20.53c-16.38 12-37.22 17-37.22 17s7.94-21.29 18.83-28.26 24.07-11.56 34.13-9.27z" className="cls-11"/>
            <circle cx="381.88" cy="312.72" r="13.54" className="cls-2"/>
            <circle cx="381.88" cy="312.72" r="6.03" fill="#b3b3b6"/>
        </g>
        <path id="top_gear"  ref={(e) => (top_gearRef = e)} fill="#47abc6" d="M596.17 316.18a47.59 47.59 0 11-47.59 47.59 47.6 47.6 0 0147.59-47.59zm17.36-56a105.42 105.42 0 00-34.73 0l-4.51 21.49a84.89 84.89 0 00-28.69 13.81l-19.6-9.89a105.18 105.18 0 00-21.65 27.16l14 16.92a85 85 0 00-7.08 31.05l-19.94 9.17a105 105 0 007.67 33.86l21.94-.39a84.87 84.87 0 0019.86 24.9l-5.26 21.32a105.1 105.1 0 0031.29 15.06l13.38-17.4a84.87 84.87 0 0031.85 0l13.38 17.4a105 105 0 0031.29-15.06l-5.26-21.32a84.87 84.87 0 0019.86-24.9l21.95.39a105.23 105.23 0 007.72-33.86l-19.95-9.17a84.81 84.81 0 00-7.05-31.05l14-16.92a105.18 105.18 0 00-21.65-27.16l-19.61 9.87A84.89 84.89 0 00618 281.65z" data-name="top gear"/>
        <g id="top_pill_pack" data-name="top pill pack" ref={(e) => (top_pill_pack = e)}>
            <rect width="212.91" height="125.24" x="210.91" y="371.18" fill="#b7ce56" stroke="#55a145" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5" rx="24.81"/>
            <circle cx="250.57" cy="411.54" r="17.51" className="cls-15"/>
            <circle cx="250.57" cy="458.15" r="17.51" className="cls-15"/>
            <circle cx="295.1" cy="411.54" r="17.51" className="cls-15"/>
            <circle cx="295.1" cy="458.15" r="17.51" className="cls-15"/>
            <circle cx="339.63" cy="411.54" r="17.51" className="cls-15"/>
            <circle cx="339.63" cy="458.15" r="17.51" className="cls-15"/>
            <circle cx="384.16" cy="411.54" r="17.51" className="cls-15"/>
            <circle cx="384.16" cy="458.15" r="17.51" className="cls-15"/>
        </g>
        <g id="top_files" ref={(e) => (top_filesRef = e)} data-name="top files">
            <rect width="96.54" height="122.06" x="90.54" y="289.28" className="cls-16 card" rx="3.87"/>
            <rect width="96.54" height="122.06" x="66.03" y="262.38" className="cls-16 card" rx="3.87"/>
            <g className="card">
                <rect width="96.54" height="122.06" x="41.52" y="235.48" className="cls-16" rx="3.87"/>
                <path fill="#fff" d="M89.79 276.39c7.92-9.86 23.77-9.86 31.7-2.92s7.92 20.8 0 34.67c-5.55 10.41-19.82 20.81-31.7 27.74-11.89-6.88-26.15-17.33-31.7-27.74-7.93-13.87-7.93-27.74 0-34.67s23.77-6.94 31.7 2.92z"/>
                <path d="M107.59 292.6h-8.51a.92.92 0 01-.92-.92v-8.51a.92.92 0 00-.92-.92h-14.9a.92.92 0 00-.92.92v8.51a.93.93 0 01-.93.92H72a.93.93 0 00-.93.92v14.9a.93.93 0 00.93.92h8.5a.93.93 0 01.93.93v8.5a.93.93 0 00.92.93h14.9a.93.93 0 00.92-.93v-8.5a.93.93 0 01.92-.93h8.51a.92.92 0 00.92-.92v-14.9a.92.92 0 00-.93-.92z" className="cls-3"/>
            </g>
        </g>
        <path id="top_cross_outline" ref={(e) => (top_cross_outlineRef = e)}  d="M4.23 85.23h34.34a3.72 3.72 0 003.72-3.72V47.16A3.73 3.73 0 0146 43.44h60.16a3.72 3.72 0 013.72 3.72v34.35a3.73 3.73 0 003.73 3.72H148a3.73 3.73 0 013.7 3.77v60.15a3.73 3.73 0 01-3.73 3.73h-34.34a3.73 3.73 0 00-3.73 3.73v34.34a3.72 3.72 0 01-3.72 3.73H46a3.73 3.73 0 01-3.73-3.73v-34.38a3.72 3.72 0 00-3.72-3.73H4.23a3.73 3.73 0 01-3.73-3.73V89a3.73 3.73 0 013.73-3.77z" className="cls-5" data-name="top cross outline"/>
        </svg>

    )
}

export default PharmacyDoctor