import React from "react";
import { Modal } from "react-responsive-modal";
import bicyclist from "../../../images/donationTracker/biker.png";

const LearnMore = ({modalId, closeModal}) => {
    return (
        <Modal
        open={modalId === "learnMore"}
        onClose={closeModal}
        center focusTrapped={false}
        classNames={{
          modal: "customModal max-width-27",
        }}
      >

        <div className="container" name="learnMore">
            <div className="row">
                <div className="col-12">
                    <h4 className="font-weight-bold" style={{ color: "#0557b6" }}>
                    Find the bicyclists, <br /> increase the donations!
                    </h4>
                </div>
                </div>
                <div className="row align-items-center">
                <div className="col-md-2 px-0">
                    <img src={bicyclist} alt="" className="img-fluid" />
                </div>
                <div className="col-md-10 pt-1">
                    <p>
                    By clicking on the bicyclists, your engagement will help
                    increase our overall donation to support community health
                    centers.
                    </p>
                </div>
                </div>
                <div className="row pt-2">
                <div className="col-12">
                    Once you have found as many bikes as you can, click the "Finish
                    Experience" button under the tracker.
                </div>
            </div>
        </div>

      </Modal>

    )
}

export default LearnMore