export const CityResourceData = {
  bubbleName: "resource",
  title: "BD Resource Hub",
  text: 'Explore key learnings.',
  backgroundDPath:
    "M1095 906.2h250.88V799.06c0-9.51-10-17.22-22.35-17.22H1095c-12.35 0-22.36 7.71-22.36 17.22V889c-.02 9.49 9.99 17.2 22.36 17.2Z",
  backgroundTransform: "translate(1.94 1.94)",
  textTransform: "translate(1000 840)",
  titleTransform: "translate(1095 880)",
  transformOrigin: "bottom right",
  startingYPosition: "2rem",
  startingScaleX: 1,
  startingScaleY: 0.6,
  hoverTitleXPosition: "1000",
  hoverTitleYPosition: "790",
  hoverTextXPosition: "1000",
  hoverTextYPosition: "840",
  coordinates: "-100 -800 2800 5000",
  path: "/resource-hub",
};

export const CitySolutionsData = {
  bubbleName: "solutions",
  title: "Solutions Marketplace",
  text: 'Explore connected solutions to <tspan x="0" y="22">  help you achieve consistent  </tspan><tspan x="0" y="44"> medication management across  </tspan><tspan x="0" y="65"> your health system.</tspan>',
  backgroundDPath:
    "M2198.69 980h-303.35V826.74a27.87 27.87 0 0 1 27.87-27.87h275.48a27.87 27.87 0 0 1 27.87 27.87v125.42a27.87 27.87 0 0 1-27.87 27.84Z",
  backgroundTransform: "translate(1.94 1.94)",
  textTransform: "translate(1920 835)",
  titleTransform: "translate(1917 945)",
  transformOrigin: "bottom left",
  startingYPosition: "2rem",
  startingScaleX: 1,
  startingScaleY: 0.5,
  hoverTitleXPosition: "1920",
  hoverTitleYPosition: "790",
  hoverTextXPosition: "1920",
  hoverTextYPosition: "835",
  coordinates: "700 -800 2800 5000",
  path: "/solutions-marketplace",
};

export const CityCommunityData = {
  bubbleName: "community",
  title: 'Support Our Community <tspan x="0" y="30">Health Initiative</tspan>',
  text: 'Learn how you can help support <tspan letter-spacing=".01em"><tspan x="0" y="22">community health centers with </tspan><tspan x="0" y="44">international aid organization, </tspan></tspan><tspan letter-spacing="-.01em"><tspan x="0" y="65">Direct Relief, in partnership with BD.</tspan></tspan>',
  backgroundDPath:
    "M2192.75 1214.36h312.83V1039.8a28 28 0 0 0-27.88-28h-284.95a28 28 0 0 0-27.87 28v146.51a28 28 0 0 0 27.87 28.05Z",
  backgroundTransform: "translate(0 -40)",
  textTransform: "translate(2070 1030)",
  titleTransform: "translate(2150 1125)",
  transformOrigin: "bottom right",
  startingYPosition: "0rem",
  startingScaleX: 1.1,
  startingScaleY: 0.45,
  hoverTitleXPosition: "2070",
  hoverTitleYPosition: "950",
  hoverTextXPosition: "2070",
  hoverTextYPosition: "1030",
  coordinates: "800 -400 2800 5000",
  path: "/community",
};



export const CityEducationTheaterData = {
  bubbleName: "theater",
  title: "Education Center",
  text: 'Learn from your peers&apos; perspectives <tspan x="0" y="23">on achieving a connected medication</tspan><tspan x="0" y="45">management system.</tspan>',
  backgroundDPath:
    "M2347.7 1496.06h-322.85V1342.2c0-13.65 12.87-24.73 28.76-24.73h294.09c15.89 0 28.77 11.08 28.77 24.73v129.14c0 13.66-12.88 24.72-28.77 24.72Z",
  backgroundTransform: "translate(0 0)",
  textTransform: "translate(2050 1365)",
  titleTransform: "translate(2045 1467)",
  transformOrigin: "bottom left",
  startingYPosition: "0rem",
  startingScaleX: 0.8,
  startingScaleY: 0.45,
  hoverTitleXPosition: "2050",
  hoverTitleYPosition: "1315",
  hoverTextXPosition: "2050",
  hoverTextYPosition: "1365",
  coordinates: "700 -200 2800 5000",
  path: "/education-center",
};


export const CityPostOfficeData = {
  bubbleName: "post-office",
  title: "Connect with us",
  text: 'Let&apos;s discuss your unique challenges!<tspan x="0" y="22">Click here to connect and learn more </tspan><tspan x="0" y="44">about our solutions.</tspan>',
  backgroundDPath:
    "M1170.11 1247.92h341.69v-144.34c0-12.81-13.63-23.2-30.44-23.2h-311.25c-16.81 0-30.44 10.39-30.44 23.2v121.14c0 12.81 13.63 23.2 30.44 23.2Z",
  backgroundTransform: "translate(0 0)",
  textTransform: "translate(1035 1130)",
  titleTransform: "translate(1255 1215)",
  transformOrigin: "bottom bottom",
  startingYPosition: "0rem",
  startingScaleX: 0.75,
  startingScaleY: 0.5,
  hoverTitleXPosition: "1035",
  hoverTitleYPosition: "1080",
  hoverTextXPosition: "1035",
  hoverTextYPosition: "1130",
  coordinates: "250 -400 2800 5000",
  path: "/connect-with-us",
};
