import React from "react";
import SessionPresentationCard from "./SessionPresentationCard";


const SessionPresentationCards = ({openModalHandler, presentations}) => {
    return (
        <>
        {
            presentations.map((presentation, index) => {
                let isOdd = false;
                (index + 1) % 2 == 0 ? isOdd = false : isOdd = true;
                return (
                    <SessionPresentationCard 
                        key={index}
                        date={presentation.date}
                        time={presentation.time}
                        location={presentation.location}
                        title={presentation.title}
                        speakers={presentation.speakers}
                        showHideDescription={presentation.showHideDescription}
                        presentationComingSoon={presentation.presentationComingSoon}
                        sessionModalID={presentation.sessionModalID}
                        openModalHandler={openModalHandler}
                        registerLink={presentation.registerLink}
                        isOdd={isOdd}
                        note={presentation.note}
                    />
                )
            })
        }
        </>
        
    )
}

export default SessionPresentationCards


