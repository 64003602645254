import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import {
  BorderTopBorderBottom,
  BorderBottom,
  Title,
  OrangeLink,
  Text,
} from "../ModalElements/Modal.elements";
import ReactGA from "react-ga";

ReactGA.initialize("UA-203934706-1");
ReactGA.pageview(window.location.pathname + window.location.search);

export const SignModalOne = ({
  title,
  img,
  alt,
  topLine,
  statementNumber,
  middleLine,
  bottomLine,
  BottomDescription,
  linkText,
  link,
  citations,
}) => {
  const listCitations = citations.map((citation) => (
    <li key={Math.random()}>
      <Text>{ReactHtmlParser(citation)}</Text>
    </li>
  ));
  // Did you know medical errors are the third leading cause of death in the US?1
  return (
    <Container style={{ overflow: "hidden" }}>
      <Fade down>
        <Row>
          <Col sm={12}>
            <Title className="pr-md-5 pb-3 pt-3 font-weight-bold">
              {ReactHtmlParser(title)}
            </Title>
          </Col>
        </Row>
      </Fade>
      <Row>
        <Col md={5} sm={4} className="text-center d-flex align-items-center">
          <Fade left>
            <img src={img} alt={alt} className="img-fluid" />
          </Fade>
        </Col>
        <Col md={7} sm={8}>
          <Fade right>
            <Text className="font-weight-semi-bold pb-3">{topLine}</Text>
            <Text
              className="display-1 font-weight-semi-bold"
              style={{ lineHeight: ".7" }}>
              {statementNumber}
            </Text>
            <BorderTopBorderBottom className="w-75">
              <Text className="font-weight-semi-bold pt-1">
                {ReactHtmlParser(middleLine)}
              </Text>
            </BorderTopBorderBottom>
            <Text className="display-5 font-weight-bold d-inline-block">
              {ReactHtmlParser(bottomLine)}
              <sup className="h6" style={{ top: "-1.5em", left: "-.2em" }}>
                3
              </sup>
            </Text>
          </Fade>
        </Col>
      </Row>
      <Slide top>
        <Row>
          <Col sm={12} className="pt-5">
            <Text>
              <OrangeLink to={link}>{linkText}</OrangeLink>
              {BottomDescription}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="pt-4 pb-2">
            <ul className="small pl-2">{listCitations}</ul>
          </Col>
        </Row>
      </Slide>
    </Container>
  );
};

export const SignModalTwo = ({
  title,
  img,
  alt,
  topLine,
  secondLine,
  statementNumber,
  bottomLine,
  BottomDescription,
  linkText,
  link,
  citations,
}) => {
  const listCitations = citations.map((citation) => (
    <li key={Math.random()}>
      <Text>{ReactHtmlParser(citation)}</Text>
    </li>
  ));
  //38% of a nurse's time
  return (
    <Container style={{ overflow: "hidden" }} className="pt-3">
      <Fade down>
        <Row>
          <Col sm={12}>
            <Title className="font-weight-bold pb-3">
              {ReactHtmlParser(title)}
            </Title>
          </Col>
        </Row>
      </Fade>
      <Row>
        <Col sm={6} className="d-flex align-items-center">
          <Fade up>
            <img src={img} alt={alt} className="img-fluid" />
          </Fade>
        </Col>
        <Col sm={6}>
          <Fade down>
            <Text className="h4 font-weight-semi-bold mb-0">{topLine}</Text>
            <Text className="pl-md-5 pb-3">{secondLine}</Text>
            <BorderBottom>
              <Text
                className="font-weight-semi-bold"
                style={{ lineHeight: ".7", fontSize: "7rem" }}>
                {statementNumber}{" "}
              </Text>
            </BorderBottom>
            <Text
              className="font-weight-semi-bold mt-1"
              style={{ fontSize: "1.19rem" }}>
              {ReactHtmlParser(bottomLine)}
            </Text>
          </Fade>
        </Col>
      </Row>
      <Fade up>
        <Row>
          <Col sm={12} className="mt-3 mb-4">
            <Text>
              {BottomDescription}
              <OrangeLink to={link}>{linkText}</OrangeLink>
            </Text>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ul className="small pl-2">{listCitations}</ul>
          </Col>
        </Row>
      </Fade>
    </Container>
  );
};

export const SignModalThree = ({
  title,
  img,
  alt,
  topLine,
  statementNumber,
  statementBottomLine,
  bottomLine,
  BottomDescription,
  linkText,
  link,
  citations,
}) => {
  const listCitations = citations.map((citation) => (
    <li key={Math.random()}>
      <Text>{ReactHtmlParser(citation)}</Text>
    </li>
  ));
  // 165 RN salaries
  return (
    <Container style={{ overflow: "hidden" }}>
      <Slide top>
        <Row>
          <Col sm={12}>
            <Title className="pr-4 font-weight-bold pb-3">
              {ReactHtmlParser(title)}
            </Title>
          </Col>
        </Row>
      </Slide>
      <Fade left>
        <Row>
          <Col md={7} sm={8} className="arrow-left">
            <Text
              className="font-weight-semi-bold"
              style={{ fontSize: "1.9rem" }}>
              {topLine}
            </Text>
            <BorderTopBorderBottom>
              <Text
                className="display-3 font-weight-semi-bold"
                style={{ lineHeight: "1", paddingTop: ".8rem" }}>
                {statementNumber}
              </Text>
            </BorderTopBorderBottom>
            <Text
              className="font-weight-semi-bold mb-0"
              style={{ fontSize: "1.1rem" }}>
              {ReactHtmlParser(statementBottomLine)}
            </Text>
            <Text className="font-weight-semi-bold h3">
              {ReactHtmlParser(bottomLine)}
            </Text>
          </Col>
          <Col md={5} sm={4} className="d-flex align-items-center">
            <img src={img} alt={alt} className="img-fluid" />
          </Col>
        </Row>
      </Fade>
      <Fade bottom>
        <Row>
          <Col sm={12} className="py-4">
            <Text>
              {BottomDescription}
              <OrangeLink to={link}>{linkText}</OrangeLink>
            </Text>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ul className="small pl-2">{listCitations}</ul>
          </Col>
        </Row>
      </Fade>
    </Container>
  );
};