import styled from "styled-components";
import { Link } from "react-router-dom";

export const BorderTopBorderBottom = styled.div`
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  display: inline-block;
  font-size: 1.1rem;
`;
export const BorderBottom = styled.div`
  border-bottom: 2px solid #000;
  display: inline-block;
  font-size: 1.1rem;
`;

export const Title = styled.h4`
  font-weight: bold;
  color: #02519d;
`;

export const Text = styled.p`
  color: #4b4b4b;
`;

export const OrangeLink = styled(Link)`
  color: #f27707;
  font-weight: bold;
  transition: color 300ms;

  &:hover {
    color: #e34c00;
  }
`;