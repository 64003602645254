import React, { useState } from "react";
import Header from "../../../components/UI/Header/Header";
import HeaderImg from "../../../images/headers/education-center-header01.jpg";
import Image14 from "../../../images/archive/HIMSS22/EducationArchive/theater-popcorn@2x.png";
import SideNavBar from "../../../components/SideNavBar/SideNavBar";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Fade";
import KeepExploring from "../../../components/UI/Button/KeepExploring";
import "../../../components/Modals/ModalsCSS/modal.css";
import ReactGA from "react-ga";
import LinkedInTag from "react-linkedin-insight";
import PresentationModals from '../../components/PresentationModals/PresentationModals'
import { cards_Ashp21, cards_Himss21 } from "../../components/VideoCards/videoData";
import { Himss21_presentations, Ashp21_presentations} from "../../components/SessionPresentationCards/sessionPresentationData";
import VideoModals from '../../components/VideoModals/VideoModals'
import SessionPresentationCards from '../../components/SessionPresentationCards/SessionPresentationCards'
import VideoCards from '../../components/VideoCards/VideoCards'
import ThankYouModals from '../../components/VideoModals/ThankYouModals/ThankYouModals'
import AccessRequests from '../../components/VideoModals/AccessRequests/AccessRequests'

LinkedInTag.init("807690");

ReactGA.initialize("UA-203934706-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const EducationArchive = (props) => {

  // When the user clicks on "submit now in Request modal, it sets userSubmittedInfo to true (in App.js) and closes the modal"
  const onUserSubmission = (linkedinID, ThankyouModalID) => {
    
    props.handleUserAccess();
    onCloseModal();
    onOpenModal(ThankyouModalID);
    LinkedInTag.track(linkedinID);
    setDisable(true)
  };


  const [modalState, setModalState] = useState(null)
  const onOpenModal = (id) => {setModalState(id)};
  const onCloseModal = () => setModalState(null);

  const [setDisable] = useState(false);
  // Previous code
  // const [disable, setDisable] = useState(false);



  return (
    <>

      <PresentationModals 
        modalId={modalState} 
        closeModal={onCloseModal} 
      />

      <AccessRequests 
        modalId={modalState} 
        closeModal={onCloseModal}  
        hasAccess = {props.gainVideoAccess} 
        baseUrl="//go.bd.com" 
        onUserSubmit={onUserSubmission} 
      />

      <VideoModals 
        modalId={modalState} 
        closeModal={onCloseModal}  
      />

      <ThankYouModals 
        modalId={modalState}  
        closeModal={onCloseModal}  
      />
      
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-2 p-xl-0">
            <SideNavBar {...props} />
          </div>

          <div className="col-xl-10 bg-wild-sand subpage-content">
            <Header
              image={HeaderImg}
              title="Welcome to the Virtual Education Center Archive"
              description="Explore our featured on-demand educational videos and session presentations from ASHP21 and HIMSS21."
            />

      
                {/* <div className="col-md-8 offset-md-2">
                <div className="text-center mt-4 pt-2">
                  <h3 className= "grey-title">
                    If you are attending ASHP21 in person, please visit <span className="font-weight-bold" style={{color:'#02519d', textDecoration:'underline'}}>BD booth 6326</span> to attend one of our education sessions.
                  </h3>

                  <div className="mb-5 pt-4">
                    <ButtonLink to="/education-theater#ondemand">View on-demand sessions now</ButtonLink>
                  </div>
                  </div>
                </div> */}

                <div className="row px-lg-5 pt-5">
                  <div className="col-12">
                    <Slide left>
                      <h2 className="font-weight-bold grey-title mb-0">
                        ASHP21 Session Presentations:
                      </h2>
                      {/* <h5 className="grey-title">All times in PT</h5> */}
                    </Slide>
                    <div className="hr-line mb-3"></div>
                  </div>
                </div>
      
              
              <SessionPresentationCards presentations={Ashp21_presentations} openModalHandler={onOpenModal}  />

                <div className="row px-lg-5 pt-5">
                  <div className="col-12">
                    <Slide left>
                      <h2 className="font-weight-bold grey-title mb-0">
                        HIMSS21 Session Presentations:
                      </h2>
                      {/* <h5 className="grey-title">All times in PT</h5> */}
                    </Slide>
                    <div className="hr-line mb-3"></div>
                  </div>
                </div>
      
              
              <SessionPresentationCards presentations={Himss21_presentations} openModalHandler={onOpenModal}  />

             
                {/* <div className="col-lg-3 col-md-4 mt-4">
                  <div>
                    <Fade bottom>
                      <img src={Image13} className="img-fluid" />
                    </Fade>
                  </div>
                </div> */}

                 {/* <div className="col-lg-8 offset-lg-1 mt-5 pt-3">
                  <p className="h4 grey-title mt-4 text-height-1">
                    If you are unable to attend in person and would 
                    like to view a session after ASHP21, please request 
                    access by clicking the button below. 
                  </p>

                  <div className="mb-5 pt-3">
                    <ButtonElement disabled={disable} onClick={() => props.gainVideoAccess ? '' : onOpenModal("cantAttendRequestToView")}>Request access</ButtonElement>
                  </div>
                </div>  */}

             
                  <div className="row px-lg-5 text-center mb-5" id="ondemand">
                    <div className="col-md-6 mb-5">
                      <Fade bottom>
                        <img
                          src={Image14}
                          height={350}
                          width={350}
                          className="img-fluid"
                          alt="video"
                        />
                      </Fade>
                      <div>
                        <h3 className="mt-3 font-weight-semi-bold text-center grey-title mx-auto">
                          HIMSS21 On-Demand Videos
                        </h3>
                        <div className="hr-line w-50 mt-1 mx-auto"></div>
                      </div>
                    </div>

                    <VideoCards openModalHandler={onOpenModal} gainVideoAccess={props.gainVideoAccess} cards={cards_Himss21} />

               
                      <div className="col-12 mt-5">
                        <div>
                          <p className=" h2 font-weight-bold grey-title mb-0 text-left">
                            ASHP21 On-Demand Learning
                          </p>
                          <div className="hr-line mb-5"></div>
                        </div>
                      </div>
         


                    <VideoCards openModalHandler={onOpenModal} gainVideoAccess={props.gainVideoAccess} cards={cards_Ashp21} extended={true} />

                    <div className="col-12 text-center py-5">
                      <KeepExploring />
                    </div>
                  </div>
             
            
           
          </div>
        </div>
      </div>
    </>
  );
};

export default EducationArchive;
