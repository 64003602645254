import React, { useState } from "react"; 
import { NavLink } from 'react-router-dom';

import { Dropdown, ButtonGroup } from "react-bootstrap";

const MenuItems = () => {
  const [key, setKey] = useState(window.location.pathname)

  const styles = {
    active: {
      color: 'white',
      backgroundColor: '#004593'
    }
  };
    return (
        <>
        <Dropdown as={ButtonGroup}>
        <NavLink activeClassName="active"  className="nav-link nav-text nav-dropdown" to="/solutions-marketplace">Solutions Marketplace</NavLink >

        <Dropdown.Toggle split variant="light" id="dropdown-split-basic" className="border-top border-white"  />

        <Dropdown.Menu>
            <Dropdown.Item href="/cloud-based-analytics" style={(key === "/cloud-based-analytics") ? styles.active : {}} >Cloud-Based Analytics</Dropdown.Item>
            <Dropdown.Item href="/pharmacy-automation" style={(key === "/pharmacy-automation") ? styles.active : {}}>Pharmacy Automation</Dropdown.Item>
            <Dropdown.Item href="/enterprise-interoperability" style={(key === "/enterprise-interoperability") ? styles.active : {}}>Enterprise Interoperability</Dropdown.Item>
            <Dropdown.Item href="/enterprise-iosv" style={((key === "/enterprise-iosv") ? styles.active : {})}><span style={{whiteSpace: "normal"}}>Enterprise Inventory Optimization & Supply Visibility</span></Dropdown.Item>
        </Dropdown.Menu>
        </Dropdown>
        {/* END dropdown toggle */}

        <Dropdown as={ButtonGroup}>
        <NavLink activeClassName="active"  className="nav-link nav-text nav-dropdown" to="/education-center">Education Center</NavLink >

        <Dropdown.Toggle split variant="light" id="dropdown-split-education" className="border-top border-white" />

        <Dropdown.Menu>
            <Dropdown.Item href="/education-center-archive" style={(key === "/education-center-archive") ? styles.active : {}} >Education Center Archive</Dropdown.Item>
        </Dropdown.Menu>
        </Dropdown>

        <li className="nav-item">
          <NavLink 
            activeClassName="active"
            to="/resource-hub"
            className="nav-link nav-text">
            BD Resource Hub
          </NavLink >
        </li>
        <li className="nav-item">
          <NavLink 
          activeClassName="active"
            to="/community"
            className="nav-link nav-text">
            Support Our Community <br />
            Health Initiative
          </NavLink >
        </li>
        <li className="nav-item">
          <NavLink 
          activeClassName="active"
            to="/connect-with-us"
            className="nav-link nav-text">
            Connect with Us
          </NavLink >
        </li>
    </>
    )
}

export default MenuItems