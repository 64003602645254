import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-MG93WRV'
}

TagManager.initialize(tagManagerArgs)

ReactGA.initialize('UA-203934706-1');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
      <App />,
    document.getElementById("root")
  );