import React, {useLayoutEffect, useEffect, useRef} from 'react'
import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './mobileDivider.css'
gsap.registerPlugin(ScrollTrigger)

const MobileDivider = () => {

  let magnifierRef = null;
  let top_stretcher = null;
  let divider_background= null
  let top_conveyor_belt = null
  let div_cloud = null

  useLayoutEffect(() => {

    const tl = gsap.timeline({
        delay: .3,
        scrollTrigger: {
            trigger: '.mobileDivider-napc',
            end: 'bottom top',
        }
    })

    tl
        .fromTo(divider_background , { opacity: 0}, { opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
        .fromTo(top_stretcher, {xPercent: -200, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
        .fromTo(top_conveyor_belt, {xPercent: 200, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
        .fromTo(magnifierRef, {transformOrigin: 'center center', xPercent: -300, opacity: 0}, {yPercent: 0, xPercent: 0, opacity: 1, duration: 1.5, ease: "sine.inOut"}, "-=.5")
        .fromTo(div_cloud, {xPercent: 200, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)

      return () =>  {
        let killAnimation = tl
        tl.kill()
     }

  }, []);
    return (
<svg xmlns="http://www.w3.org/2000/svg" id="divider" viewBox="0 0 1491.96 1267.15" className="mobileDivider-napc">
  <path fill="#d0ecf3" d="M0 0h1491.96v1267.15H0z" ref={(e) => divider_background = e}/>
  <path id="div_cloud" ref={(e) => div_cloud = e} fill="#fff" stroke="#a9a9ab" strokeLinejoin="round" strokeWidth="2.47" d="M980.16 1160a60.22 60.22 0 0 0-78.22-51.13 67.65 67.65 0 0 0-113.77-40 3.73 3.73 0 0 1-6.33-2.69v-.11a95.81 95.81 0 0 0-191.07-10.3 85.48 85.48 0 0 0-78.58 90c2.48 45.59 41.4 80.7 87.06 80.7h321.54a60.22 60.22 0 0 0 59.37-66.47Z" data-name="div cloud"/>
  <g id="top_stretcher" data-name="top stretcher" ref={(e) => top_stretcher = e}>
    <path d="M893.52 568.37a10.71 10.71 0 0 0-15-1.85L778.09 645l-100.4-78.48a10.7 10.7 0 0 0-13.18 16.87l96.21 75.19L679 722.43a10.7 10.7 0 0 0 13.18 16.87l85.88-67.14L864 739.3a10.7 10.7 0 0 0 13.18-16.87l-81.69-63.85 96.21-75.19a10.7 10.7 0 0 0 1.82-15.02Z" className="cls-3"/>
    <path d="M689.19 733.16h190.58v15.29H689.19z" className="cls-3"/>
    <path d="M911.86 526.81H672.34L543.2 471.23a29.89 29.89 0 0 0-39.12 15.69 29.89 29.89 0 0 0 15.7 39.08l131.58 56.64a29.59 29.59 0 0 0 14.39 3.74h246.11a29.81 29.81 0 0 0 0-59.61Z" className="cls-4"/>
    <circle cx="677.21" cy="740.8" r="38.47" className="cls-5"/>
    <circle cx="883.56" cy="740.8" r="38.47" className="cls-5"/>
  </g>
  <g id="top_conveyor_belt" data-name="top conveyor belt" ref={(e) => top_conveyor_belt = e}>
    <path fill="#929495" stroke="#5d574f" strokeMiterlimit="10" strokeWidth="1.24" d="M872.17 215.93H542.06a19.46 19.46 0 1 0 0 38.91h330.11a19.46 19.46 0 0 0 0-38.91Z"/>
    <path d="m554.93 247.72 37.81-32.98-46.37-.71-11.35 9.9 19.91 23.79zM663.07 247.72l37.81-32.98-46.38-.71-11.34 9.9 19.91 23.79zM771.21 247.72l37.8-32.98-46.37-.71-11.34 9.9 19.91 23.79zM879.35 247.72l37.8-32.98-46.37-.71-11.34 9.9 19.91 23.79z" className="cls-7"/>
    <path d="M941.9 155.15H611.8a19.39 19.39 0 0 0-14 6l-67.39 58.69a19.34 19.34 0 0 1 11.68-3.94h330.08a19.42 19.42 0 0 1 14.26 32.63l66.73-58.13a19.45 19.45 0 0 0 8.2-15.83 19.51 19.51 0 0 0-19.46-19.42Z" className="cls-8"/>

    <circle cx="545.52" cy="235.38" r="15.53" className="cls-8"/>
    <circle cx="653.66" cy="235.38" r="15.53" className="cls-8"/>
    <circle cx="761.8" cy="235.38" r="15.53" className="cls-8"/>
    <circle cx="869.94" cy="235.38" r="15.53" className="cls-8"/>
    <circle cx="545.52" cy="235.38" r="7.77" className="cls-7"/>
    <circle cx="653.66" cy="235.38" r="7.77" className="cls-7"/>
    <circle cx="761.8" cy="235.38" r="7.77" className="cls-7"/>
    <circle cx="869.94" cy="235.38" r="7.77" className="cls-7"/>


    <path d="m598.94 87.58-33.55 23.28h77.75l33.55-23.28h-77.75z" className="cls-11"/>
    <path d="m624.99 87.58-33.55 23.28h24.86l33.55-23.28h-24.86z" className="cls-11"/>
    <path d="M643.14 110.86V200.06l33.55-23.29V87.58l-33.55 23.28z" className="cls-12"/>
    <path d="M598.94 110.86h-33.55v89.2h77.75V110.86h-44.2z" className="cls-13"/>
    <path d="M591.64 110.86h24.86v33.55h-24.86zM699.58 87.58l-33.55 23.28h77.75l33.55-23.28h-77.75z" className="cls-11"/>
    <path d="m725.63 87.58-33.55 23.28h24.86l33.55-23.28h-24.86z" className="cls-11"/>
    <path d="M743.78 110.86V200.06l33.55-23.29V87.58l-33.55 23.28z" className="cls-12"/>
    <path d="M699.58 110.86h-33.55v89.2h77.75V110.86h-44.2z" className="cls-13"/>
    <path d="M692.28 110.86h24.86v33.55h-24.86z" className="cls-11"/>
    <path d="M800.22 110.86h-33.55v89.2h77.75V110.86h-44.2z" className="cls-13"/>
    <path d="m800.22 87.58-33.55 23.28h77.75l33.55-23.28h-77.75z" className="cls-11"/>
    <path d="m826.27 87.58-33.55 23.28h24.86l33.55-23.28h-24.86zM792.92 110.86h24.86v33.55h-24.86z" className="cls-11"/>
    <path d="M844.42 110.86V200.06l33.55-23.29V87.58l-33.55 23.28z" className="cls-12"/>
  </g>
  <g  ref={(e) => magnifierRef = e} >
    <circle cx="874.33" cy="112.16" r="68.25" fill="#a6cede" opacity=".7"/>
    <path fill="#377bb1" d="M958.87 244.42 914.75 170l-.09.06c-1.09.73-2.22 1.4-3.32 2.11q-1.94 1.26-3.91 2.43l-8.69 4.6-.11-.21c-1.28.52-2.57 1-3.89 1.42a35.87 35.87 0 0 1-4.43 1.11 13.27 13.27 0 0 0 1 2.09l44.25 74.6c1.34 2.26 3.82 3.4 6.86 3.4a18.58 18.58 0 0 0 9.22-2.86 20 20 0 0 0 6.33-5.72c2.03-2.98 2.37-6.13.9-8.61Z"/>
    <path d="M874.76 188.5a75.32 75.32 0 1 1 75.32-75.32 75.41 75.41 0 0 1-75.32 75.32Zm0-138.79a63.48 63.48 0 1 0 63.48 63.47 63.54 63.54 0 0 0-63.48-63.47Z" className="cls-4"/>

    </g>
</svg>

    )
}

export default MobileDivider