import React, {useState, useEffect} from "react"
import { Modal } from "react-responsive-modal";
import LoadingSpinner from "./LoadingSpinner";


const SurveyModal = ( {modalId, closeModal, hideSpinnerHandler, isLoading}) => {

  const hideSpinner = () => hideSpinnerHandler();

    return (
        <Modal
        open={modalId === "survey"}
        onClose={closeModal}
        classNames={{
          modal: "customModal width-36",
        }}
        center
        focusTrapped={false}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {isLoading ? (
                <LoadingSpinner />
              ) : null }
              {/* BD NetScore Survey Slider Embed Code Section */}
              <div className="text-center embed-responsive embed-responsive-16by9 my-4 p-0">
                <iframe src="https://s.chkmkt.com/?e=251235&d=i&h=005E34A264408BC&l=en" onLoad={hideSpinner}  className="embed-responsive-item m-0 p-0" style={{width:'100%', height: '700px', border:'white'}}></iframe>
              </div>
              {/* End */}
            </div>
          </div>
        </div>
      </Modal>
    )
}

export default SurveyModal