import { gsap } from "gsap"


export const mapMarkerBounce = arr => {

    let tlBounceArr = [];
    const duration = 1.5;
    //firstChild refers to map markers (red teardrop and white circle), lastChild is marker's shadow
    arr.forEach((marker) => {
        let tlBounce = gsap.timeline({ repeat: -1, force3D: true, z:0})
        tlBounce.to(marker.firstChild, duration / 4, {z: 0, force3D: true, y:-20, ease: 'Power2.easeOut', delay:Math.random() * duration / 4});
        tlBounce.to(marker.lastChild, duration / 2 , {z: 0, force3D: true, scale:1.5, ease: 'Power2.easeOut', delay:duration / 4, transformOrigin:"right"}, '=-.8')
        tlBounce.to(marker.firstChild, duration / 2, {z: 0, force3D: true, y:0, ease: 'Bounce.easeOut', delay:Math.random() * duration / 4});
        tlBounce.to(marker.lastChild, duration / 4, {z: 0, force3D: true, scale:1, ease: 'Bounce.easeOut', delay:duration / 4}, '=-1')
        tlBounce.to(marker, {delay: Math.random() * 3, z:0, force3D: true,})

        tlBounceArr.push(tlBounce)
    })

    return tlBounceArr;

  
  };
