import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Modal } from "react-responsive-modal";
import Form from "../../../../components/Form/Form";

const AccessRequest = ({id, modalId, closeModal, title, directions, baseUrl, formId, munchkinId, onUserSubmit, linkedinID, ThankyouModalID}) => {
    return (
      <Modal
        open={modalId === id}
        onClose={closeModal}
        classNames={{
          modal: "customModal",
        }}
        center
        focusTrapped={false}>
        <Container style={{ overflow: "hidden" }} className="py-md-4 p-0">
          <Row>
            <Col sm={12} className="pb-4">
              <h4 style={{ color: "#02519d" }} className="font-weight-bold">
                {title}
              </h4>
              <p>
                {directions}
              </p>
              {/* Turn on button below to bypass filling out form! Make sure to comment out before production */}
              {/* <button onClick={() => onUserSubmit(linkedinID, ThankyouModalID)}>Development BYPASS</button> */}
              <Form
                baseUrl={baseUrl}
                formId={formId}
                munchkinId={munchkinId}
                onUserSubmit={onUserSubmit}
                linkedinID={linkedinID}
                ThankyouModalID={ThankyouModalID}
              />
            </Col>
          </Row>
        </Container>
      </Modal>
    )
}

export default AccessRequest