import React from "react";

export const SolutionsMarkerOverlay = () => {
  return (
    <>
      <rect 
        id="top-right-transparent" 
        width="103.64" 
        height="99.93" 
        x="1844.87" 
        y="993.44" 
        rx="49.97"
        fill="transparent"
        />

    </>
  );
};

export const CommunityMarkerOverlay = () => {
  return (
    <>
      <rect 
        id="mid-right-transparent" 
        width="103.64" 
        height="99.93" 
        x="2456.33" 
        y="1234.39" 
        rx="49.97"
        fill="transparent"
      />
    </>
  );
};

export const TheaterMarkerOverlay = () => {
  return (
    <>
      <rect 
        id="bottom-transparent" 
        width="103.64" 
        height="99.93" 
        x="1973.07" 
        y="1509.64" 
        rx="49.97"
        fill="transparent"
      />
    </>
  );
};

export const PostOfficeMarkerOverlay = () => {
  return (
    <>
      <rect 
        id="mid-left-transparent" 
        width="103.64" 
        height="99.93" 
        x="1469.07" 
        y="1247.3" 
        rx="49.97"
        fill="transparent"
      />
    </>
  );
};

export const ResourceMarkerOverlay = () => {
  return (
    <>
      <rect 
        id="top-left-transparent" 
        width="103.64" 
        height="99.93" 
        x="1296.02" 
        y="920.77" 
        rx="49.97"
        fill="transparent"
        />
    </>
  );
};