import React from "react";
import ThankYou from "./ThankYou";

const ThankYouModal = ({ modalId, closeModal}) => {
    return (
        thankYouMessages.map(message => {
            return (
                <ThankYou 
                    key={message.id}
                    id={message.id}
                    title={message.title}
                    description={message.description}
                    modalId={modalId}
                    closeModal={closeModal}
                />
            )
        })

    )
}

export default ThankYouModal

const thankYouMessages = [
    {
    /* Thank you modal for On-Demand video form submission */
    id:"unlockVideoAccess",
    title:"Thank you!",
    description:"Click on an unlocked video now."
    },
    {
    /* Thank you modal for Request access / Marketo if unable to attend  */
    id:"submissionForFuturePresentations",
    title:"Thank you!",
    description:"Your submission has been received."
    }
]