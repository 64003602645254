import React, {useEffect, useLayoutEffect} from 'react'
import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './PharmacyDivider.css'
gsap.registerPlugin(ScrollTrigger)

const PharmacyDivider = () => {

    let divider_backgroundRef = null
    let div_white_pillRef = null
    let div_eyeballRef = null
    let div_monitor = null
    let div_rx = null
    let div_structure = null

    //scroll trigger effects
    useLayoutEffect(() => {
        gsap.fromTo(div_eyeballRef, {scaleY: 1}, {scaleY: .9, duration: 1.5, ease: "sine.inOut", transformOrigin: 'center center', repeat: -1, yoyo: true})

          const tl = gsap.timeline({
            delay: .3,
            scrollTrigger: {
                trigger: '.divider',
                end: 'bottom top',
                toggleActions: "play none none reverse",
            }
        })

        tl
            .fromTo(divider_backgroundRef, {transformOrigin: 'center center', scale: .75, opacity: 0}, {opacity: 1, scale: 1, duration: 1, ease: "sine.inOut"}, 0)
            .fromTo(div_eyeballRef, {yPercent: -100, xPercent: 100, opacity: 0}, {yPercent: 0, xPercent: 0, opacity: 1, duration: 1.2, ease: "sine.inOut"}, 0)
            .fromTo(div_monitor, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, "<")
            .fromTo(div_rx, {yPercent: -100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1.2, ease: "sine.inOut"}, "<")
            .fromTo(div_structure, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1.2, ease: "sine.inOut"}, "<")
            .fromTo(div_white_pillRef, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, transformOrigin: 'center center', rotate: 360, duration: 2, ease: "sine.inOut"}, "<")

          return () =>  {
            let killAnimation = tl
            tl.kill()
         }

      }, []);

    //random floating effect for background
    useEffect(() => {

        let tweenProperty = (element, prop, min, max, direction) => {
    
            let randomDur = gsap.utils.random(.5, 1, .05, true);
    
            gsap.to(element, {
                [prop]: gsap.utils.random(min, max),
                duration: randomDur(),
                ease: "Sine.easeInOut",
                onComplete: tweenProperty,
                onCompleteParams: [element, prop, min, max, direction * -1]
            });
        }
    
        gsap.utils.toArray(divider_backgroundRef ).forEach(element => {
            tweenProperty(element, "x", 0, 5, 1)
            tweenProperty(element, "y", 10, 20, -1)
          });


      }, []);



    return(
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 -80 1448.79 400" className="pharmacy-divider divider">
        <defs/>
        <defs>
            <clipPath id="clipPath">
            <ellipse cx="156.98" cy="100.34" className="cls-1" rx="106.45" ry="53.74"/>
            </clipPath>
        </defs>
        <path id="divider_background" ref={(e) => divider_backgroundRef = e} fill="#d0ecf3" d="M1429.26 61.09c-24-22.06-64.89-32.86-91.94-37.79-37.24-6.8-75.42-6.11-113.2-3.78-90.29 5.58-179.76 20.41-269.89 28.22C874 54.69 792.26 56 712.88 40.22 635.89 24.9 565.41-5.86 484.32 1 450.63 3.81 417 19.91 383.92 28.64c-48.51 12.82-94.3 19-146.25 13.74-37.82-3.82-75.23-10.11-113.31-11.38-36.63-1.16-86.21-3.19-111.72 28.5-20.1 25-14.92 64.27 6.34 88.28s54.75 34.28 86.82 34.31 63.39-9 94.15-18.07c28.67-8.46 63.14-22 93.25-17.2 32 5.12 64.19 16.63 95.8 24.42 77.87 19.19 139.43 25.12 219.85 12.74 104.41-16.09 204.68-21 310.52-21 85.67 0 168.84 20.38 253.37 24 11.41.49 22.8 1.22 34.2 1.81 72.61 3.72 166.8 13.93 222.48-43.93 12.62-13.11 21.33-31.19 19-49.24-1.81-13.81-8.97-25.18-19.16-34.53z" data-name="divider background"/>
        <g id="div_white_pill" data-name="div white pill" ref={(e) => div_white_pillRef = e} >
            <path d="M778.87 69.22l-41 80.71a46.71 46.71 0 0041-80.71zM748.05 57.59a46.71 46.71 0 00-27.22 84.69l41.8-82.35a46.58 46.58 0 00-14.58-2.34z" className="cls-3"/>
            <path d="M762.63 59.93l-41.8 82.35a46.55 46.55 0 0017.08 7.65l41-80.71a46.67 46.67 0 00-16.28-9.29z" className="cls-3"/>
        </g>
        <g id="div_monitor" data-name="div monitor" ref={(e) => div_monitor = e}>
            <path d="M426.22 162.46h74.56v32.7h-74.56zM413.93 195.43h99.16v3.66h-99.16z" className="cls-4"/>
            <rect width="199.1" height="144.94" x="363.95" y="17.52" className="cls-4" rx="11.63"/>
            <rect width="181.66" height="128.77" x="372.67" y="25.61" fill="#b7ce56" stroke="#55a145" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.5" rx="5.96"/>
            <path d="M509.39 67.75h-21.92a2.37 2.37 0 01-2.38-2.38V43.45a2.38 2.38 0 00-2.38-2.38H444.3a2.37 2.37 0 00-2.38 2.38v21.92a2.38 2.38 0 01-2.38 2.38h-21.93a2.39 2.39 0 00-2.38 2.38v38.41a2.38 2.38 0 002.38 2.38h21.93a2.39 2.39 0 012.38 2.38v21.93a2.38 2.38 0 002.38 2.38h38.41a2.39 2.39 0 002.38-2.38V113.3a2.38 2.38 0 012.38-2.38h21.92a2.37 2.37 0 002.38-2.38V70.13a2.38 2.38 0 00-2.38-2.38z" className="cls-6"/>
        </g>
        <g id="div_structure" data-name="div structure" ref={(e) => div_structure = e}>
            <path d="M1328.84 113.18h17.91v-23h-17.91a5 5 0 01-5-5V60.06h-35.21v20.52a19.9 19.9 0 010 39.59v23.12h35.21v-25.06a5 5 0 015-5.05zM1266.64 100.37a19.9 19.9 0 0116.84-19.64V60.06h-33.93v25.06a5 5 0 01-5 5h-16.64v23h16.64a5 5 0 015 5v25.06h33.93V120a19.88 19.88 0 01-16.84-19.63z" className="cls-1"/>
            <path d="M1286.54 80.47a19.61 19.61 0 012 .11V60.06h35.21V55h-74.24v5.1h33.93v20.63a20.6 20.6 0 013.1-.26zM1323.79 143.29h-35.21v-23.12a19.63 19.63 0 01-2 .1 19.34 19.34 0 01-3.06-.26v23.28h-33.93v5.1h74.24zM1346.75 90.17h5.1v23.02h-5.1z" className="cls-3"/>
            <path d="M1372.27 85.12V29.9a5 5 0 00-5-5h-38.38a5 5 0 00-5 5v55.22a5 5 0 005 5h38.38a5 5 0 005-5zM1372.27 173.45v-55.22a5 5 0 00-5-5h-38.38a5 5 0 00-5 5v55.22a5 5 0 005 5.05h38.38a5 5 0 005-5.05z" className="cls-7"/>
            <path d="M1222.76 90.17h5.1v23.02h-5.1z" className="cls-3"/>
            <path d="M1249.55 85.12V29.9a5 5 0 00-5-5h-38.38a5 5 0 00-5 5v55.22a5 5 0 005 5h38.38a5 5 0 005-5zM1249.55 173.45v-55.22a5 5 0 00-5-5h-38.38a5 5 0 00-5 5v55.22a5 5 0 005 5.05h38.38a5 5 0 005-5.05zM1288.58 120.17a19.9 19.9 0 000-39.59 19.61 19.61 0 00-2-.11 20.6 20.6 0 00-3.06.26 19.87 19.87 0 000 39.28 19.34 19.34 0 003.06.26 19.63 19.63 0 002-.1z" className="cls-7"/>
        </g>
        <g id="div_rx" data-name="div rx" ref={(e) => div_rx = e}>
            <circle cx="997.85" cy="104.93" r="88.84" fill="#f68622" stroke="#d35327" strokeMiterlimit="10"/>
            <path d="M1046.2 101.2h-18.07l-13.65 17.93-11.2-18.47a23.84 23.84 0 008-5.64 25.32 25.32 0 005.24-8.53 29 29 0 001.82-10.18 27.48 27.48 0 00-1.94-10.11 29.19 29.19 0 00-5.53-8.94 27 27 0 00-8.47-6.36 24.28 24.28 0 00-10.64-2.35h-36.94v83.52h16.23v-28h16.24l17.17 28.2-22.76 29.87h17.88l12.85-16.8 10.73 17.66H1041l-18.6-30.7zm-45.62-18.06a12.85 12.85 0 01-3.77 4.86 8.62 8.62 0 01-5.41 1.82h-20.35V62.78h19.65a9.36 9.36 0 015.47 1.77 13.75 13.75 0 014.17 4.76 14.31 14.31 0 011.65 7 16.14 16.14 0 01-1.41 6.83z" className="cls-6"/>
        </g>
        <g id="div_eyeball" data-name="div eyeball"  ref={(e) => div_eyeballRef = e}>
            <ellipse cx="156.98" cy="99.92" className="cls-3" rx="106.45" ry="53.74"/>
            <g clipPath="url(#clipPath)">
            <circle cx="156.98" cy="100.34" r="58.22" className="cls-7"/>
            <circle cx="156.98" cy="100.34" r="21.61" fill="#377bb1"/>
            </g>
        </g>
        </svg>

    )
}

export default PharmacyDivider