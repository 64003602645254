import styled from "styled-components";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-203934706-1');
ReactGA.pageview(window.location.pathname + window.location.search);


export const FooterContainer = styled.footer`
  background-color: #014695;
  position: relative;
  z-index: 2;
  min-height: 7rem;
  @media (width: 1366px) {
    height: 11%;
  }
  @media (min-width: 1337px) {
    height: 12%;
  }
  @media only screen 
  and (device-width: 375px) 
  and (device-height: 812px) 
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: landscape)  {
  height: 67% !important;
}
  @media only screen and (min-width: 414px) and (max-width: 896px) and (orientation: landscape) and (-webkit-device-pixel-ratio : 3) {
    height: 60%;
  }
  @media only screen and (min-width: 300px) and (max-width: 700px) and (orientation: landscape) and (-webkit-device-pixel-ratio : 2) {
    height: 60%;
  }
  @media screen and (width: 1280px) and (min-height: 850px) and (max-height: 1024px) and (orientation:landscape) {
    height: 12%;
  }
  &::before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 5rem;
    background-color: #014695;
    transform: skewY(1.3deg);
    top: -2rem;
    left: 0rem;
  }
`;

// export const SocialLogo = styled(Link)`
//   color: #fff;
//   justify-self: start;
//   cursor: pointer;
//   text-decoration: none;
//   font-size: 2rem;
//   display: flex;
//   align-items: center;
//   margin-bottom: 16px;
// `;

// export const SocialIcon = styled(FaHospital)`
//   margin-right: 10px;
// `;

// export const WebsiteRights = styled.small`
//   color: #fff;
//   margin-bottom: 6px;
//   padding: 12px;
// `;

// export const SocialIcons = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 140px;
//   padding: 10px;
// `;

export const SocialIconLink = styled.a`
  color: #fff;
  transition: color 200ms;
  font-size: 13px;
  margin: 0 15px;
  &:hover {
    color: #99b5d4;
  }
  @media (min-width: 992px) {
    margin: 0 4px;
    font-size: 20px;
  }
  @media (min-width: 1200px) {
    margin: 0 7px;
    font-size: 1.56rem;
  }
  @media (min-width: 1400px) {
    margin: 0 12px;
  }
`;

export const LegalLinks = styled.a`
  color: #fff;
  cursor: pointer;
  font-size: 11px;
  font-weight: bold;
  transition: color 200ms;
  &:hover {
    color: #99b5d4;
    text-decoration: none;
  }
`;

export const PipeSpan = styled.span`
  color: #fff;
  display: inline-block;
  margin: 0 7px;
`;

// End