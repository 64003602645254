import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Modal } from "react-responsive-modal";
import Fade from "react-reveal/Fade";


const VideoModal = ({id, modalId, closeModal, title, iframeSrc}) => {


    return (
      <Modal
        open={modalId === id}
        onClose={closeModal}
        classNames={{
          modal: "customModal",
        }}
        center
        focusTrapped={false}>
        <Container style={{ overflow: "hidden" }} className="py-md-4 p-0">
          <Row>
            <Col sm={12} className="pb-4">
              <Fade right>
                <p
                  className="pb-2 font-weight-bold h5"
                  style={{ color: "#02519d" }}>
                  {title}
                </p>
              </Fade>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title={title}
                  src={iframeSrc}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal> 
    )
}

export default VideoModal