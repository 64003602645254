import React  from "react"
import { Modal } from "react-responsive-modal";
import { FaAngleDoubleLeft } from "react-icons/fa";
import directReliefLogo from "../../../images/donationTracker/logo-direct-relief.png";

const DonationTrackerModal = ({ bicyclistsFound, modalId, modalState, closeModal, continueExperience}) => {
    return (
    
      <Modal
      open={modalId == "finishExperience"}
      onClose={closeModal}
      classNames={{
        modal: "customModal no-padding max-width-30 ",
      }}
      center
      focusTrapped={false}>
      <h4
        className="text-center font-weight-semi-bold"
        style={{ color: "#02519d" }}>
        Donation Tracker
      </h4>
      <div className="p-2" style={{ backgroundColor: "#c8e2f5" }}>
        <p
          className="pt-2 text-center"
          style={{ backgroundColor: "#c8e2f5" }}>
          Bicyclists found:
        </p>
        <h3 className="text-center font-weight-bold">
          {bicyclistsFound} of 6
        </h3>
        <span className="h2" id="output-area"></span>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h5 className="m-1 p-3 text-start">
              Thank you for exploring our virtual experience. You have
              discovered <strong>{bicyclistsFound}</strong> donation
              tokens. Your engagement helps us to support the international
              aid organization, Direct Relief.
            </h5>
            <img
              className="d-flex mx-auto my-2"
              src={directReliefLogo}
              height={35}
              width={150}
            />
            <div className="text-start m-1 p-1">
              {/* <Form
                baseUrl="//go.bd.com"
                formId="15987"
                munchkinId="565-YXD-236"
                onUserSubmit={onFinishedFormSubmit}
              /> */}
              <div className="text-start m-1 p-1">
                <h5>
                  Would you like to continue exploring the site or finish your
                  experience?
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center m-1">
        <button
          type="button"
          className="btn text-white rounded-0 d-inline-block mb-3"
          onClick={() => modalState("survey")}
          style={{ backgroundColor: "#f26925" }}>
          Finish experience
        </button>
        <div className="d-flex justify-content-center mt-3 mb-3">
          <a
            onClick={() => closeModal(null)}
            style={{ color: "#02519d", cursor: "pointer" }}
            className="font-weight-semi-bold">
            <FaAngleDoubleLeft />
            Continue experience
          </a>
        </div>
      </div>

      {/* <button
        type="button"
        className="btn text-white rounded-0 d-inline-block"
        onClick={onOpenNetScore}
        style={{ backgroundColor: "#f26925" }}>
        Finish experience
      </button> */}
    </Modal>

    )
}

export default DonationTrackerModal