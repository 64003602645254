import React from "react";
import Header from "../../components/UI/Header/Header";
import HeaderImg from "../../images/headers/marketplace-header.png";
import hackensackPDF from "../../Documents/archive/HIMSS22/bd-hsca-hsia-hackensack-meridian-case-study.pdf";
import pharmacistShelfImg from "../../images/archive/HIMSS22/bd-insights-hero-1920.jpg";
import bigDataPDF from "../../Documents/archive/HIMSS22/bd-urmc-case-study-big-data-results.pdf";
import diversionPDF from "../../Documents/archive/HIMSS22/bd-mms_bd-institute-diversion-rp-en-report.pdf";
import receptionistImg from "../../images/archive/HIMSS22/bd-receptionist-pc.jpg";
import doctorNurseImg from "../../images/archive/HIMSS22/bd-doctor-nurse.jpg";
import KeepExploring from "../../components/UI/Button/KeepExploring";
import DoctorSVG from "./DoctorSVG";
import Divider from "./Divider";
import InformationCard from "../components/InformationCard";
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import TopDescription from '../components/TopDescription'
import ReactGA from 'react-ga';
import {useWindowDimension} from '../../components/UI/useWindowDimension'
import MobileDivider from "./mobile/MobileDivider";

ReactGA.initialize('UA-203934706-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const CloudBasedAnalytics = (props) => {
  const [width, height] = useWindowDimension();

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-2 p-xl-0">
        <SideNavBar {...props} />
        </div>
        <div className="col-xl-10 bg-wild-sand subpage-content ">
    
          <Header image={HeaderImg} title="Cloud-Based Analytics" />

   
            <div className="row px-md-5 d-flex align-items-center pt-5">
              <TopDescription 
                description="BD HealthSight™ enterprise pharmacy analytics solutions help
                close gaps to help reduce medication errors and waste and
                connect disparate systems to create visibility and provide
                actionable insights for patient care."
              />
              <div className="col-lg-7 text-md-right order-1 order-lg-2 mb-5">
                <DoctorSVG />
              </div>
            </div>
            <div className="row px-md-5">
              <div className="col-md-12">
                {width <= 500 ? 
                  <MobileDivider />
                  : <Divider />
                }
              </div>
            </div>
            <div>
              <InformationCard 
                img={doctorNurseImg}
                imgAlt="Two medical professionals looking at a computer screen"
                title="BD HealthSight™ Clinical Advisor"
                description="BD HealthSight™ Clinical Advisor automates aggregation and
                standardization of clinical data to help advance medication
                stewardship and patient safety with customizable clinical
                alerting and reporting, Sanford Guide integration, and
                insights into regional / national antibiotic resistance and
                trend data."
                contentLabel="Hackensack Meridian Case Study"
                btnText="Download now"
                btnDownload={hackensackPDF}
              /> 

            <InformationCard 
                img={pharmacistShelfImg}
                imgAlt="Pharmacist woman looking at medication on a shelf"
                title="BD HealthSight™ Inventory Optimization"
                description="BD HealthSight™ Inventory Optimization Analytics Software
                automates your process of adjusting PAR levels, empowering
                your clinicians to quickly and confidently take meaningful
                action to optimize medication inventory so that your patient
                safety is enhanced and remains paramount throughout your
                pharmacy operations. <br><br>
                With enterprise-wide visibility and control of medications, we
                help secure for you the assurance you may need to get the
                right medication to the right place at the right time."
                contentLabel="From Big Data to Results: URMC"
                btnText="Download now"
                btnDownload={bigDataPDF}
              /> 
            
            <InformationCard 
                img={receptionistImg}
                imgAlt="Receptionist at desk typing on a computer"
                title="BD HealthSight™ Diversion Management"
                description="<p>
                Drug diversion is a challenge for hospitals since healthcare
                workers face the same rates of addiction to opioids as other
                people. The pandemic has exacerbated the problem. Prevention is
                a priority but gaps between systems and manual processes for
                aggregating and analyzing data make it difficult to detect who
                is diverting.
              </p><br>
              <p>
                BD HealthSight™ Diversion Management is a machine learning
                based, hosted software application that leverages BD
                technologies and a hospital’s EMR to assist with diversion
                investigations. With it you can:
              </p>
              <ul class='pl-4'>
                <li>
                  Identify clinicians whose behavior indicates higher rate of
                  anomalous behaviors.
                </li>
                <li>
                  Automate the time consuming manual reconciliation process
                  and discover discrepancies that may warrant inquiry.
                </li>
                <li>
                  Monitor, triage, assign cases to investigators and document
                  results.
                </li>
              </ul>"
                contentLabel="Health Care’s Hidden Epidemic - A Call to Action on Hospital
                Drug Diversion Report"
                btnText="Download now"
                btnDownload={diversionPDF}
              /> 
            
            


              <div className="row pb-5">
                  <div className="col-12 text-center py-5">
                    <KeepExploring solutions={true} /> 
                  </div>
                </div>
       
        

          </div>
        </div>
    
      </div>
    </div>
  );
};

export default CloudBasedAnalytics;