import React from "react";
import VideoCard from "./VideoCard";
import VideoCardExtended from "./VideoCardExtended";


const VideoCards = ({ gainVideoAccess, openModalHandler, cards, extended = false}) => {
    if(!extended){
        return (
            cards.map(card => {
                return (
                    <VideoCard 
                        key={card.videoModalID}
                        img={card.img}
                        topTitle={card.topTitle}
                        bottomTitle={card.bottomTitle}
                        videoModalID={card.videoModalID}
                        gainVideoAccess={gainVideoAccess}
                        openModalHandler={openModalHandler}
                    />
                )
            })
        )
    } else {
        return (
            cards.map(card => {
                return (
                    <VideoCardExtended
                        key={card.videoModalID}
                        img={card.img}
                        title={card.title}
                        description={card.description}
                        speakers={card.speakers}
                        videoModalID={card.videoModalID}
                        gainVideoAccess={gainVideoAccess}
                        openModalHandler={openModalHandler}
                    />
                )
            })
        ) 
    }
}

export default VideoCards
