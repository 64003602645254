export const DataChallengeVideos = [
  {
    id: "vid1",
    title: "Disconnected systems and workflows",
    img: require("../../../images/challengeVideos/challenge-1.jpg"),
    alt: "click to open Disconnected systems and workflows video in another modal",
    path: "/cloud-based-analytics",
    embed:
      '<iframe src="https://player.vimeo.com/video/670419427?h=60acdca66c&amp;badge=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Disconnected systems and workflows"></iframe>',
    },
  {
    id: "vid2",
    title: "Limited visibility into medication flow",
    img: require("../../../images/challengeVideos/challenge-2.jpg"),
    alt: "click to open Limited visibility into medication flow video in another modal",
    path: "/pharmacy-automation",
    embed:
      '<iframe src="https://player.vimeo.com/video/670421363?h=180d379e0f&amp;badge=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Limited visibility into medication flow"></iframe>',
  },
  {
    id: "vid3",
    title: "Slow response to changing patient status",
    img: require("../../../images/challengeVideos/challenge-3.jpg"),
    alt: "click to open Slow response to changing patient status video in another modal",
    path: "/enterprise-interoperability",
    embed:
      '<iframe src="https://player.vimeo.com/video/670422532?h=257662482b&amp;badge=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Slow response to changing patient status"></iframe>',
  },
];