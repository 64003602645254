import React from "react";
import Image14 from "../../images/community/support-direct-relief@2x.png";
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import Header from "../../components/UI/Header/Header";
import HeaderImg from "../../images/headers/med-bldg-blue-windows.png"
import KeepExploring from "../../components/UI/Button/KeepExploring";
import Fade from 'react-reveal/Fade';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-203934706-1');
ReactGA.pageview(window.location.pathname + window.location.search);


const OurCommunity = (props) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-2 p-xl-0">
            <SideNavBar {...props} />
          </div>

        <div className="col-xl-10 bg-wild-sand subpage-content" >
          <Header
            image={HeaderImg}
            title="Support Our Community Health Initiative "
            description="Learn how you can help support community health centers through <span className='d-lg-block'> international aid organization, Direct Relief, in
            partnership with BD.</span>"
          />
     
            <div className="row mb-5 mt-5 pt-4 pb-4">
              <div className="col-md-4">
                <div className="mb-5 text-center">
                  <Fade bottom>
                    <img src={Image14} className="img-fluid w-50" alt="direct-relief-logo" />
                  </Fade>
                </div>
              </div>
              <div className="col-md-8">
                <p className="lead">
                  By visiting this virtual experience and clicking on the bicyclists around the city, 
                  you are helping us to support International Aid Organization, Direct Relief.
                </p>
                <p className="lead mt-4">
                  Click on this link to learn more about how with your participation, 
                  BD is helping to build healthy communities for underserved populations. 
                </p>
                <div className="mt-4">
                  <a
                    href="https://www.directrelief.org/work/bdhbhc/"
                    target="_blank"
                    className="font-weight-bold h5"
                    style={{ color: "#014695", wordBreak:'break-word'}}>
                    https://www.directrelief.org/work/bdhbhc/
                  </a>
                </div>
              </div>
            </div>
            <div className="row pb-5">
                  <div className="col-12 text-center  my-5 py-5">
                    <KeepExploring  /> 
                  </div>
                </div>
        </div>
      </div>
    </div>
  );
};

export default OurCommunity;