import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
// import "./CitySVG.css";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";
import { useWindowDimension } from "../../../UI/useWindowDimension";

const BubbleTitle = styled.text`
  cursor: pointer;
  text-rendering: geometricprecision;
  font-size: 1.8em;
  fill: #014695;
  font-family: FSAlbertArabicBold, Arial, Helvetica, sans-serif;
  font-weight: 500;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  & tspan {
    font-family: FSAlbertArabicBold, Arial, Helvetica, sans-serif;
    font-weight: 500;
    text-rendering: geometricprecision;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
  }
`;

const BubbleGroup = styled.g`
  cursor: pointer;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  transform: translateZ(0);
`;

const BubbleBackground = styled.path`
  fill: white;
  filter: drop-shadow(-5px 10px 10px rgba(0, 0, 0, 0.7));
`;

const BubbleText = styled.text`
  opacity: 0;
  visibility: hidden;
  text-rendering: geometricprecision;
  font-size: 1.2em;
  fill: #000000;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
`;

const CityBuildingBubble = (props) => {
  let bubbleRef = null;
  let bubbleTitleRef = null;
  let bubbleTextRef = null;
  let bubbleHoverTl = useRef(gsap.timeline({ paused: true }));

  const history = useHistory();
  const [delayHandler, setDelayHandler] = useState(null);
  const [zoomOnClick, setZoomOnClick] = useState(false);

  const [width, height] = useWindowDimension();

  const playBubbleAnimation = () => {
    // dont show bubble open animation for tablet, tablet landscape, mobile, mobile landscape
    if (
      (width == 1024 && height == 768) ||
      (width <= 768 && height <= 1024) ||
      (width == 1024 && height == 1366) ||
      (width == 1266 && height == 1024) ||
      width <= 768 ||
      (width >= 500 && width <= 882)
    ) {
      bubbleHoverTl.current.pause();
    } else {
      bubbleHoverTl.current.play();
      clearTimeout(delayHandler);
    }
  };

  const reverseBubbleAnimation = () => {
    setDelayHandler(
      setTimeout(() => {
        bubbleHoverTl.current.reverse();
      }, 500)
    );
  };

  const onMouseEnterHandler = () => playBubbleAnimation();
  const onMouseLeaveHandler = () => reverseBubbleAnimation();

  const onClickHandler = () => {
    setZoomOnClick(true);
  };

  useEffect(() => {
    if (zoomOnClick) {
      if (
        (width == 1024 && height >= 600) ||
        (width == 1440) ||
        (width <= 768 && height <= 1024) ||
        (width == 1024 && height == 1366) ||
        (width == 1266 && height == 1024) ||
        width <= 768 ||
        (width >= 500 && width <= 882)
      ) {
        history.push(props.path);
      } else {
        gsap.to(".citySVG",
        {
          duration: 1.3,
          attr: { viewBox: props.coordinates },
          scale: 2,
          z: 0,
          transformOrigin: "center center",
          rotate: 0.01,
          force3D: true,
          onComplete: () => {
            history.push(props.path);
          },
        });
      }
    }
  }, [zoomOnClick]);

  useEffect(() => {
    gsap.set(bubbleRef, {
      scaleX: props.startingScaleX,
      scaleY: props.startingScaleY,
      transformOrigin: props.transformOrigin,
      force3D: true,
    });
    bubbleHoverTl.current
      .to(bubbleRef, { duration: 0.3, scale: 1.35, z: 0, force3D: true })
      .to(
        bubbleTitleRef,
        {
          duration: 0.2,
          x: props.hoverTitleXPosition,
          y: props.hoverTitleYPosition,
          z: 0,
          force3D: true,
          scale: 1.2,
        },
        "=-.2"
      )
      .to(
        bubbleTextRef,
        {
          duration: 0.2,
          x: props.hoverTextXPosition,
          y: props.hoverTextYPosition,
          autoAlpha: 1,
          z: 0,
          force3D: true,
          scale: 1.4,
        },
        "=-.1"
      );
  }, []);

  return (
    <>
      <BubbleGroup
        transformOrigin={props.startingYPosition}
        onMouseOver={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        onClick={onClickHandler}>
        <BubbleBackground
          transform={props.backgroundTransform}
          d={props.backgroundDPath}
          startingScale={props.startingScale}
          ref={(e) => (bubbleRef = e)}></BubbleBackground>

        <BubbleTitle
          transform={props.titleTransform}
          ref={(e) => (bubbleTitleRef = e)}>
          {ReactHtmlParser(props.title)}
        </BubbleTitle>

        <BubbleText
          transform={props.textTransform}
          ref={(e) => (bubbleTextRef = e)}>
          {ReactHtmlParser(props.text)}
        </BubbleText>

        {props.markerOverlay}
      </BubbleGroup>
    </>
  );
};

export default CityBuildingBubble;