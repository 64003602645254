import { gsap } from "gsap"

export const signGlimmer = arr => {

    let tlGlimmerArr = [];
    const duration = 1.5;

    arr.forEach((glimmer) => {
        let tlGlimmer = gsap.timeline({ repeat: -1, delay: Math.random() * duration, force3D: true, z:0})
        tlGlimmer.timeScale(.5)
        tlGlimmer.to(glimmer, {x:-60, opacity: .9, scaleY: 2.5, scaleX: 1.5, z:0, force3D: true, ease: 'Power2.easeIn', transformOrigin:"50% 50%"})
        tlGlimmer.to(glimmer, {x:-70, opacity: 0, scaleY: .9, z:0, force3D: true, ease: 'Power2.easeOut', transformOrigin:"50% 50%"})
        tlGlimmer.to(glimmer, {delay: Math.random() * duration, z:0, force3D: true})

        tlGlimmerArr.push(tlGlimmer)
    })

    return tlGlimmerArr;
  
  };