import React, {useState} from "react";
import styled from "styled-components";
import Header from '../../components/UI/Header/Header'
import KeepExploring from "../../components/UI/Button/KeepExploring";
import HeaderImg from '../../images/headers/post-office-header.png';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Image16 from "../../images/connectWithUs/bd-connect-envelopes@2x.png";
import checkmark from "../../images/forms/checkmark__branded.svg";
import SideNavBar from "../../components/SideNavBar/SideNavBar";
import Form from '../../components/Form/Form'
import { Modal } from "react-responsive-modal";
import "../../components/Modals/ModalsCSS/modal.css";
// Note: This will be kept in case BD would like to re-implement for the second event in December 2021
// import LiveChat from 'react-livechat' - 
// End LiveChat import
import ReactGA from 'react-ga';
import LinkedInTag from 'react-linkedin-insight';

LinkedInTag.init('807690');

ReactGA.initialize('UA-203934706-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const GreyText = styled.p`
  color: #4B4B4B;
`
const ConnectWithUs = (props) => {
  const [openThankYou, setOpenThankYou] = useState(false)
  const onOpenThankYou = () => setOpenThankYou(true);
  const onCloseThankYou = () => setOpenThankYou(false);

  const [removeForm, setRemoveForm] = useState(false)

  const formSubmitted = () => {
    onOpenThankYou()
    LinkedInTag.track(6528785);
    setRemoveForm(true)
  };

  return (
    <>
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-2 p-xl-0">
            <SideNavBar {...props} />
          </div>
          {/* <LiveChat license={12943914} /> */}
        <div className="col-xl-10 bg-wild-sand subpage-content ">
          <Header
            image={HeaderImg} 
            title="Connect with Us"
            description="We are ready to connect and discuss your unique challenges."
          />
       
            <div className="row px-md-5">
              {/* <div className="col-lg-6 mb-5">
                <Slide left>
                  <GreyText className="font-weight-bold mb-2 h2">Chat now</GreyText>
                </Slide>
                <div className="hr-line" style={{width: '90%'}}></div>
                <div className="row mt-5">
                  <div className="col-md-4">
                    <Fade bottom>
                      <img className="img-fluid" src={Image14} alt="" />
                    </Fade>
                  </div>
                  <div className="col-md-8">
                    <div style={{fontSize: '1.4rem'}}>
                      <p className="mb-4">Get your questions answered live.</p>
                      <p className="font-weight-semi-bold">Live chat dates/times:</p>
                      <p>Tues, 8/10 - Thurs, 8/12</p>
                      <p>10am - 12pm PT</p>
                      <p>2pm - 4pm PT</p>
                    </div>
                    <div className="mb-5 pt-3 w-75">
                      <ButtonHref href="" id="chat-btn" className="livechat_button" data-id="6a87d82432">
                        Enter live chat
                      </ButtonHref>
                    </div> 
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-6 mb-5">
                <Slide left>
                  <GreyText className="font-weight-bold mb-2 h2">Schedule a meeting</GreyText>
                </Slide>
                <div className="hr-line"></div>
                <div className="row mt-5">
                  <div className="col-lg-5 col-md-4">
                    <Fade bottom>
                      <img className="img-fluid" src={Image15} alt=""/>
                    </Fade>
                  </div>
                  <div className="col-lg-7 col-md-8">
                    <div style={{fontSize: '1.4rem'}}>
                      <p className="pb-4">Meet virtually one-on-one with one of our specialists and let us help you reach your objectives.</p>
                      <p>Choose your preferred date and time.</p>
                    </div>
                    <div className="my-5">  
                    <ButtonHref href="https://calendly.com/bd-mms" target="_blank">Schedule meeting</ButtonHref>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-12 py-5">  
                <GreyText className="font-weight-semi-bold px-3 px-md-0 text-center" style={{fontSize: '1.4rem'}}>
                  Attending ASHP21 in person? Visit us at booth #6326.
                </GreyText>
              </div> */}
              <div className="col-12 mt-md-5 pt-5">
                <Slide left>
                  <GreyText className="font-weight-semi-bold h2">
                    Learn more about Connected Medication Management
                  </GreyText>
                </Slide>
                <div className="hr-line"></div>
                <div className="row">
                  <div className="col-lg-5">
                    {!removeForm &&
                      <div>
                        <p className="mt-4 mb-3 lead">
                          Please complete the form below to connect with us.
                        </p>
                        <Form baseUrl="//go.bd.com" formId="17488" munchkinId="565-YXD-236" onUserSubmit={formSubmitted}/>
                      </div>
                    }
                    {removeForm &&
                      <div>
                        <p className="mt-4 mb-3 h3">
                          Thank you for connecting with us!
                        </p>
                        <p style={{color:'#004594'}} className="h4 font-weight-bold">
                        We have received your submission.
                        </p>
                    </div>
                    }
                  </div>
                  <div className="col-lg-7 d-flex justify-content-center align-items-center pt-5 pt-lg-0">
                    <Fade bottom>
                      <img className="img-fluid" style={{width:'30rem'}} src={Image16} alt="" />
                    </Fade>
                  </div>
                </div>
                <div className="text-center my-5 py-4">
                  <KeepExploring />
                </div>
              </div>
            </div>
            
      
        </div>
      </div>
    </div>
      <Modal
          open={openThankYou}
          onClose={onCloseThankYou}
          classNames={{
          modal: "customModal",
          }}
          center
          focusTrapped={false}>
          <div style={{ overflow: "hidden" }} className="py-md-4 p-0 container">
          <div className="row">
              <div className="col-4 offset-4 my-3">
                <Bounce top>
                  <img src={checkmark} alt="" className="img-fluid" />
                </Bounce>
              </div>
              <div className="pb-4 col-12 text-center">
                <Fade>
                  <h4 style={{color: '#02519d'}} className="font-weight-bold">Thank you!</h4>
                  <p className="lead">Your submission has been received.</p>
                </Fade>
              </div>
          </div>
          </div>
      </Modal>
    </>
  );
};

export default ConnectWithUs;