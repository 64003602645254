import React from "react";
import "react-responsive-modal/styles.css";
import Fade from "react-reveal/Fade";
import ReactHtmlParser from "react-html-parser";
import { Modal } from "react-responsive-modal";
import { Container, Row, Col } from "react-bootstrap";
import ButtonLink from "../../UI/Button/ButtonLink";
import ReactGA from "react-ga";

ReactGA.initialize("UA-203934706-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const SingleChallengeVideo = ({
  handlerToCloseModal,
  img,
  title,
  path,
  show,
  embed,
}) => {
  const closeModal = () => handlerToCloseModal();

  return (
    <>
      <Modal
        open={show}
        onClose={closeModal}
        classNames={{
          modal: "customModal",
        }}
        center>
        <Container style={{ overflow: "hidden" }} className="py-md-4 p-0">
          <Row>
            <Col sm={12} className="pb-4">
              <Fade right>
                <p
                  className="pb-2 font-weight-bold h5"
                  style={{ color: "#02519d" }}>
                  {title}
                </p>
              </Fade>
              <div className="embed-responsive embed-responsive-16by9">
                {ReactHtmlParser(embed)}
              </div>
            </Col>
            <Col sm={12} className="text-right">
              <ButtonLink to={path}>Learn more</ButtonLink>
            </Col>
          </Row>
        </Container>
        <div></div>
      </Modal>
    </>
  );
};

export default SingleChallengeVideo;