import React from "react";
import marker from "../../../images/donationTracker/mapmarker.png";
import bicycle from "../../../images/donationTracker/bikeForTour.png";
import {
  TopArrow,
  BottomArrow,
  LeftArrow,
  RightArrow,
  BottomRightArrow,
} from "./Arrow";
import "../Home.css";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-203934706-1');
ReactGA.pageview(window.location.pathname + window.location.search);

// For React Tour on homepage
export const steps = [
  {
    content: ({ goTo, inDOM }) => (
      <div className="step-1 card-container">
        <div style={{ color: "#02519d" }}>
          <h5 className="font-weight-bold">
            Welcome to Our HIMSS22
            <br />
            Virtual Experience
          </h5>
        </div>
        <div>
          <p>
            Explore the cityscape to discover solutions for 
            connected medication management and much more. <br/>
            <span className="small">The experience is best viewed on Google Chrome for desktop.</span>
          </p>
        </div>
      </div>
    ),
    style: {
      backgroundColor: "#fff",
      paddingBottom: '3rem'
    },
    stepInteraction: false,
  },
  {
    selector: "#top-right-transparent",
    content: ({ goTo, inDOM }) => (
      <div className="card-container step-2">
        <TopArrow className="top" />
        <LeftArrow className="left-long" />
        <div style={{ color: "#02519d" }}>
          <h5 className="font-weight-bold font-weight-bold">
            Navigate the city
          </h5>
        </div>
        <div>
          <p>
            To explore, click on a building's red map marker or select from the menu bar.
            <img src={marker} alt="" className="img-fluid" style={{position:'absolute', height:'2rem', top:'3rem'}} />
          </p>
        </div>
      </div>
    ),
    style: {
      backgroundColor: "#fff",
      paddingBottom: '3rem'
    },
    position: "left",
    stepInteraction: false,
  },
  {
    selector: "#music",
    content: () => (
      <div className="card-container step-3">
        <RightArrow className="right-long" />
        <div style={{ color: "#02519d", fontWeight: "bold" }}>
          <h5 className="font-weight-bold font-weight-bold">
            Control the city sound
          </h5>
        </div>
        <div>
          <p>
            Click the speaker icon to mute and unmute the city's soundscape.
          </p>
        </div>
      </div>
    ),
    position: "center",
    style: {
      backgroundColor: "#fff",
      paddingBottom: '3rem'
    },
    stepInteraction: false,
  },
  {
    selector: "#hiddenTourBike",
    content: () => (
      <div className="card-container step-4">
        <BottomArrow className="bottom-left" />
        <BottomRightArrow className="bottom-right" />
        <div style={{ color: "#02519d", fontWeight: "bold" }}>
          <h5 className="font-weight-bold font-weight-bold">Experience more</h5>
        </div>
        <div>
          <p>
            HINT: Click on moving elements found throughout the city to enhance
            your experience! <br /> <br/>
            Each time you click on a bicyclist, we'll increase our overall
            donation to support community health centers.
            <img
              src={bicycle}
              alt=""
              className="img-fluid"
              style={{ 
                width: "2.2rem", 
                position: 'absolute', 
                top: '17rem',
                right: '19.5rem',
              }}
            />
          </p>
        </div>
      </div>
    ),
    style: {
      backgroundColor: "#fff",
      paddingBottom: '3rem'
    },
    position: "top",
    stepInteraction: false,
  },
];