import styled from "styled-components";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";

export const LeftArrow = styled(FaCaretLeft)`
  font-size: 2rem;
  fill: #03589d;
  cursor: pointer;

  &.disabled {
    fill: #d9d9d9;
  }
`;

export const RightArrow = styled(FaCaretRight)`
  font-size: 2rem;
  fill: #03589d;
  cursor: pointer;

  &.disabled {
    fill: #d9d9d9;
  }
`;
