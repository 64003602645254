import React, {useEffect, useRef} from 'react'
import { gsap } from "gsap"
import './doctor.css'

const Doctor = () => {
    let top_doctorRef = null
    let top_eye_outlineRef = null 
    let div_gearsRef = null
    let robot_moveRef = null
    let penRef = null
    let top_gear_background = null
    let top_global = null
    let top_gear = null
    let top_small_gear = null
    let top_clipboard = null
    let top_robot_arm = null
    let doctorTimeLine = useRef(gsap.timeline({ delay: .4, force3D: true}));


    useEffect(() => {

        gsap.set('.checkmark', {
            y: 800,
            rotate: 20,
            opacity: 0,
            scale: .5,
            transformOrigin: 'center center',
           force3D: true,
         });

         gsap.fromTo(top_eye_outlineRef, {y: -5}, {y: 0, duration: 1.3, ease: "sine.inOut", repeat: -1, yoyo: true})
         gsap.fromTo(div_gearsRef, {x: 5}, {x: 0, duration: 1.3, ease: "sine.inOut", repeat: -1, yoyo: true})
         gsap.to(robot_moveRef, {rotate: -10, duration: 3,   ease: "back.out(1.5)", transformOrigin: 'center center', repeat: -1, yoyo: true} )
         gsap.to(penRef,  {rotate: -8, duration: 2,  ease: "elastic.out(1, 0.5)", transformOrigin: 'center center', repeat: -1, yoyo: true})
         

         doctorTimeLine.current
         .fromTo(top_doctorRef, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
         .fromTo(top_gear_background, {transformOrigin: 'center center', scale: 0, opacity: 0}, {scale: 1, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
         .fromTo(div_gearsRef, {xPercent: -100, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
         .fromTo(top_eye_outlineRef, {xPercent: 100, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
         .fromTo(top_small_gear, {yPercent: -100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
         .fromTo(top_gear, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
         .fromTo(top_clipboard, {xPercent: 100, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
         .fromTo(top_global, {yPercent: -100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
         .fromTo(top_robot_arm, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
         .to('.checkmark', { duration: 4, scale: 1, rotate: 0, opacity: 1, y: 0, stagger: .5, ease: "elastic.out(1, 0.5)", force3D: true}, "-=40%")

        //  return () =>  {
        //      const killAnimation = doctorTimeLine.current
        //      doctorTimeLine.kill()
        //   }
        

      }, []);

      useEffect(() => {

        let tweenProperty = (element, prop, min, max, direction) => {
    
            let randomDur = gsap.utils.random(.5, 1, .05, true);
    
            gsap.to(element, {
                [prop]: gsap.utils.random(min, max),
                duration: randomDur(),
                ease: "Sine.easeInOut",
                onComplete: tweenProperty,
                onCompleteParams: [element, prop, min, max, direction * -1]
            });
        }
    
        gsap.utils.toArray(top_gear_background).forEach(element => {
            tweenProperty(element, "x", 0, 10, 1)
            tweenProperty(element, "y", 10, 20, -1)
          });
      }, []);
    return(
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 853.45 496.76" className="enterprise-inventory-doctor">
        <defs/>
        <path id="div_gears" ref={(e) => div_gearsRef = e} d="M112.37 97.84a46.39 46.39 0 11-54.88 35.94 46.4 46.4 0 0154.88-35.94zm27.71-50a102.25 102.25 0 00-33.14-6.91l-8.57 19.6A82.94 82.94 0 0068.24 68L51.49 54.69a102.57 102.57 0 00-26.06 21.6l10 18.93a83 83 0 00-12.94 28.22l-20.88 4.79a102.51 102.51 0 00.63 33.84l21 4a82.84 82.84 0 0014 27.71L28 213.07a102.32 102.32 0 0026.85 20.61l16.24-13.94a82.84 82.84 0 0030.39 6.34l9.31 19.26a102.28 102.28 0 0032.85-8.15l-.78-21.38A82.87 82.87 0 00166.77 196l20.86 4.74A102.7 102.7 0 00201.75 170l-17.21-12.73a82.68 82.68 0 00-.58-31l16.71-13.36a102.28 102.28 0 00-15.26-30.22l-20.67 5.52a82.94 82.94 0 00-24.63-18.9z" className="cls-1" data-name="div gears"/>
        <path id="top_gear_background" ref={(e) => top_gear_background = e} fill="#d0ecf3" d="M373.11 42.67a226.83 226.83 0 00-55.36-18.51l-21.59 40.38A183.82 183.82 0 00242.61 66l-23.69-39.23a226.68 226.68 0 00-54.31 21.41L174 93a183.61 183.61 0 00-40.1 35.5l-43.32-14.79A226.79 226.79 0 0062.74 165l36 28.28a183.16 183.16 0 00-7.91 53L48.15 262.8A226.65 226.65 0 0059.8 320l45.77-1.49a183.2 183.2 0 0028 45.66l-22.09 40.12a226.05 226.05 0 0045.7 36.32l34.1-30.55a183.75 183.75 0 0050.79 17l8.87 44.94a226.64 226.64 0 0058.35-1.54l6.49-45.33a183.33 183.33 0 0049.83-19.64l35.66 28.71A226.45 226.45 0 00445 395.47l-24.17-38.89a183.67 183.67 0 0025.55-47.08l45.78-.93a226.51 226.51 0 008.61-57.73l-43.52-14.26a183 183 0 00-10.69-52.48L481 154a226.31 226.31 0 00-30.51-49.77L408 121.25a183 183 0 00-41.92-33.33z" data-name="top gear background"/>
        <path id="top_gear" ref={(e) => top_gear = e} d="M518.79 99.78a62 62 0 11-73.37 48 62 62 0 0173.37-48zm-6.85 32.81a28.49 28.49 0 11-33.7 22.08 28.51 28.51 0 0133.7-22.08zm33.63-85.51a120 120 0 00-30.27-6.31l-8.87 22.58a97.19 97.19 0 00-28.09 4.06l-14.89-19.16a119.87 119.87 0 00-27.25 14.63l7.72 23A97.17 97.17 0 00425 107l-23.72-5.1a120.08 120.08 0 00-11.48 28.72l20.7 12.66a97 97 0 00-.88 28.36L388.19 183a120.13 120.13 0 009.67 29.38l24-3.61a97.18 97.18 0 0017.56 22.29l-9.14 22.48a120.43 120.43 0 0026.29 16.29l16.06-18.19a97 97 0 0027.78 5.79l7.44 23.09a120 120 0 0030.61-4.42l.61-24.26a97.14 97.14 0 0025-13.41l20.54 12.9a120.22 120.22 0 0020.61-23.06l-15.13-19A96.88 96.88 0 00600.62 183l24-3.32a120.29 120.29 0 001-30.91l-23.78-4.81A96.81 96.81 0 00593 117l16.28-18a120.58 120.58 0 00-19.13-24.3L568.8 86.27a97.27 97.27 0 00-24.13-14.94z" className="cls-3" data-name="top gear"/>
        <path id="top_small_gear" ref={(e) => top_small_gear = e} d="M366.16 235.4a30.28 30.28 0 11-35.82 23.46 30.29 30.29 0 0135.82-23.46zm18.09-32.63a67.1 67.1 0 00-21.63-4.51l-5.6 12.8a53.78 53.78 0 00-19.66 4.88l-10.93-8.7a66.73 66.73 0 00-17 14.1l6.52 12.35a54.15 54.15 0 00-8.45 18.42l-13.61 3.13a66.86 66.86 0 00.41 22.09l13.7 2.61a54.22 54.22 0 009.13 18.06l-6 12.59a66.77 66.77 0 0017.53 13.45l10.6-9.1a54 54 0 0019.83 4.14l6.08 12.58a67.06 67.06 0 0021.44-5.32l-.51-14a54.16 54.16 0 0015.6-12.93l13.62 3.09a66.56 66.56 0 009.21-20.08l-11.22-8.31a54.09 54.09 0 00-.38-20.25l10.9-8.73a66.82 66.82 0 00-10-19.72l-13.5 3.6a54 54 0 00-16.07-12.33z" className="cls-3" data-name="top small gear"/>
        <g id="top_clipboard" data-name="top clipboard" ref={(e) => top_clipboard = e}>
            <rect width="204.02" height="253.07" x="581.99" y="207.4" className="cls-4" rx="21.13"/>
            <path d="M735.46 178H715v-.33a29 29 0 10-58.08 0v.33h-22.4a9.36 9.36 0 00-9.36 9.36v39.15a9.36 9.36 0 009.36 9.36h100.94a9.36 9.36 0 009.36-9.36v-39.17a9.36 9.36 0 00-9.36-9.34zM686 165.88a11.77 11.77 0 0111.77 11.77v.33a11.75 11.75 0 01-23.5 0v-.33A11.77 11.77 0 01686 165.88z" className="cls-5"/>
            <rect width="47.08" height="43.16" x="612.73" y="254.81" className="cls-6" rx="5.53"/>
            <path d="M621.37 273.74l8.75-10.54 9.88 16.51c20-14.54 39.31-21.69 55-28.6l.61 4.33c-20.7 11-37 19.46-56.06 35.48z" className="cls-7 checkmark"/>
            <rect width="47.08" height="43.16" x="612.73" y="323.96" className="cls-6" rx="5.53"/>
            <path d="M621.37 342.89l8.75-10.54 9.88 16.51c20-14.54 39.31-21.68 55-28.6l.61 4.33c-20.7 11-37 19.47-56.06 35.48z" className="cls-7 checkmark"/>
            <rect width="47.08" height="43.16" x="612.73" y="393.12" className="cls-6" rx="5.53"/>
            <path d="M621.37 412l8.75-10.54L640 418c20-14.54 39.31-21.68 55-28.6l.61 4.33c-20.7 11-37 19.47-56.06 35.48z" className="cls-7 checkmark"/>
            <circle cx="777.17" cy="335.55" r="75.53" className="cls-5"/>
            <path fill="#e1212b" d="M816.39 317.1h-18.74a2 2 0 01-2-2v-18.78a2 2 0 00-2-2h-32.9a2 2 0 00-2 2v18.75a2 2 0 01-2 2h-18.81a2 2 0 00-2 2V352a2 2 0 002 2h18.74a2 2 0 012 2v18.75a2 2 0 002 2h32.83a2 2 0 002-2V356a2 2 0 012-2h18.74a2 2 0 002-2v-32.87a2 2 0 00-1.86-2.03z"/>
        </g>
        <g id="top_global"  ref={(e) => top_global = e} data-name="top global">
            <ellipse cx="321.09" cy="74.77" className="cls-9" rx="18.3" ry="35.57"/>
            <path d="M321.09 39.2v71.14M285.53 74.77h71.13M290.51 56.76h61.27" className="cls-9"/>
            <path fill="none" stroke="#377bb1" stroke-miterlimit="10" stroke-width="1.5" d="M290.51 92.71h61.27"/>
            <path d="M321.09 112.2a37.43 37.43 0 1137.43-37.43 37.48 37.48 0 01-37.43 37.43zm0-71.13a33.7 33.7 0 1033.71 33.7 33.73 33.73 0 00-33.71-33.7z" className="cls-11"/>
            <path d="M335.3 14.69L321.91 1.3a1.86 1.86 0 00-2.63 2.63l9.62 9.62a61.77 61.77 0 00-69.55 61.22 1.86 1.86 0 001.86 1.86 1.86 1.86 0 001.86-1.86 58.06 58.06 0 0166.83-57.33l-10.62 10.64a1.85 1.85 0 000 2.63 1.85 1.85 0 002.63 0l13.39-13.39a1.85 1.85 0 000-2.63zM381 72a1.87 1.87 0 00-1.84 1.89 57.88 57.88 0 01-66.46 58.27l10.86-10.86a1.86 1.86 0 10-2.64-2.63L307.5 132a2 2 0 00-.22.27s0 .08-.07.13-.07.12-.1.19a1.55 1.55 0 00-.07.23v.1a2.48 2.48 0 000 .27v.09a1.9 1.9 0 000 .25 1.94 1.94 0 00.07.33s0 .1.05.15a2.11 2.11 0 00.16.3v.06a2 2 0 00.18.23l13.39 13.4a1.87 1.87 0 002.64-2.64l-9.34-9.33a62.72 62.72 0 006.95.41 61.83 61.83 0 0061.72-62.7A1.86 1.86 0 00381 72z" className="cls-11"/>
        </g>
        <g id="top_eye_outline" data-name="top eye outline" ref={(e) => top_eye_outlineRef = e}>
            <circle cx="714.65" cy="86.69" r="16.93" className="cls-1"/>
            <circle cx="714.65" cy="86.69" r="45.63" className="cls-1"/>
            <ellipse cx="714.65" cy="86.69" className="cls-1" rx="83.42" ry="42.12"/>
        </g>
        <g id="top_robot_arm" data-name="top robot arm"  ref={(e) => top_robot_arm = e}>
            <path d="M494.42 457.35l26.88 9.03 30.42-116.4-26.88-9.04-30.42 116.41z" className="cls-12"/>
            <rect width="73.87" height="17.44" x="474.68" y="451.37" className="cls-12" rx="3.26" transform="rotate(-180 511.615 460.095)"/>
            <g ref={(e) => robot_moveRef = e }>
                <path d="M460.27 331.55l3.05 29.65 89.1-13.41-3.04-29.65-89.11 13.41z" className="cls-12"/>
                <path d="M391.85 364.28c1.27-3.13 4.53-4.53 7.28-3.12l15.31 7.84a3 3 0 003.38-.45l24.65-21.27 6.58 9.87-29.81 25.73a3 3 0 01-3.37.45l-21.34-10.83c-2.76-1.4-3.96-5.08-2.68-8.22z" className="cls-13"/>
                <path d="M389.9 347.87a5 5 0 007.72.72l13-12.37a3 3 0 013.37-.63l28.5 12.75 4.15-11.58-34.48-15.43a3 3 0 00-3.35.62l-18.16 17.15a6.81 6.81 0 00-.75 8.77z" className="cls-13"/>
                <path d="M445.59 375.35l18.33-2.77a3.37 3.37 0 002.61-3.8l-4.7-44.13a3 3 0 00-3.32-2.9l-18.33 2.76a3.37 3.37 0 00-2.6 3.8l4.69 44.14a3 3 0 003.32 2.9z" className="cls-12"/>
            </g>
            <ellipse cx="541.61" cy="332.35" className="cls-13" rx="25.33" ry="28.73"/>
            <ellipse cx="541.61" cy="332.35" className="cls-12" rx="10.51" ry="11.92"/>
        </g>
        <g id="top_doctor" data-name="top doctor" ref={(e) => top_doctorRef = e}>
            <path d="M148.63 471.07s-4.49 18.66 1.09 21.71 40.17 4.54 51.36 2.63c0 0 3.76-6-6.61-10.63s-22.39-5.27-23.79-12.85z" className="cls-14"/>
            <path d="M201.17 470.69s-4.71 18.37.87 21.41 40.17 4.55 51.37 2.64c0 0 3.75-6-6.62-10.64s-22.39-5.27-23.79-12.85z" className="cls-14"/>
            <path d="M146.33 364.53L138.9 475s6.3 6.23 24.59 5.91c16.76-.29 19-7.68 19-7.68l2.11-106.88zM193.46 363.44l2.73 110.88s7.13 4.56 23.61 4c16-.58 15.25-6.2 15.25-6.2l-5.63-111.37z" className="cls-15"/>
            <path d="M167.84 143s-30.27 24.5-34.46 28.67-5.65 66.76-4.51 76.89 15 11 18.28 11.26c0 0-8.91 93.79-11.71 105.54 0 0 39.75 11.83 98.75-3.29 0 0-6.43-95.72-7.69-108.75l9.71-38.21s-4.37-44.9-7.13-50.24-25.75-16.4-31.34-19.81-14.82-6.64-29.9-2.06z" className="cls-16"/>
            <path d="M143.94 270.33s18.72 4.42 28.54 4.57l-.54 39s-19.09 1.2-31-3.77zM230.01 271.84l-13.27 2.17 2.79 38.06 12.98-2.44-2.5-37.79zM144.66 230.14c5.84.27 28.21 7.35 28.21 7.35a32.62 32.62 0 00-5 14.24c-.76 8.21 4 12.56 4 12.56s-27.53-4.74-31.31-5.45" className="cls-16"/>
            <path d="M156.15 186.23l-.08 46.71 2 1s1.28-40.57-1.92-47.71zM254.51 240.3s8.87 3.73 2.6 17.07-19 3.23-19.81 2.44 7.07-7.37 7.07-7.37z" className="cls-15"/>
            <path d="M244.28 263.62l-16.9.14 2.26-4.72 10.1.4 4.54 4.18z" className="cls-16"/>
            <path fill="none" stroke="#75c0d5" stroke-miterlimit="10" stroke-width="1.5" d="M195.69 188.71s1.23 144.18-.57 180.25"/>
            <g ref={(e) => penRef = e}> 
            <path d="M175.72 207.06c-1.64.34 8.27 15.66 12.57 18.58l3-2.67s-13.41-16.36-15.57-15.91z" className="cls-4"  />
            </g>
            <path d="M173.39 236.92c-3.95.16-10 28.08.17 27.67s5-27.88-.17-27.67z" className="cls-15"/>
            <path d="M230.17 209.57S199.26 245 181.6 257.72l40.81 11.43C240.07 256.43 271 221 271 221z" className="cls-12"/>
            <path d="M172.87 237.49l14.22-12.31s3.29-3 6.85-2.46 10.89 2.83 11.48 3.9a5.39 5.39 0 01.38 2.71s2.26 2.09 1.37 3.49a8.61 8.61 0 00-1.13 2.36l-18 17.1s-17.45 3.16-19.91 3c.01-.01-.33-11.72 4.74-17.79zM232.71 260.82s15.85-2.55 19-4.32c0 0 4.61-7.14 4-13-.24-2.18-5.47-7.14-8.51-8.1s-15.2-2.23-17.2-1.33-2.32 3.51.16 4.09c0 0-2.22 2.4.3 4.07 0 0-1.62 3.75 2.11 5 0 0-1.64 3.07 3.18 4.24l4.45 2.27zM189.63 113s2.35 23.09 20.18 24.63 25.81-41.67 25.81-41.67-12.79-.7-18.31-5.43a140.78 140.78 0 00-14.76-10.38l-10.09 4.5-9.68 9.22 1.33 14.4z" className="cls-18"/>
            <path d="M184.69 108.7s-2.93 17.26-9 28l5.94 18.15a15 15 0 0017.15.08l2.13-18-6.52-19.74-1.73-10.57z" className="cls-18"/>
            <path fill="#6d655c" stroke="#929495" stroke-linecap="round" stroke-linejoin="round" d="M202.21 84s8.6 11.44 21.14 13.28 18.32-.73 17.61-8.59-9.27-23.34-32-23.07-30.45 16-30 27.09.32 20.84-.53 23.74 2.93 4.07 2.93 4.07l4-9.36 3-2.92S182 97 186.31 94s8 7 8 7l4.84-1-.6-10.57z"/>
            <path d="M179.84 127.46s-19.12 6-11.2 26.36l9.71 4-6.75 8.46 23.89 24.11-19.63-53.46z" className="cls-16"/>
            <path d="M202.9 138s7 12.53 8.22 21.21l-8.12 2.21 5.19 7.15-12.74 21.78 4.28-55.52z" className="cls-16"/>
        </g>
</svg>

    )
}

export default Doctor