import React from "react";
import AccessRequest from "./AccessRequest";

const AccessRequests = ({ modalId, closeModal,  hasAccess, baseUrl, onUserSubmit}) => {
    return (
        requests.map( request => {
            return (
                <AccessRequest
                    key={request.id} 
                    id={request.id}
                    title={request.title}
                    directions={request.directions}
                    formId={request.formId}
                    munchkinId={request.munchkinId}
                    linkedinID={request.linkedinID}
                    ThankyouModalID={request.thankyouModalID}
                    modalId={modalId} 
                    closeModal={closeModal}  
                    hasAccess = {hasAccess} 
                    baseUrl={baseUrl}
                    onUserSubmit={onUserSubmit}
                />
            )
        })
    )
}

export default AccessRequests

const requests = [
    {
        /* START modal for Video access / Marketo */
        id:"requestVideoAccess",
        title:"Unlock and watch the videos now",
        directions:"Enter your information below to unlock and watch the on-demand sessions",
        formId:"15988",
        munchkinId:"565-YXD-236",
        linkedinID:6528793,
        thankyouModalID:"unlockVideoAccess"
    },
    {
        /* START modal for Request access / Marketo if unable to attend */
        id:"cantAttendRequestToView",
        title:"Request access to ASHP21 sessions",
        directions:"Enter your information below to request access to our ASHP21 educational sessions",
        formId:"16554",
        munchkinId:"565-YXD-236",
        linkedinID:6528793,
        thankyouModalID:"submissionForFuturePresentations"
    },
]


