import React from 'react'
import styled from "styled-components";
import { HashLink as Link } from 'react-router-hash-link';

const Button = styled(Link)`
  background: #02519d;
  color: white;
  padding: .5rem 2rem;
  font-family: FSAlbertArabicBold, Arial, Helvetica, sans-serif;
  text-transform: ${props => props.upper ? 'uppercase' : 'initial'};
  &:hover{
      color: white;
      text-decoration: none;
  }
`;

const ButtonLink = (props) => {
    return(
        <Button {...props} className="d-inline-flex align-items-center">
            {props.children}
        </Button>
    )
}

export default ButtonLink