export const DataSignOne = {
  title:
    "Did you know medical errors are the third leading cause of death in the US?<sup>1</sup>",
  img: require("../../../images/DYKModals/dyk-meds@2x.png"),
  alt: "Medications",
  topLine: "Globally, there are an estimated",
  statementNumber: "100M",
  middleLine:
    "medication errors each year<sup>2</sup>, costing US health systems ",
  bottomLine: "$21 billion.",
  BottomDescription:
    " to learn how your health system can reduce errors and improve patient safety.",
  linkText: "Visit the Solutions Marketplace",
  link: "/solutions-marketplace",
  citations: [
    "<sup>1</sup>C—0653. Makary Martin A, Michael, D. Medical error—the third leading cause of death in the US. BMJ 2016; 353:i2139. doi: 10.1136/bmj.i2139.",
    "<sup>2</sup>C—0662. Ebel, T., George, K., Larsen, E., et al. Strength in unity: The promise of global standards in healthcare. McKinsey & Company. October 2012.",
    "<sup>3</sup>C—0670, C—0661, C—0671. Lahue B, Pyenson B, Iwasaki K, et al. National Burden of Preventable Adverse Drug Events Associated with Inpatient Injectable Medications: Healthcare and Medical Professional Liability Costs. Am Health Drug Benefits. 2012;5(7):1-10.",
  ],
};

export const DataSignTwo = {
  title:
    "Did you know that up to 38% of a nurse's time is spent on non-value-added acitvities?<sup>1</sup>",
  img: require("../../../images/DYKModals/dyk-doctor-clock@2x.png"),
  alt: "Doctor with clock",
  topLine: "Annually this can cost",
  secondLine: "health systems more than",
  statementNumber: "$1M",
  bottomLine: "for med-surg nursing units<sup>2</sup>",
  BottomDescription:
    "Learn how to better support clinical staff efficiency in the ",
  linkText: "Solutions Marketplace by clicking here.",
  link: "/solutions-marketplace",
  citations: [
    "<sup>1</sup> C—0679. Allen, Stephanie PhD, RN. (2015.) The Connection Between Nurses Working at Top of Licensure and Patient Care. Infor Healthcare.",
    "<sup>2</sup>C—0648. Storfjell, J. L., Ohlson, S., Omoike, O., Fitzpatrick, T., & Wetasin, K. (2009, January). Non-value-added time: the million dollar nursing opportunity. J Nurs Adm, 39(1), 38-45.",
  ],
};

export const DataSignThree = {
  title:
    "Did you know that US hospitals could save $12.1M in medical expenses - the equivalent of 165 RN salaries - through improvements in supply chain efficiency?<sup>1</sup>",
  img: require("../../../images/DYKModals/nurse.png"),
  alt: "Nurse",
  topLine: "Hospitals could save",
  statementNumber: "$12.1 M",
  statementBottomLine: "with supply chain improvements.",
  bottomLine: "That is equivalent to: ",
  BottomDescription:
    " Learn how your health system can connect critical points of inventory management - from the loading dock to the bedside - to help reduce costs and improve efficiencies. ",
  linkText: "Visit the Solutions Marketplace by clicking here.",
  link: "/solutions-marketplace",
  citations: [
    "<sup>1</sup>REF-13784, Annual Hospital Supply Chain Savings Opportunity Reaches $25.7 Billion. 2019 Supply Chain Analysis. Navigant, A Guidehouse Company. 2019.",
  ],
};