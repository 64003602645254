import React from 'react'
import ReactHtmlParser from 'react-html-parser';

const TopDescription = ({ description }) => {

    return (
        <div className="col-lg-5 order-2 order-lg-1">
        <div>
          <h3 className="font-weight-semi-bold grey-title">
            {ReactHtmlParser(description)}
          </h3>
        </div>
      </div>
    )

}

export default TopDescription