import React from "react";
import useMarketo from "./useMarketo";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-203934706-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function Form(props) {
  const { formId } = props; 

  // Original line of code
  // function Form(props) {
  //   const { baseUrl, munchkinId, formId, onUserSubmit } = props;

  useMarketo(props);

  return <form id={`mktoForm_${formId}`} />;
}

export default Form
