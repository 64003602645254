import React, {useLayoutEffect, useRef} from 'react'
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import ReactHtmlParser from 'react-html-parser';
import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const InformationCard = ({ img, imgAlt, title, description, contentLabel, btnText, btnDownload, pdfsArr }) => {

  // useLayoutEffect(() => {
  //   gsap.utils.toArray(".infoCard").forEach(function(elem) {
  //     hide(elem); 

  //     if(elem.classList.contains("fadeInRight")) {
  //       gsap.set(elem, {xPercent: -100} )
  //     } else if  (elem.classList.contains("fadeInLeft")) {
  //       gsap.set(elem, {xPercent: 100} )
  //     } 
  //     ScrollTrigger.create({
  //       trigger: elem,
  //       onEnter: function() { animateFrom(elem) }
  //     });
  //   });


  //   function hide(elem) {
  //     gsap.set(elem, {autoAlpha: 0, opacity: 0});
  //   }
    

  // function animateFrom(elem) {
  //   gsap.to(elem, {
  //     delay: .2,
  //     xPercent: 0,
  //     opacity: 1,
  //     autoAlpha: 1,
  //     duration: 2,
  //     ease: "expo", 
  //     overwrite: "auto"
  //   });
  // }

  // }, []);

  

    return (
      <>
        <div className="row infoCard px-md-5">
        <Slide left>
          <div className="col-md-6 pr-md-4 pb-5 pb-md-0">
              <img
                className="img-fluid fadeInRight"
                src={img}
                alt={imgAlt}
              />
          </div>
        </Slide>
          <div className="col-md-6 pl-md-4">
            <Slide right>
              <h3 className="font-weight-bold grey-title fadeInLeft">{title}</h3>
            </Slide>
            <Fade>
            <div className="hr-line"></div>
            <p className="mt-4 mb-5 grey-title lead description">
              {ReactHtmlParser(description)}
            </p>
            </Fade>


            {!pdfsArr && 
            <Fade>
              <h5 className="font-weight-semi-bold grey-title mt-4 pt-3">
                {contentLabel}
              </h5>
            </Fade>
            }
            {btnDownload === undefined ? 
                null

                :
            <Fade>
              <div style={{marginTop: '.875rem' }}>
                <a className="btn text-white rounded-0 mb-3" href={btnDownload} style={{ backgroundColor: "#004593", fontWeight: "600" }} target="_blank">
                  {btnText}</a>
              </div>
            </Fade>
          }

          {pdfsArr && 
            pdfsArr.map( pdf => {
              return (
                <Fade>
                  <h5 className="font-weight-semi-bold grey-title mt-4 pt-3">
                    {pdf.title}
                  </h5>
                  <div style={{marginTop: '.875rem' }}>
                    <a className="btn text-white rounded-0 mb-3" href={pdf.download} style={{ backgroundColor: "#004593", fontWeight: "600" }} target="_blank">
                      Download now</a>
                  </div>
                </Fade>
              )
            })
          }
          
          </div>
          </div>
        </>
    )

}

export default InformationCard