import React from "react";

const VideoCard = ({img, topTitle, bottomTitle, videoModalID, gainVideoAccess, openModalHandler}) => {
    return (
        <div className="col-md-6 my-5 text-left text-lg-center">
        <div onClick={() => gainVideoAccess ? openModalHandler(videoModalID) : openModalHandler("requestVideoAccess")} style={{ cursor: "pointer" }}>
          <img className="img-fluid" src={img} />
          <div className="mt-3">
            <h5 className="font-weight-semi-bold grey-title">
              {topTitle}
              <span className="d-lg-block font-weight-semi-bold grey-title">
                {bottomTitle}
              </span>
            </h5>
          </div>
        </div>
      </div>
    )
}

export default VideoCard