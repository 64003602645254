import React, {useEffect, useRef} from 'react'
import { gsap } from "gsap"
import './DoctorSVG.css'

const DoctorSVG = () => {
    let top_doctorRef = null
    let top_vialRef = null
    let top_pill_bottleRef = null
    let top_pill_bottle_outlineRef = null
    let top_cloud_outlineRef = null
    let top_cloud_right = null
    let top_cloud_left = null
    let top_file_folders = null
    let top_servers = null
    let doctorTimeLine = useRef(gsap.timeline({ delay: .4, force3D: true}));
    let serverLights = useRef(gsap.timeline({ repeat: -1, yoyo: true}));

    useEffect(() => {
    
        doctorTimeLine.current
            .fromTo(top_doctorRef, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
            .fromTo(top_cloud_right, {transformOrigin: 'center center', scale: 0, opacity: 0}, {scale: 1, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
            .fromTo(top_cloud_left, {transformOrigin: 'center center', scale: 0, opacity: 0}, {scale: 1, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
            .fromTo(top_pill_bottle_outlineRef, {xPercent: -100, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
            .fromTo(top_vialRef, {yPercent: -100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
            .fromTo(top_pill_bottleRef, {yPercent: -100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
            .fromTo( top_cloud_outlineRef, {xPercent: 100, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
            .fromTo(top_file_folders, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
            .fromTo(top_servers, {yPercent: 100, opacity: 0}, {yPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)

        serverLights.current
            .to('.green', .6, {
                fill: "#3cff12",
                stagger: .3,
                delay: 0.5
            })
            .to('.red', .5, {
                fill: "#ff2e34",
                stagger: -.5,
                delay: 0.5
            })

            // return () =>  {
            //     let killDoctorTL = doctorTimeLine.current
            //     let killServerTL = serverLights.current
            //     killDoctorTL.kill()
            //     killServerTL.kill()
            //  }

      }, []);


      useEffect(() => {

        let tweenProperty = (element, prop, min, max, direction) => {
    
            let randomDur = gsap.utils.random(.5, 1, .05, true);
    
            gsap.to(element, {
                [prop]: gsap.utils.random(min, max),
                duration: randomDur(),
                ease: "Sine.easeInOut",
                onComplete: tweenProperty,
                onCompleteParams: [element, prop, min, max, direction * -1]
            });
        }
    
        gsap.utils.toArray('.doctorAnalytics > path').forEach(element => {
            tweenProperty(element, "x", 0, 10, 1)
            tweenProperty(element, "y", 10, 20, -1)
          });
      }, []);

    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 806.47 423.61" className="doctorAnalytics">
            <path id="top_cloud_left" ref={(e) => (top_cloud_left = e)} d="M525.11 347.16a67.52 67.52 0 00-87.75-57.35 75.88 75.88 0 00-127.62-44.89 4.19 4.19 0 01-7.1-3v-.12A107.48 107.48 0 0088.3 230.22 95.89 95.89 0 00.15 331.16c2.78 51.14 46.44 90.54 97.66 90.54h360.7a67.55 67.55 0 0066.6-74.54z" className="cls-1" data-name="top cloud left"/>
            <path id="top_cloud_right" ref={(e) => (top_cloud_right = e)} d="M780 248.28a67.54 67.54 0 00-87.75-57.35 75.87 75.87 0 00-127.62-44.88 4.19 4.19 0 01-7.1-3v-.12a107.48 107.48 0 00-214.34-11.56A95.89 95.89 0 00255 232.28c2.78 51.14 46.44 90.54 97.66 90.54h360.7A67.55 67.55 0 00780 248.28z" className="cls-1" data-name="top cloud right"/>
            <g>
            <path id="top_cloud_outline" ref={(e) => (top_cloud_outlineRef = e)} d="M805.77 123.71A39.08 39.08 0 00755 90.52a43.92 43.92 0 00-73.87-26 2.43 2.43 0 01-4.13-1.74v-.07A62.22 62.22 0 00552.93 56a55.5 55.5 0 00-51 58.42c1.62 29.61 26.89 52.41 56.53 52.41h208.76a39.1 39.1 0 0038.55-43.12z" className="cls-2" data-name="top cloud outline"/>
            </g>
            <g id="top_pill_bottle_outline" data-name="top pill bottle outline" ref={(e) => (top_pill_bottle_outlineRef = e)}>
                <rect width="75.39" height="40.89" x="65.77" y="114.55" className="cls-2" rx="4.14"/>
                <rect width="110.7" height="161.23" x="48.12" y="164.15" className="cls-2" rx="19.95"/>
                <path d="M131.11 230.16H117.9a1.43 1.43 0 01-1.43-1.43v-13.21a1.43 1.43 0 00-1.44-1.43H91.89a1.43 1.43 0 00-1.43 1.43v13.21a1.43 1.43 0 01-1.46 1.43H75.82a1.44 1.44 0 00-1.44 1.44v23.14a1.43 1.43 0 001.44 1.43H89a1.43 1.43 0 011.43 1.44v13.21a1.43 1.43 0 001.43 1.43H115a1.43 1.43 0 001.44-1.43v-13.21a1.43 1.43 0 011.43-1.44h13.21a1.43 1.43 0 001.43-1.43V231.6a1.44 1.44 0 00-1.4-1.44z" className="cls-2"/>
                <path d="M48.12 198.7h110.7v94.84H48.12zM132.7 164.15a26.64 26.64 0 013.48.26v-9H70.99v9a26.64 26.64 0 013.48-.26z" className="cls-2"/>
            </g>
            <g id="top_file_folders" ref={(e) => (top_file_folders = e)} data-name="top file folders" >
                <path d="M734.89 255.24a4.19 4.19 0 00-4.18-4.19H677.8l-2.49-7.29a4.58 4.58 0 00-4.34-3.11h-18a4.18 4.18 0 00-4.18 4.18v20.06h86.14z" className="cls-3"/>
                <path d="M648.75 264.89v35.82a4.19 4.19 0 004.18 4.19h77.78a4.19 4.19 0 004.18-4.19v-35.82z" className="cls-4"/>
                <circle cx="711.84" cy="291.48" r="4.49" className="cls-5"/>
                <circle cx="724.86" cy="291.48" r="4.49" className="cls-5"/>
                <path d="M684.64 341.38a4.18 4.18 0 00-4.18-4.18h-52.91l-2.49-7.3a4.59 4.59 0 00-4.35-3.1h-18a4.19 4.19 0 00-4.19 4.18v20h86.15z" className="cls-3"/>
                <path d="M598.49 351v35.83a4.19 4.19 0 004.19 4.18h77.78a4.18 4.18 0 004.18-4.18V351z" className="cls-4"/>
                <circle cx="661.59" cy="377.63" r="4.49" className="cls-5"/>
                <circle cx="674.61" cy="377.63" r="4.49" className="cls-5"/>
                <path d="M792.7 341.38a4.18 4.18 0 00-4.18-4.18h-52.91l-2.49-7.3a4.59 4.59 0 00-4.35-3.1h-18a4.18 4.18 0 00-4.18 4.18v20h86.11z" className="cls-3"/>
                <path d="M706.56 351v35.83a4.18 4.18 0 004.18 4.18h77.78a4.18 4.18 0 004.18-4.18V351z" className="cls-4"/>
                <circle cx="769.65" cy="377.63" r="4.49" className="cls-5"/>
                <circle cx="782.67" cy="377.63" r="4.49" className="cls-5"/>
                <path d="M643.08 337.39v-14.14a1.92 1.92 0 011.92-1.92h104.6a1.92 1.92 0 011.92 1.92v14.33M692.2 321.18v-16.1" className="cls-6"/>
            </g>
            <g id="top_pill_bottle" data-name="top pill bottle" ref={(e) => ( top_pill_bottleRef = e)}>
                <path fill="#75c0d5" stroke="#377bb1" strokeMiterlimit="10" d="M418.06 90.06V79.22h-78.48V90A31.61 31.61 0 00312 121.36v131a31.6 31.6 0 0031.6 31.6h70.14a31.6 31.6 0 0031.6-31.6v-131a31.6 31.6 0 00-27.28-31.3zm-74.33-.3zm72.35.09h0z"/>
                <rect width="90.81" height="49.25" x="333.25" y="30.01" className="cls-8" rx="6.19"/>
                <path fill="#fff" stroke="#adafb0" strokeLinejoin="round" strokeWidth="1.5" d="M311.98 131.38h133.34v114.25H311.98z"/>
                <path fill="#e1212b" d="M412 169.27h-16a1.72 1.72 0 01-1.72-1.72v-15.92a1.73 1.73 0 00-1.73-1.72h-27.83a1.72 1.72 0 00-1.73 1.72v15.92a1.72 1.72 0 01-1.73 1.72h-15.91a1.74 1.74 0 00-1.73 1.73v27.87a1.73 1.73 0 001.73 1.73h15.91a1.72 1.72 0 011.73 1.73v15.91a1.73 1.73 0 001.73 1.73h27.87a1.74 1.74 0 001.73-1.73v-15.91a1.72 1.72 0 011.68-1.73h16a1.72 1.72 0 001.72-1.73V171a1.73 1.73 0 00-1.72-1.73z"/>
            </g>
            <g id="top_doctor" data-name="top doctor"  ref={(e) => (top_doctorRef = e)}> 
                <path d="M285.77 249.32s45.79 15.3 50.71 23.4 28.12 80.22 18.16 149.07c0 0-112.89 2.14-218.83-.13 0 0-10.71-103 17.25-154.34 3-5.49 53.45-19.35 59.3-19.35s73.41 1.35 73.41 1.35z" className="cls-11"/>
                <path fill="#75c0d5" d="M268 243.92l8.83 5.4s3.39 133.49-23.8 173.54h-14.49s-28.57-116.39-20.49-178z"/>
                <path d="M268.52 220.07s5.12 38.25-.87 53.1-40.77 12.15-47.43-3.6-3-40-3.48-58.94c0 0 10.24 6.75 26.51 8.09s25.27 1.35 25.27 1.35z" className="cls-13"/>
                <path fill="#399043" d="M170.78 368.07c0-.59-.07-1.18-.11-1.79-.72-14 .6-25.44 2.94-25.44s4.84 11.39 5.56 25.44c0 .65.06 1.29.08 1.92-2.83-.1-5.65-.2-8.47-.13z"/>
                <path d="M267.85 224.57s32.08 28.8 40.2 55.35l-13 18.9s14.55 20.25 16.91 22.5c0 0-12.14 35.1-43.74 59.4 0 0 14-95.4 1.55-135.9zM217.9 224.57s-29.11 28.8-34.49 55.35l14.92 18.9s-12.46 20.25-14.59 22.5c0 0 14.36 34.2 45.31 58.5 0 0-19.27-94.5-11-135zM163.82 379c-.22-7.44-.33-14-.33-14l36.11 2.25s.48 6.47 1 13.63c-6.96-.75-24.95-2.45-36.78-1.88z" className="cls-11"/>
                <path d="M200.57 380.85c.38 5.52.77 11.45 1 15.17.44 8.54-4.95 17.54-17.62 16.19s-18.37-7.2-19.17-13.94c-.4-3.4-.71-11.78-.93-19.29 11.8-.55 29.79 1.15 36.72 1.87z" className="cls-11"/>
                <path fill="#754d29" d="M203.59 83.09s4.29-14.88 20.19-16.49 34-2.56 39-12.43c0 0 28.63 6.81 22.8 35.49 0 0 19.68 4.6 19.95 28.47s-25.18 26.8-25.18 26.8l-4.08-29.63s-22.22 1.16-41.15 1.24c-17.6.08-21.42-4.1-21.42-4.1l2 42s.07 18.45 18.07 20.19 54.47 1.87 54.47 1.87 7 34.63-7.29 47.77c-14.81 13.58-43 17.79-67.94-.81s-17.11-51.14-17.11-51.14-18.17 1.85-22.47-13c0 0 4.79-5.84 5.79-27.79s-1.35-59.47 24.37-48.44z"/>
                <path d="M210.59 107.43s7.49 4.95 26 5.39 30-5.58 42.31 2.35l9.35 61.33s-37.84.18-45-.51-26.25.31-28-21.57c-1.78-21.08-4.66-46.99-4.66-46.99zM200.7 128.1s-11.74-.37-11.74 11 10.7 14.77 10.7 14.77a152.83 152.83 0 001.04-25.77z" className="cls-13"/>
            </g>
            <g id="top_servers" ref={(e) => (top_servers = e)} data-name="top servers" >
                <rect width="150.32" height="51.92" x="431.22" y="239.66" className="cls-8" rx="7.75"/>
                <rect width="150.32" height="51.92" x="431.22" y="305.3" className="cls-8" rx="7.75"/>
                <rect width="150.32" height="51.92" x="431.22" y="370.94" className="cls-8" rx="7.75"/>
                <path d="M444.56 357.22h122.71v13.72H444.56zM444.56 291.58h122.71v13.72H444.56z" className="cls-16"/>
                <rect width="6.68" height="56" x="468.74" y="237.62" className="cls-17" rx="2.96" transform="rotate(-90 472.075 265.615)"/>
                <path d="M453.39 262.16v6.87M462.7 262.16v6.87M472.01 262.16v6.87M481.31 262.16v6.87M490.62 262.16v6.87" className="cls-18"/>
                <rect width="6.68" height="56" x="468.74" y="303.26" className="cls-17" rx="2.96" transform="rotate(-90 472.075 331.255)"/>
                <path d="M453.39 327.8v6.87M462.7 327.8v6.87M472.01 327.8v6.87M481.31 327.8v6.87M490.62 327.8v6.87" className="cls-18"/>
                <rect width="6.68" height="56" x="468.74" y="368.9" className="cls-17" rx="2.96" transform="rotate(-90 472.075 396.895)"/>
                <path d="M453.39 393.44v6.87M462.7 393.44v6.87M472.01 393.44v6.87M481.31 393.44v6.87M490.62 393.44v6.87" className="cls-18"/>

                <g>
                    <circle cx="550.63" cy="264.38" r="4.12" className="cls-19 green"/>
                    <circle cx="550.63" cy="330.02" r="4.12" className="cls-19 green"/>
                    <circle cx="550.63" cy="395.66" r="4.12" className="cls-19 green"/>
                </g>

                <g>
                <circle cx="563.24" cy="264.38" r="4.12" className="cls-20 red"/>
                <circle cx="563.24" cy="330.02" r="4.12" className="cls-20 red"/>
                <circle cx="563.24" cy="395.66" r="4.12" className="cls-20 red"/>
                </g>
                

            </g>
            <g id="top_vial" data-name="top vial" ref={(e) => (top_vialRef = e)}>
                <path fill="#d9d9db" stroke="#b3b3b6" strokeMiterlimit="10" strokeWidth="1.5" d="M519.35 28.79h-57.46a7.78 7.78 0 00-7.75 7.75v.83a7.78 7.78 0 007.75 7.75h2.5a3.08 3.08 0 013.08 3.09v136a22.67 22.67 0 0022.61 22.6H492a22.67 22.67 0 0022.6-22.6v-136a3.09 3.09 0 013.09-3.09h1.7a7.78 7.78 0 007.75-7.75v-.83a7.78 7.78 0 00-7.79-7.75z"/>
                <path fill="#47abc6" d="M467.47 184.23a22.67 22.67 0 0022.6 22.6H492a22.67 22.67 0 0022.6-22.6v-72h-47.13z"/>
                <path d="M467.66 64.28h20.42M467.66 83.29h20.42M467.66 102.29h20.42M467.66 121.32h20.42M467.66 140.34h20.42M467.66 159.36h20.42M467.66 178.37h20.42" className="cls-23"/>
            </g>
            </svg>

        </>
    )
}

export default DoctorSVG