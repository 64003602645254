import lessonsLearned from "../../../images/archive/HIMSS22/EducationArchive/bd-speakers-image.png";
import panelists from "../../../images/archive/HIMSS22/EducationArchive/bd-panelists.png";
import maximizeEnterprise from "../../../images/archive/HIMSS22/EducationArchive/bd-speakers-text-only.png";
import vid1Img from "../../../images/ASHP21/EducationCenter/video01.jpg";
import vid2Img from "../../../images/ASHP21/EducationCenter/video02.jpg";
import vid3Img from "../../../images/ASHP21/EducationCenter/video03.jpg";

//This is for the display of the thumbnails and information, not the modals
//extended style
export const cards_Ashp21 = [
    {
        img: vid1Img,
        title: "Analytics: the key to high performance pharmacy programs",
        description: "Data derived and accumulated in hospital pharmacies carries potential for unlocking organizational value but requires a transition from metrics reporting to insightful and actionable analytics. Learn how quality pharmacy analytics correlate to high performing pharmacy programs",
        speakers:[
            {nameAndCreds:"Joe Lassiter, PharmD", jobTitle:"CEO </br> The Robertson Group (TRG)"},
            {nameAndCreds:"Jeffrey Chalmers, PharmD", jobTitle:"System Director of Pharmacy Informatics and Technology, Cleveland Clinic"},
            {nameAndCreds:"Chad Hatfield, PharmD, MHA, BCPS", jobTitle:"System Director of Pharmacy Informatics and Technology, Cleveland Clinic"},
            {nameAndCreds:"Gilbert Romero, BS Pharm, RPh, Six Sigma MBB", jobTitle:"Senior Consultant, Visante"},
            {nameAndCreds:"Moderator: David Kvancz, MS, RPh, FASHP", jobTitle:"Senior Vice President, Visante"}
    ],
        videoModalID: "analytics"
    },
    {
        img: vid2Img,
        title: "Improving the medication management system with rule-based EMR initiatives",
        description: "Medication management systems represent devices, software and processes that help enable patient care delivery. When evaluating the effectiveness of the medication management system, Truman Medical Center launched a task force to understand data, policies and opportunities for standardization to improve medication overrides and management. Hear about their experience and how you can apply these learnings to your facility.",
        speakers:[
            {nameAndCreds:"Mary E. Durham, PharmD, BCPS", jobTitle:"Director of Pharmacy <br> Truman Medical Center"},
        ],
        videoModalID: "medManagement"
    },
    {
        img: vid3Img,
        title: "Perspectives from the C-suite: unleashing the value of pharmacy within healthcare organizations",
        description: "Insightful analytics can impact what lies ahead. Join this interactive session to explore challenges and opportunities faced by healthcare C-suite executives, particularly in the wake of a pandemic. Pharmacy executives will advise on how to develop a pharmacy program that leads, facilitates and assists in achieving organizational goals and initiatives to unlock clinical, operational and financial value.",
        speakers:[
            {nameAndCreds:"Alan Yordy, MBA, FACHE", jobTitle:"President, The Infinity Group <br> Senior Partner, CEO Advisory Network"},
            {nameAndCreds:"Joel A. Hennenfent, PharmD, MBA, BCPS, FASHP", jobTitle:"Chief Pharmacy Officer & Associate Administrator for Laboratory and Imaging <br> Truman Medical Centers / University Health"},
            {nameAndCreds:"Moderator: Jim Jorgenson, MS, RPH, FASHP", jobTitle:"CEO, Visante"},
        ],
        videoModalID: "perspectives"
    }
]

export const cards_Himss21 = [
    {
        img: lessonsLearned,
        topTitle: "Effectively Managing Supply Challenges: ",
        bottomTitle: "Lessons Learned from the COVID-19 Pandemic",
        videoModalID: "supplyChallenges"
    },
    {
        img: panelists,
        topTitle: "COVID-19 Panel Discussion: Actionable Insights ",
        bottomTitle: "from the C-Suite to the Front Lines",
        videoModalID: "covidPanel"
    },
    {
        img: maximizeEnterprise,
        topTitle: "Maximizing Enterprise Medication Inventory ",
        bottomTitle: "Optimization Utilizing Analytics Software",
        videoModalID: "maximizeMedication"
    }
]
