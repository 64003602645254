import React, {useLayoutEffect, useEffect, useRef} from 'react'
import { gsap } from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './mobileDivider.css'
gsap.registerPlugin(ScrollTrigger)

const MobileDivider = () => {
  let divider_background = null
  let div_white_pill = null
  let div_eyeball = null
  let div_monitor = null

  useLayoutEffect(() => {

    const tl = gsap.timeline({
        delay: .3,
        scrollTrigger: {
            trigger: '.mobileDivider-hp',
            end: 'bottom top',
        }
    })

    tl
        .fromTo(divider_background , { opacity: 0}, { opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
        .fromTo(div_eyeball, {xPercent: -200, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
        .fromTo(div_monitor, {xPercent: 200, opacity: 0}, {xPercent: 0, opacity: 1, duration: 1, ease: "sine.inOut"}, 0)
        .fromTo(div_white_pill, {xPercent: -200, opacity: 0}, {xPercent: 0, opacity: 1, transformOrigin: 'center center', rotate: 360, duration: 1.5, ease: "sine.inOut"}, 0)

      return () =>  {
        let killAnimation = tl
        tl.kill()
     }

  }, []);

    return (
<svg xmlns="http://www.w3.org/2000/svg" id="divider" viewBox="0 0 1491.96 1267.15" className="mobileDivider-hp pb-4">
  <defs>
    <clipPath id="clipPath">
      <ellipse cx="759.22" cy="150.63" fill="none" rx="214.04" ry="108.06"/>
    </clipPath>
    <style>
      
    </style>
  </defs>
  <path fill="#d0ecf3" d="M0 0h1491.96v1267.15H0z" ref={(e) => divider_background = e} />
  <g id="div_white_pill" data-name="div white pill" ref={(e) => div_white_pill = e} >
    <path d="M840.08 989.26 721 1223.84a135.77 135.77 0 0 0 119.08-234.58ZM750.49 955.46a135.79 135.79 0 0 0-79.12 246.16l121.5-239.37a135.62 135.62 0 0 0-42.38-6.79Z" className="cls-3"/>
    <path d="m792.87 962.25-121.5 239.37a135.08 135.08 0 0 0 49.63 22.22l119.08-234.58a135.53 135.53 0 0 0-47.21-27.01Z" className="cls-3"/>
  </g>
  <g id="div_monitor" data-name="div monitor" ref={(e) => div_monitor = e} >
    <path d="M678.83 706.87h137.39v60.26H678.83zM656.17 767.61h182.71v6.75H656.17z" className="cls-4"/>
    <rect width="366.86" height="267.07" x="564.09" y="439.79" className="cls-4" rx="11.63"/>
    <rect width="334.74" height="237.27" x="580.15" y="454.7" fill="#b7cb53" stroke="#55a145" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.76" rx="5.96"/>
    <path fill="#fff" d="M832.08 532.35h-40.4a4.38 4.38 0 0 1-4.38-4.35v-40.4a4.39 4.39 0 0 0-4.39-4.39h-70.77a4.38 4.38 0 0 0-4.39 4.39V528a4.38 4.38 0 0 1-4.38 4.38H663a4.39 4.39 0 0 0-4.38 4.39v70.77a4.39 4.39 0 0 0 4.38 4.36h40.41a4.38 4.38 0 0 1 4.38 4.38v40.41a4.38 4.38 0 0 0 4.39 4.38h70.77a4.39 4.39 0 0 0 4.39-4.38v-40.41a4.38 4.38 0 0 1 4.38-4.38h40.4a4.4 4.4 0 0 0 4.39-4.39v-70.77a4.4 4.4 0 0 0-4.43-4.39Z"/>
  </g>
  <g id="div_eyeball" data-name="div eyeball" ref={(e) => div_eyeball = e} >
    <ellipse cx="759.22" cy="149.78" fill="#fff" stroke="#a9a9ab" strokeLinejoin="round" strokeWidth="3.02" rx="214.04" ry="108.06"/>
    <g clipPath="url(#clipPath)">
      <circle cx="759.22" cy="150.63" r="117.07" fill="#47abc6"/>
      <circle cx="759.22" cy="150.63" r="43.45" fill="#377bb1"/>
    </g>
  </g>
</svg>

    )
}

export default MobileDivider